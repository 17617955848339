<template>
  <trash-warnning
    v-if="isDeleted"
    title="This module is in trash."
    @restore="restoreDeletedItems(learnModule.id)"
    @delete-permanently="deletePermanentlyDeletedItems(learnModule.id)"
  />

  <!-- Header -->
  <svn-pro-top-bar-learn-module
    v-if="!isDeleted"
    type="show"
    saveMode="auto"
    :is-favorited="learnModule?.connected_user_favorited"
    :creator-or-admin="amICreatorOfEntityOrAdmin(learnModule?.creator?.id)"
    @copy-link="copyLink"
    @edit-module="goToModuleEdit"
    @close-and-go-back="handleClose"
    @toggle-comments="toggleComments"
    @toggle-favorite="toggleModuleFavorite"
  />

  <div class="w-full relative">
    <v-layout
      full-height
      style="position: relative; width: 100%; height: calc(100% - 131); z-index: 0"
    >
      <v-main class="overflow-y-auto relative py-6 px-5 md:!px-5">
        <!-- Main content -->
        <div
          class="w-full flex flex-col md:!max-w-[672px] lg:!max-w-[770px] xl:!max-w-[752px] gap-6 mx-auto pb-[140px]"
        >
          <!-- Image -->
          <bkt-image-cover-position
            mode="show"
            :url="learnModule?.cover_url?.['original'] ? learnModule?.cover_url?.['original'] : ''"
            :coordinates="{
              left: learnModule?.cover_offset_left ?? 0,
              top: learnModule?.cover_offset_top ?? 0,
            }"
          />

          <div class="flex flex-col gap-6">
            <!-- Module title -->
            <svn-pro-title h5 medium>
              {{ learnModule?.title }}
            </svn-pro-title>

            <!-- Bunch of infos -->
            <div class="flex flex-col gap-4">
              <!-- Duration -->
              <div class="flex items-center gap-1">
                <div>
                  <Icon
                    icon="mingcute:time-line"
                    height="16"
                    width="16"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text body-medium regular color="onSurfaceVariant">
                  {{ $t('Total duration') }}:
                </svn-pro-text>

                <svn-pro-text body-medium bold color="onSurfaceVariant">
                  {{ learnModule?.duration || $t('No duration') }}
                </svn-pro-text>
              </div>

              <!-- Themes -->
              <div
                v-if="learnModule?.themes?.length"
                class="w-full flex items-start content-start gap-2 flex-wrap"
              >
                <svn-pro-info-tag
                  v-for="theme in learnModule?.themes"
                  :key="theme"
                  tag-type="info"
                  tag-style="outlined"
                  tag-size="compact"
                  :text="theme?.name"
                />
              </div>

              <!-- No theme -->
              <svn-pro-info-tag
                v-else
                tag-type="info"
                tag-style="outlined"
                tag-size="compact"
                :text="$t('No theme')"
              />

              <!-- Module acquired -->
              <div
                v-if="learnModule?.submission?.status === 'acquired'"
                class="flex itemx-center gap-1"
              >
                <div>
                  <Icon
                    icon="mingcute:check-circle-line"
                    height="16"
                    width="16"
                    class="text-success"
                  />
                </div>

                <svn-pro-text body-medium regular color="success">
                  {{ $t('Acquired on') }}
                  {{
                    filters.formatDate(
                      learnModuleSubmissionUpdatedAt
                        ? learnModuleSubmissionUpdatedAt
                        : learnModule?.submission_updated_at,
                      'DD/MM/YYYY',
                    )
                  }}
                </svn-pro-text>
              </div>
            </div>

            <!-- Divider -->
            <svn-pro-divider color="[#767680]" class="border-opacity-100" />

            <!-- Content -->
            <div class="w-full flex flex-col gap-4">
              <!-- Content title -->
              <svn-pro-title h6 medium>
                {{ $t('Content') }}
              </svn-pro-title>

              <!-- Rich text editor -->
              <svn-tiptap
                v-if="editorContentData?.blocks?.length && editorContentId"
                :create-image-url="`/api/v1/editor_contents/${editorContentId}/upload_image`"
                :html-data="editorContentData?.blocks"
                :extension-selection="AllTipTapPlugins"
                :extension-slash-command="AllTipTapPlugins"
                :is-editable="false"
              />
            </div>

            <div v-if="!isDeleted" class="w-full flex flex-col gap-8">
              <!-- Evaluations -->
              <div
                v-if="
                  learnModule?.has_learn_pieces_approval ||
                  learnModule?.has_learn_pieces_quiz ||
                  learnModule?.has_learn_pieces_face_to_face_evaluation
                "
                class="w-full flex flex-col gap-6"
              >
                <!-- Self evaluation is present -->
                <div
                  v-if="learnModule?.has_learn_pieces_approval"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{
                        $t(
                          "🙌 You've reached the end! Did you learn all the following learning objectives?",
                        )
                      }}
                    </svn-pro-title>

                    <svn-pro-text-area
                      v-if="learnApprovalInput?.text"
                      v-model="learnApprovalInput.text"
                      :variant="'text'"
                      :readonly="true"
                      :label="null"
                      class="text-onSurfaceVariant text-base font-normal leading-[28px] tracking-[0.15px]"
                    />
                  </div>

                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <dialog-validation-pro
                      icon="noto:party-popper"
                      :title="$t('Module acquired!')"
                      :action-one-title="nextModuleId ? $t('Next module') : $t('Go to catalog')"
                      :action-two-title="$t('Cancel')"
                      :content-text="$t('Congratulations, name, keep it up!', { name: firstname })"
                      @click-primary-button="openLearnReactionModal('save')"
                      @click-secondary-button="openLearnReactionModal('cancel')"
                      @click-outside="openLearnReactionModal('cancel')"
                    >
                      <template #activator="{ props }">
                        <svn-pro-button
                          v-bind="props"
                          variant="flat"
                          class="w-full md:!w-fit"
                          :text="
                            learnModule?.submission?.status === 'acquired'
                              ? $t('Update acquisition date')
                              : $t(`Yes, it's all clear to me!`)
                          "
                          @click="toggleFullyUnderstood"
                        />
                      </template>
                    </dialog-validation-pro>
                  </div>
                </div>

                <!-- Evaluation Quiz is present -->
                <div
                  v-else-if="learnModule?.has_learn_pieces_quiz"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{ $t('🙌 You’ve reached the end! Ready for the quiz?') }}
                    </svn-pro-title>

                    <svn-pro-text subtitle-large regular color="onSurfaceVariant">
                      {{ $t('To complete this module, evaluate yourself with a quiz.') }}
                    </svn-pro-text>
                  </div>

                  <!-- Start the Quiz block -->
                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <svn-pro-button
                      variant="flat"
                      :text="
                        learnModule?.submission?.status === 'not_started'
                          ? $t('Start the quiz !')
                          : $t('Retake quiz')
                      "
                      @click="router.push({ name: 'quiz_edit', params: { id: piecesQuizId } })"
                    />
                    <!--                    <modal-quiz-learn-module-->
                    <!--                    @click="submissionStatus === 'acquired' ? retryQuiz() : startQuiz()"-->
                    <!--                      ref="modalQuiz"-->
                    <!--                      :inputs="inputs"-->
                    <!--                      :learn-module="learnModule"-->
                    <!--                      :learn-responses-checkboxes="learnResponsesCheckboxes"-->
                    <!--                      :submission="submission"-->
                    <!--                      :submission-status="learnModule?.submission?.status"-->
                    <!--                      :result-percentage="resultPercentage"-->
                    <!--                      :show-quiz-results="showQuizResults"-->
                    <!--                      :next-module-id="nextModuleId"-->
                    <!--                      @start-quiz="startQuiz()"-->
                    <!--                      @recall-mounted-method="mountedMethods()"-->
                    <!--                      @retry-quiz="retryQuiz()"-->
                    <!--                      @open-modal-reaction="openLearnReactionModal"-->
                    <!--                      @validate-quiz="validateQuiz()"-->
                    <!--                    />-->
                  </div>
                </div>

                <!-- Evaluation Face to Face is present -->
                <div
                  v-else-if="learnModule?.has_learn_pieces_face_to_face_evaluation"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col items-start gap-[10px] self-stretch">
                    <svn-pro-title
                      v-if="learnModule?.submission?.status === 'in_progress'"
                      :h5="isMdScreen"
                      :h6="!isMdScreen"
                      medium
                    >
                      {{
                        $t(
                          '🙌 You’ve reached the end! Validate your evaluation to complete this module.',
                        )
                      }}
                    </svn-pro-title>

                    <svn-pro-title
                      v-else-if="learnModule?.submission?.status === 'acquired'"
                      :h5="isMdScreen"
                      :h6="!isMdScreen"
                      medium
                    >
                      {{ $t('✅ Evaluation done, you have acquired this module!') }}
                    </svn-pro-title>

                    <svn-pro-title v-else h5 medium>
                      {{
                        $t(
                          '🙌 You’ve reached the end! Please notify your expert before starting the evaluation.',
                        )
                      }}
                    </svn-pro-title>

                    <svn-pro-text
                      v-if="learnModule?.submission?.status !== 'acquired'"
                      subtile-large
                      regular
                      color="onSurfaceVariant"
                    >
                      {{
                        $t(
                          'To successfully complete this module, your answers must be reviewed in real time by your expert.',
                        )
                      }}
                    </svn-pro-text>

                    <svn-pro-text v-else subtile-large regular color="onSurfaceVariant">
                      {{
                        $t(
                          'This module has been acquired, but you can still restart the evaluation.',
                        )
                      }}
                    </svn-pro-text>
                  </div>

                  <!-- Start/Continue/restart evaluation button -->
                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <modal-start-face-to-face-evaluation
                      ref="faceToFaceEvaluationModal"
                      :next-module-id="nextModuleId"
                      @open-modal-reaction="openLearnReactionModal"
                      @start-evaluation="startFaceToFaceEvaluation"
                    />
                  </div>
                </div>
              </div>

              <!-- Next Or Previous Module -->
              <div
                v-if="route?.query?.trainingId || route?.query?.playlistId"
                class="w-full flex items-center justify-end gap-2"
              >
                <!-- Go to previous module -->
                <svn-pro-tooltip :text="$t('Previous module')">
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="props"
                      variant="outlined"
                      :disabled="!prevModuleId"
                      color="onSurfaceVariant"
                      icon="custom:mingcute:arrow-left-line"
                      @click="goToPreviousModule"
                    />
                  </template>
                </svn-pro-tooltip>

                <!-- Next module -->
                <svn-pro-tooltip
                  v-if="learnModule?.submission?.status !== 'acquired'"
                  :text="$t('Next module')"
                >
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="props"
                      variant="tonal"
                      :disabled="!nextModuleId"
                      icon="custom:mingcute:arrow-right-line"
                      @click="validation.dialogRef.dialog = true"
                    />
                  </template>
                </svn-pro-tooltip>

                <!-- Next module -->
                <svn-pro-tooltip v-else :text="$t('Next module')">
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="props"
                      variant="tonal"
                      :disabled="!nextModuleId"
                      icon="custom:mingcute:arrow-right-line"
                      @click="goToNextModule"
                    />
                  </template>
                </svn-pro-tooltip>
              </div>
            </div>
          </div>
        </div>
      </v-main>

      <!-- Comments drawer Large Screen -->
      <comments-drawer
        v-model="drawerCommentsLargeScreen"
        :learn-module-reactions="learnModulesComments"
        location="end"
        :width="526"
        class="border-l-middleGrey hidden lg:block"
        @delete-comment="deleteComment"
        @add-comment-reply="addCommentReply"
      />
    </v-layout>
  </div>

  <!-- Reactions -->
  <modal-what-did-you-think-of-the-module
    ref="modalModuleReaction"
    :reactions="reactions"
    @update-reaction="onReactionUpdated"
    @click-primary-button="goToNextModuleOrCatalogOrCancel"
    @click-secondary-button="goToNextModuleOrCatalogOrCancel"
    @click-outside="goToNextModuleOrCatalogOrCancel"
    @click-close="goToNextModuleOrCatalogOrCancel"
  >
    <template #activator>
      <div class="hidden opacity-0" />
    </template>
  </modal-what-did-you-think-of-the-module>

  <!-- Comments drawer Large Mobile -->
  <comments-drawer
    v-model="drawerCommentsMobile"
    :learn-module-reactions="learnModulesComments"
    :location="isMobile ? 'bottom' : 'end'"
    :width="isMobile ? drawerWidthMobile : 526"
    class="block lg:hidden h-screen"
    :class="isMobile ? 'h-full' : 'border-l-middleGrey'"
    :mobile="true"
    @delete-comment="deleteComment"
    @add-comment-reply="addCommentReply"
    @close-drawer="drawerCommentsMobile = false"
  />

  <!-- Dialog delete comemnt -->
  <dialog-delete-comment
    ref="dialogDeleteComment"
    :has-reaction-type="commentToDelete?.reaction_type === ''"
    @delete-comment="deleteModuleComment"
  />

  <!-- Scroll to top button -->
  <svn-pro-fab to-topsize="small"
    color="primary"
    variant="tonal"
    :rounded="'lg'"
    class="fixed bottom-4 right-4 bg-white"
    icon="custom:mingcute:arrow-to-up-line"
  />

  <!-- Validation when clicking on next -->
  <dialog-validation-pro
    ref="validation"
    icon="noto:bell"
    :title="learnModule?.has_learn_pieces_quiz ? $t(`Start the quiz`) :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        $t(`Face-to-face evaluation`) : $t('Module will be acquired')"
    :content-text="learnModule?.has_learn_pieces_quiz ? $t(`To mark this module as “acquired”, you must pass the assestment quiz.`) :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        $t(`To mark this module as “acquired”, you must pass the face-to-face evaluation.`) : $t('To mark this module as “acquired”, you must confirm that you have understood it.')"
    :action-one-title="learnModule?.has_learn_pieces_quiz ? $t(`Start`) :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        $t(`Start`) : $t('Confirm')"
    :action-two-title="learnModule?.has_learn_pieces_quiz ? $t(`Do it later`) :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        $t(`Do it later`) : $t('Not yet')"
    @click-primary-button="learnModule?.has_learn_pieces_quiz ? openQuiz() :
      learnModule?.has_learn_pieces_face_to_face_evaluation ?
        startFaceToFaceEvaluation() : toggleFullyUnderstood()"
  >
    <template #activator="{ props }">
      <div class="hidden opacity-0" />
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import CommentsDrawer from '@/components/trainingApp/CommentsDrawer.vue';
import ModuleShowHeaderButtons from '@/components/trainingApp/ModuleShowHeaderButtons.vue';
import { onMounted, onUnmounted, ref, computed, onBeforeUnmount } from 'vue';
import DialogDeleteComment from '@/components/BktPopUp/Dialogs/learn/DialogDeleteComment.vue';
import i18n from '@/plugins/i18n.js';
import ModalQuizLearnModule from '@/views/learns/quiz/QuizEdit.vue';
import { useLearnModuleStore } from '@/store/learn-module';
import { useRoute, useRouter } from 'vue-router';
import { useSnackbar } from '@/store/snackbar';
import { storeToRefs } from 'pinia';
import { useToastStore } from '@/store/toast.js';
import { useMobileStore } from '@/store/mobile';
import { useActionCable } from '@/store/cable.js';
import { useUserStore } from '@/store/user.js';
import { debounce } from 'lodash';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useLearnTrainingStore } from '@/store/learn-trainings.js';
import filters from '@/tools/filters';
import TrashWarnning from '@/components/TrashWarnning.vue';
import ModalStartFaceToFaceEvaluation from '@/components/BktPopUp/Modals/learn/ModalStartFaceToFaceEvaluation.vue';
import { LearnInputType } from '@/constants/types';
import BktImageCoverPosition from '@/components/image/bkt-image-cover-position.vue';
import { AllTipTapPlugins } from 'svn-ui-library/extensions';
import DialogValidationPro from '@/components/BktPopUp/Dialogs/DialogValidationPro.vue';
import ModalWhatDidYouThinkOfTheModule from '@/components/BktPopUp/Modals/learn/ModalWhatDidYouThinkOfTheModule';

onMounted(async () => {
  await mountedMethods();
});

const resultPercentage = ref(0);
const { addToast } = useToastStore();
const { cable } = storeToRefs(useActionCable());
const { id: userId, firstname } = storeToRefs(useUserStore());

const completedCount = () => {
  return learnResponsesCheckboxes.value.reduce((count, item) => {
    if (item.status === 'correct') {
      return count + 1;
    }
    return count;
  }, 0);
};

onUnmounted(() => {
  // window.removeEventListener("scroll", alignFunc)
});

const { fetchPlaylist } = useLearnPlaylistStore();
const { fetchTraining } = useLearnTrainingStore();
const { amICreatorOfEntityOrAdmin } = useUserStore();
const {
  fetchModule,
  addReaction,
  toggleReaction,
  deleteReaction,
  getSubmissionFaceToFace,
  fetchModuleComments,
  toggleFavorite,
  approvalSubmission,
  updateApprovalSubmissionDate,
  fetchInputs,
  getSubmission,
  fetchInputsFaceToFace,
  createSubmission,
  createSubmissionFaceToFace,
  resetStates,
  validateSubmission,
  getEditorContent,
  restoreModule,
  deleteModulePermanently,
} = useLearnModuleStore();

const {
  learnModule,
  learnModulesComments,
  inputs,
  inputsFaceToFace,
  submissionId,
  piecesQuizId,
  learnResponsesCheckboxes,
  submission,
  editorContentData,
  editorContentId,
  learnModuleSubmissionUpdatedAt,
  learnApprovalInput,
} = storeToRefs(useLearnModuleStore());
const { learnTraining } = storeToRefs(useLearnTrainingStore());
const { learnPlaylist } = storeToRefs(useLearnPlaylistStore());
const { isMobile, isMdScreen, isLgScreen } = storeToRefs(useMobileStore());

const validation = ref(null);
const nextModuleId = ref(null);
const prevModuleId = ref(null);
const showQuizResults = ref(false);
const drawerWidthMobile = ref(null);
const drawerCommentsMobile = ref(false);
const drawerCommentsLargeScreen = ref(false);
const alignTop = ref(false);
const editorContentChannel = ref(null);
const inputChannel = ref(null);
const dialogDeleteComment = ref(false);
const modalQuiz = ref(false);
const commentToDelete = ref(null);
const isDeleted = ref(false);
const modalModuleReaction = ref(null);
const faceToFaceEvaluationModal = ref(null);
const finalAction = ref(null);
const reactions = computed(() => {
  return [
    {
      value: 'like',
      icon: 'https://lottie.host/?file=1d730887-ff07-4bcb-916a-1c47a1fc2191/tBJxPeERxp.json',
      text: i18n.global.t('I like it'),
      active: learnModule?.value?.user_reactions?.find((reaction) => reaction?.type === 'like')
        ?.selected,
    },
    {
      value: 'recommend',
      icon: 'https://lottie.host/?file=f503d535-88db-4e36-b0b3-a061d09424f7/XWharmF7Hb.json',
      text: i18n.global.t('I recommend'),
      active: learnModule?.value?.user_reactions?.find((reaction) => reaction?.type === 'recommend')
        ?.selected,
    },
  ];
});

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();

const startQuiz = debounce(async () => {
  try {
    if (submissionId.value) {
      await getSubmission(piecesQuizId.value);
    } else {
      await createSubmission(piecesQuizId.value);
    }

    if (completedCount() === 0 || learnResponsesCheckboxes.value.length === 0) {
      resultPercentage.value = 0;
    } else {
      resultPercentage.value = (completedCount() / learnResponsesCheckboxes.value.length) * 100;
    }

    showQuizResults.value = submission?.value?.status === 'retry';
  } catch (error) {
    console.log(error);
  }
}, 300);

const startFaceToFaceEvaluation = debounce(async () => {
  faceToFaceEvaluationModal.value.modalFaceToFace.dialog = true;

  try {
    if (submission?.value?.id) {
      await getSubmissionFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id);
    } else {
      await createSubmissionFaceToFace(
        learnModule?.value?.learn_pieces_face_to_face_evaluation?.id,
      );
    }
  } catch (error) {
    console.log(error);
  }
}, 300);

const retryQuiz = debounce(async () => {
  showQuizResults.value = false;

  await createSubmission(piecesQuizId.value);

  learnResponsesCheckboxes.value?.forEach((question) => {
    question.status = null;
    question.selected_values = [];
  });
}, 300);

const openLearnReactionModal = (action) => {
  modalModuleReaction.value.moduleReactionsModal.dialog = true;

  finalAction.value = action;
};

const openQuiz = () => {
  modalQuiz.value.modalRef.dialog = true;
  startQuiz();
};

const validateQuiz = debounce(async () => {
  await validateSubmission(piecesQuizId.value);
  if (completedCount() === 0 || learnResponsesCheckboxes.value.length === 0) {
    resultPercentage.value = 0;
  } else {
    resultPercentage.value = (completedCount() / learnResponsesCheckboxes.value.length) * 100;
  }

  showQuizResults.value = true;
}, 300);

const addCommentReply = debounce(async (text) => {
  try {
    await addReaction(learnModule?.value?.id, '', text);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error adding comment')
      .displaySnackBar();
  }
}, 300);

const deleteComment = (comment) => {
  commentToDelete.value = comment;
  dialogDeleteComment.value.dialogDeleteComment = true;
};

const deleteModuleComment = debounce(async () => {
  try {
    await deleteReaction(learnModule?.value?.id, commentToDelete.value?.id);
    dialogDeleteComment.value.dialogDeleteComment = false;

    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Comment deleted')
      .displaySnackBar();
  } catch (error) {
    dialogDeleteComment.value.dialogDeleteComment = false;

    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error deleting comment')
      .displaySnackBar();
  }
  commentToDelete.value = null;
}, 300);

const toggleFullyUnderstood = async () => {
  if (learnModule?.value?.submission && Object.keys(learnModule?.value?.submission).length) {
    try {
      await updateApprovalSubmissionDate();
    } catch (error) {
      snackbar
        .setBgColor('onSurface')
        .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
        .setMsg('Error updating acquisition date')
        .displaySnackBar();
    }
  } else {
    await approvalSubmission();
  }
};

const onReactionUpdated = async (reactionType) => {
  try {
    await toggleReaction(learnModule?.value?.id, reactionType, '');
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error toggling reaction')
      .displaySnackBar();
  }
};

const toggleComments = () => {
  if (isLgScreen.value) {
    drawerCommentsLargeScreen.value = !drawerCommentsLargeScreen.value;
  } else {
    drawerCommentsMobile.value = !drawerCommentsMobile.value;
  }
};

const openComments = () => {
  if (isLgScreen.value) {
    drawerCommentsLargeScreen.value = true;
  } else {
    drawerCommentsMobile.value = true;
  }
};

const toggleModuleFavorite = debounce(async () => {
  try {
    await toggleFavorite(route?.params?.id, learnModule?.value?.status);
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg(
        learnModule?.value?.connected_user_favorited
          ? 'Module added to favorites.'
          : 'Module removed from favorites.',
      )
      .displaySnackBar();
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error toggling favorite')
      .displaySnackBar();
  }
}, 300);

const copyLink = debounce(() => {
  navigator.clipboard.writeText(window.location.href);

  snackbar
    .setBgColor('onSurface')
    .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
    .setMsg('Link copied to clipboard.')
    .displaySnackBar();
}, 300);

const goToModuleEdit = () => {
  router.push({ name: 'module_edit', params: { id: route?.params?.id } });
};

const handleClose = () => {
  router.back();
};

const goToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

const goToCatalog = () => {
  router.push({ name: 'catalog' });
};

window.onscroll = () => {
  scrollFunction();
};

const scrollFunction = () => {
  if (document.documentElement.scrollTop >= 300) {
    alignTop.value = true;
  } else {
    alignTop.value = false;
  }
};

const restoreDeletedItems = async (id) => {
  try {
    await restoreModule(id);
    snackbar.setBgColor('positiveGreen');
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Module restored successfully, module has been moved to draft')
      .displaySnackBar();

    isDeleted.value = false;
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error restoring a module')
      .displaySnackBar();
  }
};

const deletePermanentlyDeletedItems = async (id) => {
  try {
    await deleteModulePermanently(id, false);
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Module deleted successfully.')
      .displaySnackBar();

    await router.push({ name: 'catalog' });
  } catch (e) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error deleting module.')
      .displaySnackBar();
  }
};

const mountedMethods = async () => {
  await resetStates();
  // window.addEventListener("scroll", alignFunc);

  drawerWidthMobile.value = window?.innerWidth;

  try {
    await fetchModule(route?.params?.id);
    updateEditorContent();

    if (learnModule?.value?.has_learn_pieces_quiz) {
      await fetchInputs(learnModule.value?.learn_pieces_quiz?.id);
      await updateInputQuestion();
    }

    if (learnModule?.value?.has_learn_pieces_face_to_face_evaluation) {
      await fetchInputsFaceToFace(learnModule.value?.learn_pieces_face_to_face_evaluation?.id);
      await updateInputQuestion();
    }

    if (learnModule?.value?.editor_content_id) {
      await getEditorContent(learnModule?.value?.editor_content_id);
    }

    // On vient sur le module depuis un training
    if (route?.query?.trainingId) {
      await fetchTraining(route?.query?.trainingId);

      if (learnTraining.value.modules_and_playlist.length) {
        const moduleIds = learnTraining.value.modules_and_playlist
          .map((content) => {
            if (content.content_type === 'Learn::Playlist') {
              return content.learn_modules.map((playlist_content) => {
                return playlist_content.id;
              });
            } else {
              return content.id;
            }
          })
          .flat(1);

        const moduleIdsClean = moduleIds.filter(Boolean);
        const currentIndex = moduleIdsClean.indexOf(parseInt(route?.params.id));
        nextModuleId.value = moduleIdsClean[currentIndex + 1];
        prevModuleId.value = moduleIdsClean[currentIndex - 1];
      }
    }

    // On vient sur le module depuis une playlist
    if (route?.query?.playlistId) {
      await fetchPlaylist(route?.query?.playlistId);

      if (learnPlaylist.value.learn_modules.length) {
        const moduleIds = learnPlaylist.value.learn_modules.map((mod) => mod?.id);

        const currentIndex = moduleIds.indexOf(parseInt(route?.params.id));
        nextModuleId.value = moduleIds[currentIndex + 1];
        prevModuleId.value = moduleIds[currentIndex - 1];
      }
    }

    isDeleted.value = learnModule.value.status === 'trash';
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error fetching module.')
      .displaySnackBar();
  }

  try {
    await fetchModuleComments(route?.params?.id);
  } catch (error) {
    snackbar
      .setBgColor('onSurface')
      .setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]')
      .setMsg('Error fetching module reactions.')
      .displaySnackBar();
  }
};

const updateInputQuestion = debounce(async () => {
  const subscribeOptions = {
    channel: 'Learn::InputChannel',
    piece_id:
      learnModule?.value?.submission_type === 'Learn::Pieces::Quiz'
        ? learnModule.value?.learn_pieces_quiz?.id
        : learnModule?.value?.learn_pieces_face_to_face_evaluation?.id,
  };

  inputChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'update') {
        const index = inputs.value.findIndex((x) => x.id === data.entity.id);
        const indexFaceToFace = inputsFaceToFace.value.findIndex((x) => x.id === data.entity.id);

        if (data.entity.type === LearnInputType.CHECKBOX && index >= 0) {
          inputs.value[index].title = data.entity.title;
          inputs.value[index].proposals = data.entity.proposals;
          inputs.value[index].position = data.entity.position;
        } else if (data.entity.type === LearnInputType.OPEN && indexFaceToFace >= 0) {
          inputsFaceToFace.value[indexFaceToFace].title = data.entity.title;
          inputsFaceToFace.value[indexFaceToFace].description = data.entity.description;
          inputsFaceToFace.value[indexFaceToFace].position = data.entity.position;
        } else {
          addToast(
            'info',
            i18n.global.t(`This module has just been updated !`),
            i18n.global.t(
              `The lastest version of this content will be visible if you reload this page.`,
            ),
            false,
            {
              name: i18n.global.t(`Reload this page`),
              link: '/learns/module/' + learnModule.value.id + '/show',
            },
          );
        }
      }
    },

    update: async function () {},
  });
}, 300);

const updateEditorContent = debounce(async () => {
  const subscribeOptions = {
    channel: 'EditorContentChannel',
    id: learnModule.value.editor_content_id,
  };

  editorContentChannel.value = cable.value.subscriptions.create(subscribeOptions, {
    connected: function () {
      // Called when the subscription is ready for use on the server
    },

    disconnected: function () {
      // Called when the subscription has been terminated by the server
    },

    received: function (data) {
      if (data.status === 'update') {
        addToast(
          'info',
          i18n.global.t(`This module has just been updated !`),
          i18n.global.t(
            `The lastest version of this content will be visible if you reload this page.`,
          ),
          false,
          {
            name: i18n.global.t(`Reload this page`),
            link: '/learns/module/' + learnModule.value.id + '/show',
          },
        );
      } else {
        if (data?.entity?.blocks?.length) {
          editorContentData.value.blocks = data.entity.blocks;
        } else {
          editorContentData.value.blocks = emptyBlocks.value;
        }
      }
    },

    update: async function () {},
  });
}, 300);

const goToNextModuleOrCatalogOrCancel = () => {
  modalModuleReaction.value.moduleReactionsModal.dialog = false;
if (learnModule?.value?.has_learn_pieces_approval) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[72px]').setMsg('Acquisition date has been updated.').displaySnackBar();
  }

  if (finalAction?.value === 'save') {
    if (nextModuleId?.value) {
      goToNextModule();
    } else {
      goToCatalog();
    }
  } else if (finalAction?.value === 'push-training-show') {
    router.push({ name: 'training_show', params: { id: route?.query?.trainingId } });
  } else if (finalAction?.value === 'push-training-show-and-recall') {
    router
      .push({
        name: 'module_show',
        params: { id: nextModuleId?.value },
        query: { trainingId: route?.query?.trainingId },
      })
      .then(() => {
        mountedMethods();
      });
  } else if (finalAction?.value === 'push-catalog') {
    router.push({ name: 'catalog' });
  } else if (finalAction?.value === 'cancel') {
    modalModuleReaction.value.moduleReactionsModal.dialog = false;
  }
};

const goToNextModule = () => {
  if (nextModuleId?.value) {
    if (route?.query?.trainingId) {
      router
        .push({
          name: 'module_show',
          params: { id: nextModuleId?.value },
          query: { trainingId: route?.query?.trainingId },
        })
        .then(async () => {
          await mountedMethods();
        });
    } else if (route?.query.playlistId) {
      router
        .push({
          name: 'module_show',
          params: { id: nextModuleId?.value },
          query: { playlistId: route?.query?.playlistId },
        })
        .then(async () => {
          await mountedMethods();
        });
    }
  }
};

const goToPreviousModule = () => {
  if (prevModuleId?.value) {
    if (route?.query?.trainingId) {
      router
        .push({
          name: 'module_show',
          params: { id: prevModuleId?.value },
          query: { trainingId: route?.query?.trainingId },
        })
        .then(async () => {
          await mountedMethods();
        });
    } else if (route?.query.playlistId) {
      router
        .push({
          name: 'module_show',
          params: { id: prevModuleId?.value },
          query: { playlistId: route?.query?.playlistId },
        })
        .then(async () => {
          await mountedMethods();
        });
    }
  }
};

onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  editorContentChannel?.value?.unsubscribe();
});
</script>
