<template>
  <svn-pro-card
    v-if="
      interviewKind === 'crossed'
        ? true
        : question?.visible_for === interviewKind ||
          question?.visible_for === 'all'
    "
    :id="question?.id"
    :link="false"
    class="p-8"
    :class="interviewTouched && !findInterviewAnswer.answer
      && (question?.required_for === interviewKind || question?.required_for === 'all') ?
        '!border !border-error border-opacity-100' : ''"
  >
    <div :id="question?.id" class="w-full flex flex-col items-start gap-6 self-stretch">
      <svn-pro-title h5 medium color="onSurface">
        {{ question.text }}
        {{
          question.required_for === interviewKind ||
            question.required_for === "all"
            ? "*"
            : ""
        }}
      </svn-pro-title>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />

      <div v-if="findEmployeeQuestion(question?.id) || findManagerQuestion(question?.id)" class="flex flex-col items-start gap-4 self-stretch">
        <interview-answer
          v-if="(interviewKind === 'manager' || interviewKind === 'crossed') && findEmployeeQuestion(question.id)"
          :user="employee"
          :question-type="question?.type"
          :answer="findEmployeeQuestion(question.id).answer"
          :comments="findEmployeeQuestion(question.id).comments"
        />

        <interview-answer
          v-if="interviewKind === 'manager' && findManagerQuestion(question.id)"
          :user="interviewer"
          :question-type="question?.type"
          :side="false"
          :answer="findManagerQuestion(question.id).answer"
          :comments="findManagerQuestion(question.id).comments"
        />

        <interview-answer
          v-else-if="interviewKind === 'crossed' && findManagerQuestion(question.id)"
          :user="interviewer"
          :question-type="question?.type"
          :side="false"
          :answer="findManagerQuestion(question.id).answer"
          :comments="findManagerQuestion(question.id).comments"
        />

        <interview-answer
          v-else-if="interviewKind === 'crossed' && findEmployeeQuestion(question.id) && findManagerQuestion(question.id)"
          :user="interviewer"
          :question-type="question?.type"
          :side="false"
          :answer="findManagerQuestion(question.id).answer"
          :comments="findManagerQuestion(question.id).comments"
        />
      </div>

      <div
        v-if="findInterviewAnswer?.answer && locked && interviewKind !== 'manager'"
        class="w-full flex flex-col gap-2"
      >
        <svn-pro-text
          v-if="interviewKind === 'crossed'"
          medium
          body
        >
          {{ $t('Cross review') }}
        </svn-pro-text>

        <interview-answer
          v-if="findInterviewAnswer?.answer && locked"
          :user="findInterviewAnswer.user"
          :is-last-interview-completed-and-locked="isLastInterviewCompletedAndLocked"
          :crossed="interviewKind === 'crossed'"
          :question-type="InterviewQuestionType.OPEN"
          :answer="findInterviewAnswer.answer"
          :comments="findInterviewAnswer.comments"
        />
      </div>

      <!-- Comments drawer button -->
      <svn-pro-button
        v-if="locked && findInterviewAnswer && findInterviewAnswer?.messages?.length && campaign?.campaign_type === 'Survey'"
        variant="text"
        class="w-fit"
        :text="$t('Comments')"
        @click="commentDrawer = !commentDrawer"
      />

      <svn-pro-text-area
        v-if="!locked"
        v-model="findInterviewAnswer.answer"
        :placeholder="$t('Answer')"
        variant="outlined"
        :rows="6"
        :max-rows="6"
        :label="$t('Answer')"
        :textarea="true"
        class="w-full"
        @update:model-value="findInterviewAnswer.answer = $event; updateAnswer($event)"
      />
    </div>
  </svn-pro-card>

  <!-- Comments drawer -->
  <bkt-survey-comments
    v-model="commentDrawer"
    :answer-interview-id="findInterviewAnswer?.id"
    :comment="findInterviewAnswer"
    :answer="findInterviewAnswer?.question"
    :is-interview="true"
    :comments="findInterviewAnswer?.messages"
    @close="commentDrawer = false"
    @refetch-answers="refetchAnswers"
  />
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, computed }  from "vue";
import { useSnackbar } from "@/store/snackbar";
import { useSurveyStore } from "@/store/survey.js";
import InterviewAnswer from "../InterviewAnswer.vue";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';
import BktSurveyComments from "@/components/surveyApp/BktSurveyComments.vue";

const snackbar = useSnackbar();
const { fetchSurveyAnswers } = useSurveyStore();
const { updateInterviewAnswer } = useInterviewStore();

const {
  interviewKind,
  campaign,
  isLastInterviewCompletedAndLocked,
  interviewAnswers,
  locked,
  employee,
  interviewer,
  employeeAnswers,
  managerAnswers,
  interviewTouched
} = storeToRefs(useInterviewStore());
const { answers } = storeToRefs(useSurveyStore());

const props = defineProps(["question", "loopIdx"]);

const commentDrawer = ref(false);

const findInterviewAnswer = computed(() => {
  return (
    interviewAnswers.value.find(
      (answer) => answer.question_id === props.question.id
    ) || {}
  );
});

const updateAnswer = debounce((event) => {
  updateRemote();
}, 400);

const updateRemote = () =>
  updateInterviewAnswer(props.loopIdx, props.question.id, {
    answer: findInterviewAnswer.value.answer,
  });

const findEmployeeQuestion = (questionId) => {
  return employeeAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const findManagerQuestion = (questionId) => {
  return managerAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const setCrossDefaultAnswer = () => {
  let managerAnswers = findManagerQuestion(props.question.id);
  let employeeAnswers = findEmployeeQuestion(props.question.id);

  if (!findInterviewAnswer.value.answer) {
    findInterviewAnswer.value.answer = managerAnswers
      ? managerAnswers.answer
      : employeeAnswers
      ? employeeAnswers.answer
      : null;
    updateRemote();
  }
};

if (interviewKind.value === "crossed" && !locked.value) {
  setCrossDefaultAnswer();
}

const refetchAnswers = async() => {
  try {
    await fetchSurveyAnswers(findInterviewAnswer?.value?.question?.interview_app_form_id, findInterviewAnswer?.value?.question?.id);
    let answer = interviewAnswers.value.find((answer) => answer.question_id === props?.question?.id)
    answer.messages = answers?.value?.find((answer) => answer?.question_id === props?.question?.id)?.messages
  } catch {
    snackbar.setBgColor('onSurface').setMsg('An error occured, please try again.').displaySnackBar();
  }
}
</script>