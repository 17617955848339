<template>
  <div
    :id="question.id"
  >
    <div
      id="roadmapBlock"
      class="flex flex-col gap-2"
    >
      <svn-pro-title h6 bold>
        {{ question.text }}
      </svn-pro-title>

      <svn-pro-divider v-if="question.text && question?.description" class="w-full border-[#767680]"/>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />
    </div>

    <div
      v-if="snapshotsUpdated?.length"
      class="w-full flex flex-col gap-2 sm:!gap-8 mt-2 sm:!mt-8"
    >
      <update-roadmap-block-card
        v-for="(target, idx) in snapshotsUpdated"
        :key="idx"
        :target="target"
        :question="question"
        :is-draft="false"
        :employee-id="employee?.id"
      />
    </div>

    <div
      v-else
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('You don\u0027t have any targets for the moment.') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { useInterviewStore } from "@/store/interview.js";
import UpdateRoadmapBlockCard from "./UpdateRoadmapBlockCard.vue";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";

const { fetchUpdatedSnapshots } = useTargetSuggestionsStore();
const { snapshotsUpdated } = storeToRefs(useTargetSuggestionsStore());
const { employee, campaignId, id } = storeToRefs(useInterviewStore())
const unarchived = ref([])
const snackbar = useSnackbar();

const props = defineProps(['question'])

const fetchTargets = async () => {
  try {
    await fetchUpdatedSnapshots(employee?.value?.id, campaignId?.value, id?.value)

    if (snapshotsUpdated?.value?.length === 0) {
      unarchived.value = null
    }
    else {
      unarchived.value = snapshotsUpdated.value
    }
  } catch (e) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching targets !')
    snackbar.displaySnackBar()
  }
}
onMounted(async () => {
  await fetchTargets()
})
</script>
