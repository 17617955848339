<template>
  <svn-pro-modal
    v-model="dialog"
    :width="isMdScreen ? '580' : ''"
    :title="$t('Create new playlist')"
    no-click-animation
    :actionOneTitle="$t('Validate')"
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    :primary-loading="formLoading"
    @click-close="closeAndResetFields"
    @click-primary-button="createNewPlaylist"
    @click-outside="closeAndResetFields"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="flex flex-col gap-6">
        <!-- Title input -->
          <svn-pro-text-field
            v-model="playlistTitle"
            variant="outlined"
            color="onSurfaceVariant"
            :label="('Playlist title*')"
            :counter="true"
            maxlength="70"
        />

        <!-- Description input -->
        <svn-pro-text-area
          v-model="playlistDescription"
          :label="$t('Your message*')"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :counter="true"
          :maxlength="200"
        />
      </div>
    </template>
  </svn-pro-modal>

</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useMobileStore } from '@/store/mobile';
import { useLearnPlaylistStore } from '@/store/learn-playlist';
import { useSnackbar } from '@/store/snackbar';
import { storeToRefs } from "pinia";

const props = defineProps({
  title: { type: String, default: '' },
  textConfirm: { type: String, default: '' },
})

const playlistId = ref(null)
const dialog = ref(false)

const playlistTitle = ref('')

const playlistDescription = ref('')

const snackbar = useSnackbar()

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const { createPlaylist } = useLearnPlaylistStore()

const disabled = computed(() => {
  if (!(playlistTitle.value)) {
    return true
  }
  else {
    return false
  }
})

const router = useRouter()

const closeAndResetFields = () => {
  dialog.value = false
  playlistTitle.value = ''
  playlistDescription.value = ''
}

const createNewPlaylist = async() => {
  try {
    playlistId.value = await createPlaylist(playlistTitle.value, playlistDescription.value)
    router.push({ name: "playlist_show", params: { id: playlistId?.value }  })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating new playlist')
    snackbar.displaySnackBar()
  }
}

defineExpose({
  dialog
})
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
