<template>
  <div class="w-full flex flex-col items-center bg-surface">
    <trash-warnning
      v-if="isTrashed"
      title="This campaign is in trash."
      @restore="restoreDeletedItem"
      @delete-permanently="deleteTargetRef.dialogRef.dialog  = true"
    />

    <div class="w-full bg-surface px-2 sm:!px-[52px] border-b border-b-borderColor border-opacity-100">
      <svn-pro-breadcrumbs :items="breadcrumbItems" />
    </div>

    <div class="w-full md:!max-w-[672px] lg:!max-w-[928px] xl:!max-w-[1136px] flex md:!py-6 desktop:!py-10 flex-col justify-center">
      <div class="flex flex-col gap-6">
        <div class="w-full flex flex-col px-5 md:!px-0 md:!justify-between md:!flex-row md:!items-center">
          <div class="w-full flex justify-between items-center gap-2">
            <svn-pro-title h5 medium class="line-clamp-1 break-all">
              {{ campaign?.title }}
            </svn-pro-title>

            <div class="flex justify-end items-end md:!hidden">
              <svn-pro-tooltip :text="$t('The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.')">
                <template #activator="{ props }">
                  <svn-pro-icon-button
                    v-bind="props"
                    icon="custom:mingcute:group-2-line"
                    color="onSurfaceVariant"
                    variant="text"
                    @click="copilotsRef.manageCopilots.dialog = true"
                  />
                </template>
              </svn-pro-tooltip>

              <svn-pro-menu
                v-if="!isMdScreen"
                v-model="menu"
                :items="menuItems"
              >
                <template #activator>
                  <svn-pro-icon-button
                    icon="custom:mingcute:more-2-line"
                    color="onSurfaceVariant"
                    variant="text"
                  />
                </template>
              </svn-pro-menu>
            </div>
          </div>

          <div class="whitespace-nowrap">
            <svn-pro-text subtitle-medium regular>
              {{ $t("Deadline") }} : {{ $filters.formatDate(campaign?.deadline, 'DD/MM/YYYY') }}
            </svn-pro-text>
          </div>
        </div>

        <div class="flex items-center justify-between">
          <v-tabs
            v-model="tab"
            :grow="!isMdScreen"
            color="primary"
          >
            <!-- Overview Tab -->
            <v-tab
              value="overview"
              class="w-fit"
              :text="$t('Overview')"
            />

            <!-- Participants Tab -->
            <v-tab
              value="participants"
              class="w-fit"
              :text="`${$t('Participants')} (${campaign?.employees_count || 0})`"
            />

            <!-- Answers Tab if Survey -->
            <v-tab
              v-if="campaign?.campaign_type === CampaignTypeBackend.SURVEY"
              value="answers"
              class="w-fit"
              :text="`${$t('Answers')} (${completionAll?.['submitted'] || 0})`"
            />
          </v-tabs>

          <div v-if="isMdScreen" class="flex items-center gap-2 border-b border-opacity-100 border-surfaceLight">

              <svn-pro-tooltip :text="$t('The co-pilot will have the same rights as you on this campaign. However, he will not be able to share access with anyone else.')">
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  :disabled="isTrashed"
                  icon="custom:mingcute:group-2-line"
                  color="onSurfaceVariant"
                  variant="text"
                  @click="copilotsRef.manageCopilots.dialog = true"
                />
              </template>
            </svn-pro-tooltip>

            <svn-pro-menu
              v-model="menu"
              :disabled="isTrashed"
              :items="menuItems"
            >
              <template #activator>
                <svn-pro-icon-button
                  :disabled="isTrashed"
                  icon="custom:mingcute:more-2-line"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>
            </svn-pro-menu>
          </div>
        </div>
      </div>

      <v-window v-model="tab">
        <v-window-item value="overview">
          <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-0 mt-4 md:!mt-6">
            <campaign-overview v-if="campaign" />
          </div>
        </v-window-item>

        <v-window-item value="participants">
          <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-0 mt-4 md:!mt-6">
            <CampaignParticipants
              :set="set"
              :anonymous="isAnonymous"
              :campaign-overview="campaignOverview"
              :overview="overview"
              :is-trashed="isTrashed"
              @fetch-from-campaign-show-search="fetchCurrentCampaign"
            />
          </div>
        </v-window-item>

        <div class="w-full h-full flex flex-col gap-4 px-5 md:!px-0 mt-4 md:!mt-6">
        </div>
        <!-- <v-window-item value="answers">
        </v-window-item> -->
      </v-window>

      <BktSurveyAnswers
        v-if="tab === 'answers'"
        :campaign="campaign"
        :is-trashed="isTrashed"
        class="transition-all"
        :class="tab === 'answers' ? 'opacity-100' : 'opacity-0'"
      />
    </div>
  </div>

  <pop-up-edit-date
    ref="deadlineModal"
    min
    :date-value="new Date(campaign?.deadline)"
    @save="updateDate"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-edit-date>

  <dialog-validation-pro
    ref="deleteDialog"
    icon="noto:warning"
    :title="$t('Delete the campaign ?')"
    :action-one-title="$t('Delete')"
    :action-two-title="$t('Cancel')"
    :content-text="$t('Deleted campaigns are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteCampaign"
  >
    <template #activator>
      {{ null }}
    </template>
  </dialog-validation-pro>

  <pop-up-manage-copilots
    ref="copilotsRef"
    :campaign-id="campaign?.id"
  >
    <template #activator>
      {{ null }}
    </template>
  </pop-up-manage-copilots>

  <dialog-validation-pro
    ref="deleteTargetRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Campaign will be deleted`)"
    :content-text="$t('This is a permanent action.')"
    @click-primary-button="deleteCampaignPermanently"
    @click-secondary-button="deleteTargetRef.dialogRef.dialog  = false"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import moment from "moment";
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n.js";
import axios from "@/tools/axios-service.js";
import filters from "../../../tools/filters";
import { useRoute, useRouter } from "vue-router";
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile.js";
import { useCampaignStore } from "@/store/campaign.js";
import TrashWarnning from "@/components/TrashWarnning.vue";
import BktSurveyAnswers from "@/components/BktSurveyAnswers.vue";
import { useCampaignTrashStore } from "@/store/campaign-trash.js";
import { onMounted, onUnmounted, ref, watch, computed } from "vue";
import { CampaignTypeBackend } from "@/constants/CampaignTypeEnum.js";
import CampaignOverview from "@/components/campaign/CampaignOverview.vue";
import CampaignParticipants from "@/components/campaign/CampaignParticipants.vue";
import PopUpEditDate from "../../../components/popUpComponents/PopUpEditDate.vue";
import PopUpManageCopilots from "../../../components/popUpComponents/PopUpManageCopilots.vue";
import DialogValidationPro from "../../../components/BktPopUp/Dialogs/DialogValidationPro.vue";

const props = defineProps({
  campaignId: { type: String, default: null },
  overview: { type: String, default: null },
  currentUserId: { type: String, default: null },
});

const menu = ref(false);
const deadline = ref(null);
const tab = ref('overview');
const isTrashed = ref(false);
const copilotsRef = ref(null);
const isAnonymous = ref(true);
const participants = ref(null);
const deleteDialog = ref(null);
const deadlineModal = ref(null);
const deleteTargetRef = ref(null);
const menuItems = ref([
  {
    id: 'edit_deadline',
    title: i18n.global.t('Edit deadline'),
    onClick: () => deadlineModal.value.dialog = true,
  },
  {
    id: 'delete_campaign',
    title: i18n.global.t('Delete campaign'),
    error: true,
    onClick: () => deleteDialog.value.dialogRef.dialog = true,
  },
])
const breadcrumbItems = computed(() => {
  return [
    {
      title: 'Campaigns',
      disabled: false,
      href: '/v2/interviews/campaigns',
    },
    {
      title: campaign?.value?.title,
      disabled: false,
      href: '',
    },
  ]
});

const { isMdScreen } = storeToRefs(useMobileStore());
const { campaign, campaignOverview, completionAll, set, error: errorEntity } = storeToRefs(useCampaignStore());

const route = useRoute();
const router = useRouter();
const snackbar = useSnackbar();
const { fetchCompletionAll } = useCampaignStore();
const { destroyPermanentlyCampaign, restoreCampaign } = useCampaignTrashStore();
const { fetchCampaign, updateParams, resetStates, fetchCampaignInterview, fetchCampaignFeedback, fetchCampaignSurvey, updateCampaign } = useCampaignStore();

onMounted( async() => {
  tab.value = route?.query?.tab || 'overview'

  updateParams({ "page[number]": 1 });
  await fetchCampaign(props?.campaignId || route?.params?.id);
  await fetchCurrentCampaign()
  await fetchCompletionAll(props?.campaignId || route?.params?.id)

  isTrashed.value = campaign?.value?.campaign_status === 'trashed'
})

const updateDate = async (date) => {
  deadline.value = moment(date).format('DD/MM/YYYY')

  const year = filters.formatDate(deadline?.value, "YYYY");
  const month = filters.formatDate(deadline?.value, "M");
  const day = filters.formatDate(deadline?.value, "DD");

  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${campaign?.value?.id ? campaign?.value?.id : deadline?.value}.json`, {
      campaign: {
        year: year,
        month: month,
        day: day,
      },
    });
    snackbar.setCustomClass('mb-4').setBgColor('onSurface').displaySnackBar('Deadline has been changed successfully.')
    await fetchCampaign(campaign?.value?.id)

    menu.value = false
  } catch (e) {
    snackbar.setCustomClass('mb-4').setBgColor('onSurface').displaySnackBar('Error updating campaign deadline.')
  }
}

const fetchCurrentCampaign = async () => {
  switch (campaign?.value?.campaign_type) {
    case CampaignTypeBackend?.ONE_TO_ONE:
      await fetchCampaignInterview(props?.campaignId || route?.params?.id)
      return
    case CampaignTypeBackend?.FEEDBACK_360:
      await fetchCampaignFeedback(props?.campaignId || route?.params?.id)
      return
    case CampaignTypeBackend?.SURVEY:
      await fetchCampaignSurvey(props?.campaignId || route?.params?.id)
      return
    default:
      return
  }
}

watch(set, (_) => {
  if (campaign.value?.campaign_type !== CampaignTypeBackend.SURVEY) return

  participants.value = set.value?.map((int) => {
    return {
      id: int.id,
      fullname: int.employee.fullname,
      firstname: int.employee.firstname,
      lastname: int.employee.lastname,
      avatar: int.employee.avatar,
      employeeId: int.employee.id,
      job_title: int.employee.job_title,
      tags: int.employee?.tags?.map((tag) => {
        return {
          tag_name: tag.name
        }
      }) || [],
      completion: int.status,
    }
  })
})

watch(campaign, (newVal, _) => {
  if (newVal.campaign_type !== CampaignTypeBackend.SURVEY) return
  isAnonymous.value = newVal?.anonymize_answers
})

watch(tab, (newVal, _) => {
  const id = campaign?.value?.id

  router.push({
    name: 'campaign_overview',
    params: { id },
    query: { tab: newVal }
  })
})

onUnmounted(() => {
  resetStates()
})

const deleteCampaign = async () => {
  try {
    await axios.delete(`/api/v2/interview_app/campaigns/${campaign?.value?.id}.json`);

    snackbar.setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').setBgColor('onSurface').displaySnackBar('Campaign has been deleted successfully.')
    router.push({ name: 'campaigns' })

    menu.value = false
  } catch (error) {
    snackbar.setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').setBgColor('onSurface').displaySnackBar('Error deleting campaign.')
  }
};

const restoreDeletedItem = async () => {
  try {
    await restoreCampaign(campaign.value.id)
    snackbar.setBgColor('onSurface').setMsg('Campaign restored successfully.').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').displaySnackBar()
    isTrashed.value = false
  } catch (e) {
    snackbar.setBgColor('onSurface').setMsg('Error while restoring a campaign').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').displaySnackBar()
  }
}

const deleteCampaignPermanently = async () => {
  try {
    await destroyPermanentlyCampaign(campaign.value.id)
    snackbar.setBgColor('onSurface').setMsg('Campaign has been permanently deleted').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').displaySnackBar()
    await router.push({ name: 'campaigns' });
  } catch (e) {
    snackbar.setBgColor('onSurface').setMsg('Error while deleting permanently a campaign').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[88px]').displaySnackBar()
  }
}

const saveSlackAccessToken = debounce(async (e) => {
  const accessToken = e.detail

  const ret = await updateCampaign(props.campaignId || route.params.id, {
    slack_access_token: accessToken
  })

  if (!ret) {
    snackbar.setStatus("error").displaySnackBar(errorEntity.value?.message);
  }
}, 200);

const joinChannel = async (channelId, channelName) => {
  try {
    const res = await axiosService.post(
      `/api/v2/interview_app/campaigns/${campaign.value.id}/slack_join_channel`,
      {
        slack_channel_id: channelId
      }
    )

    if (campaign.value?.slack_channel_id && campaign.value?.slack_channel_id !== channelId) {
      snackbar.setStatus("updated").displaySnackBar(t('Slack notifications have been redirected to the channel', { channelName: channelName }));
    }
    campaign.value = res.data
  } catch (e) {
    snackbar.setStatus("error").displaySnackBar("Couldn't join the channel");
  }
}

const slackLeaveChannel = async (_) => {
  openPopUp({
    componentName: "PopUpLeaveChannelSlack",
    confirm: async () => {
      const ret = await updateCampaign(props.campaignId || route.params.id, {
        slack_channel_id: null
      })

      if (!ret) {
        snackbar.setStatus("error").displaySnackBar(errorEntity.value?.message);
      }
      closePopUp()
    },
    close() {},
  });
}
</script>

<style scoped>
:deep(.v-slide-group__container) {
  height: 100%;
  display: flex;
  align-items: flex-end;
  overflow: visible;
  contain: none;
}
</style>