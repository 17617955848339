<template>
  <div
    class="p-4 "
  >
    <div :id="question.id" class="w-full flex flex-col items-start gap-4 self-stretch">
      <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
        {{ question.text }}
      </svn-pro-title>
      <div
        v-if="question?.description"
        class="flex flex-col items-start gap-4 self-stretch"
      >
        <svn-pro-divider class="w-full border-[#767680]"/>

        <div
          class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
          v-html="question.description"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

defineProps(['question'])
</script>
