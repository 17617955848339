<template>
  <svn-pro-data-table
    v-model="selectedUsers"
    :headers="roadmapTargets?.length ? headers : []"
    :items="roadmapTargets ?? []"
    :items-per-page="-1"
    :loading="roadmapTargets ? loading : true"
    item-value="id"
    :show-select="roadmapTargets?.length ? true : false"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTargetShow(item?.id)">
        <td class="px-2">
          <v-checkbox-btn
            v-model="selectedUsers"
            hide-details
            :value="item.id"
            @click.stop
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            medium
            body-large
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="avatar_text"
            :avatar="item?.employee?.avatar?.['50']"
            :text="`${item?.employee?.firstname} ${item?.employee?.lastname}`"
          />
        </td>

        <td>
          <my-roadmaps-objective-indicator :objective-indicator="item?.objective_indicator" />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.due_date, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <dialog-validation-pro
                icon="noto:warning"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Delete')"
                :title="$t('Target will be deleted')"
                :content-text="$t('Deleted targets are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteTarget(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :active="false" value="delete_template">
                    <svn-pro-text body-large regular color="error">
                      {{ $t('Delete target') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </dialog-validation-pro>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('New target')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') :
          $t('Hmm, it seems there is no target yet. Create your first target')"
        @click-primary="openNewTargetModal"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>

  <modal-create-new-target ref="refModalCreateNewTarget">
    <template #activator="{ props }">
      {{ null }}
    </template>
  </modal-create-new-target>
</template>

<script setup>
import { ref } from 'vue';
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import { useRoadmapTargetStore } from "@/store/roadmap-target.js";
import MyRoadmapsObjectiveIndicator from "../MyRoadmapsObjectiveIndicator.vue";
import DialogValidationPro from '../../BktPopUp/Dialogs/DialogValidationPro.vue';
import ModalCreateNewTarget from '../../BktPopUp/Modals/ModalCreateNewTarget.vue';

const props = defineProps({
  search: { type: String, default: null },
  loading: { type: Boolean, required: true },
  roadmapTargets: { type: Object, default: () => {} },
  pagination: {type: Object, default: () => { return null }},
})

const emit = defineEmits(['fetch-new-page-page', 'delete-target']);

const { isMdScreen } = storeToRefs(useMobileStore());
const { selectedUsers } = storeToRefs(useRoadmapTargetStore());

const router = useRouter();

const refModalCreateNewTarget = ref(null)
const headers = ref([
  {
    align: "start",
    key: "title",
    sortable: true,
    title: i18n.global.t('Target'),
    minWidth: 300,
  },
  {
    align: "start",
    key: "fullname",
    sortable: false,
    title: i18n.global.t('People'),
    minWidth: 250,
  },
  {
    align: "start",
    key: "completion",
    sortable: false,
    title: i18n.global.t('Completion'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "deadline",
    sortable: false,
    title: i18n.global.t('Deadline'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "options",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
]);

const fetchNewPage = (page) => {
  emit('fetch-new-page-page', page)
};

const deleteTarget = async (id) => {
  emit('delete-target', id)
}

const goToTargetShow = (targetId) => {
  router.push({ name: 'roadmaps_targets_show', query: { from: 'targets' }, params: { id: targetId } })
}

const openNewTargetModal = () => {
  refModalCreateNewTarget.value.modalNewTarget.dialog = true
}
</script>

<style scoped>

</style>
