<template>
  <v-navigation-drawer
    :model-value="modelValue"
    disable-resize-watcher
    :width="width"
    :location="location"
    :class="isMobile ? 'h-full' : `!h-[${screenHeight}px] !fixed !pt-[75px]`"
  >
    <div class="w-full flex flex-col items-start relative h-full">
      <!-- Header section -->
      <div
        class="flex px-4 justify-center items-center self-stretch relative"
        :class="isMobile ? '' : 'mt-[73px]'"
      >
        <p class="text-fakeBlack my-[23px] text-center text-xl font-medium self-center">
          {{ $t('Comments ()', { count: learnModulesComments?.length || 0 }) }}
        </p>

        <div
          v-if="mobile"
          class="cursor-pointer absolute top-5 right-4"
          @click="emit('close-drawer')"
        >
          <Icon
            icon="mdi-close"
            height="32"
            width="32"
            class="text-darkGrey"
          />
        </div>
      </div>

      <div class="flex h-full w-full overflow-y-auto overflow-x-hidden">
        <!-- There are comments -->
        <div
          v-if="learnModulesComments?.length"
          class="w-full flex flex-col"
          :class="mobile ? 'h-full' : 'pb-[155px]'"
        >
          <comment-card
            v-for="(comment, index) in learnModulesComments"
            :key="index"
            :comment="comment"
            class="border-t border-t-middleGrey"
            @delete-comment="emit('delete-comment', $event)"
          />
        </div>

        <!-- No comments -->
        <div
          v-else
          class="flex flex-col justify-center items-center self-stretch flex-1  h-full w-full"
        >
          <div
            class="max-w-[285px] flex flex-col justify-center items-center gap-4 h-full w-full"
          >
            <Icon
              icon="noto:winking-face"
              height="24"
              width="24"
            />

            <p class="text-fakeBlack text-sm text-center font-medium">
              {{ $t('Hmm, it seems there is no comment yet. Be the first !') }}
            </p>
          </div>
        </div>
      </div>

      <!-- Input section -->
      <div
        class="w-full flex p-4 gap-2 items-center inputSection"
        :class="mobile ? '' : 'bg-white h-[80px]'"
      >
        <!-- User avatar image -->
        <svn-avatar
          :avatar="avatar"
          :firstname="firstname"
          :lastname="lastname"
          size="lg"
        />

        <!-- Text area to add comment -->
        <v-textarea
          v-model="userComment"
          hide-details
          density="compact"
          variant="outlined"
          class="flex-1"
          :placeholder="$t('Leave a comment')"
          :auto-grow="false"
          :no-resize="true"
          :rows="1"
        />

        <!-- Submit comment button -->
        <v-btn
          icon="mdi-send"
          color="primary"
          variant="plain"
          :height="48"
          :width="48"
          :disabled="!userComment?.length"
          @click="addComment"
        />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import CommentCard from './CommentCard.vue';
import { useUserStore } from '@/store/user';
import { useMobileStore } from '@/store/mobile';

onMounted(() => {
  screenHeight.value = window.innerHeight
})

const props = defineProps({
  location: { type: String, default: ''},
  width: { type: Number, default: 526 },
  learnModulesComments: { type: Array, default: () => [] },
  modelValue: { type: Boolean, default: false },
  mobile: { type: Boolean, default: false },
})

const emit = defineEmits(['delete-comment', 'add-comment-reply', 'close-drawer', 'update:modelValue'])

const { avatar, firstname, lastname } = storeToRefs(useUserStore())

const { isMobile } = storeToRefs(useMobileStore())

const userComment = ref('')

const screenHeight = ref(0)

const addComment = () => {
  emit('add-comment-reply', userComment.value)
  userComment.value = ''
}
</script>

<style scoped>
.inputSection {
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
