<template>
  <div class="w-full flex items-center gap-4">
    <svn-pro-autocomplete
      v-model="campaignDraft.default_template_id"
      :label="$t('Select a template*')"
      :items="interviewForms"
      :error="error"
      item-title="title"
      item-value="id"
      :class="['flex-1', fullWidth ? '!w-full' : 'sm:!w-1/2']"
    />

    <v-spacer :class="['hidden', fullWidth ? '' : 'sm:!flex']" />
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";

const props = defineProps({
  fullWidth: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
})

const { fetchInterviewForms } = useCompanyStore();

const { interviewForms } = storeToRefs(useCompanyStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const loading = ref(true);

onMounted(async () => {
  if (!interviewForms?.value?.length) {
    let formType = ''
    if (campaignDraft?.value?.campaign_type == 'Survey') {
      formType = 'InterviewApp::Forms::Templates::Survey'
    } else if (campaignDraft?.value?.campaign_type == 'Feedback') {
      formType = 'InterviewApp::Forms::Templates::Feedback'
    } else {
      formType = 'InterviewApp::Forms::Templates::OneToOne'
    }
    await fetchInterviewForms(formType);
    loading.value = false;
  } else {
    loading.value = false;
  }
});
</script>