<template>
  <svn-pro-dialog
    ref="dialogRef"
    :icon="icon"
    :title="title"
    :subtitle="subtitle"
    :content-text="contentText"
    :content-height="contentHeight"
    :action-one-title="actionOneTitle"
    :action-two-title="actionTwoTitle"
    @click-outside="clickOutside"
    @click-primary-button="clickPrimaryButton"
    @click-secondary-button="clickSecondaryButton"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>
  </svn-pro-dialog>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  icon: { type: String, default: '' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  contentText: { type: String, default: '' },
  contentHeight: { type: Number, default: undefined },
  actionOneTitle: { type: String, default: '' },
  actionTwoTitle: { type: String, default: '' },
})

const emit = defineEmits(['click-primary-button', 'click-secondary-button', 'click-outside'])

const dialogRef = ref(null)

const clickOutside = () => {
  emit('click-outside')
}

const clickPrimaryButton = () => {
  dialogRef.value.dialog = false

  emit('click-primary-button')
}

const clickSecondaryButton = () => {
  dialogRef.value.dialog = false

  emit('click-secondary-button')
}

defineExpose({
  dialogRef
})
</script>
