<template>
  <div
    class="flex flex-col gap-8"
    >
    <svn-pro-text-field
      v-model="input.title"
      :label="$t('Question')"
      variant="outlined"
      @update:model-value="updateInputTitle()"
    />

    <div class="flex flex-col gap-3">
      <svn-pro-text subtitle-large medium color="onSurfaceVariant" class="text-start">
        {{ $t('Answer options (use checkbox for right answers)') }}
      </svn-pro-text>

      <div class="flex flex-col justify-start items-start gap-4">
        <svn-pro-text-field
          v-for="(opt, idx) in input.proposals"
          :key="idx"
          v-model="opt.proposal"
          class="w-full"
          :label="`Option ${getAlphabetFromIndex(idx)}`"
          variant="outlined"
          append
          @update:model-value="updateInput()"
        >
          <template #append>
            <svn-pro-checkbox
              v-model="opt.correct"
              @update:model-value="updateInput()"
            />

            <svn-pro-icon-button
              v-if="input.proposals.length > 1 "
              @click="removeOption(idx)"
              variant="text"
              color="onSurfaceVariant"
              icon="custom:mingcute:delete-2-line"
            />
          </template>
        </svn-pro-text-field>

        <svn-pro-button
          variant="text"
          :text="$t('Add answer option')"
          class="w-fit transition-all"
          :prepend-icon="'custom:mingcute:add-line'"
          @click="addQuestion()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import LearnAddQuestionBlock from "@/components/learnApp/moduleBlock/createBlock/LearnAddQuestionBlock.vue";

const emit = defineEmits(['update-input', 'add-option', 'remove-option', 'remove-input', 'duplicate-option'])

const props = defineProps({
  input: { type: Object, default: () => {} },
  update: {type: Function, default: () => {} },
  canRemoveInput: { type: Boolean, default: true }
})


const addQuestion = () => {
  emit('add-option',  props.input)
}

const removeOption= (index) => {
  emit('remove-option', { input: props.input, index: index})
}

const updateInput = () => {
    emit('update-input', props.input)
}

const updateInputTitle = () => {
  emit('update-input', props.input)
}

const beforeSaveValidation = (input, value) => {
  return input.proposals.filter(proposal => proposal.proposal == value).length > 1
}

const getAlphabetFromIndex = (index) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (index >= 0 && index < alphabet.length) {
    return alphabet[index];
  } else {
    return ''; // Return null if the index is out of bounds
  }
}
</script>
