<template>
  <svn-pro-modal
    ref="modalNewTarget"
    :title="$t('New target')"
    :width="872"
    @click-close="resetDisplayTargetNew"
    @click-outside="resetDisplayTargetNew"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <target-new
        v-if="displayTargetNew"
        :users-from-overlay="usersFromOverlay"
        :create-draft="createDraft"
        :campaign_id="campaignId"
        :overlay-mode="overlayMode"
        :objective-template-id="selectedTemplateId"
        @close="closePopUp"
        :from="from"
      />

      <div
        v-else
        class="flex flex-col gap-6 items-start"
      >
        <div class="flex flex-col gap-4 items-start">
          <svn-pro-text subtitle-large medium>
            {{ $t('Create your own target') }}
          </svn-pro-text>

          <svn-pro-button
            variant="flat"
            :text="$t('Start from scratch')"
            @click="goToTargetCreate"
          />
        </div>

        <div class="w-full flex flex-col gap-4 items-start">
          <svn-pro-text subtitle-large medium>
            {{ $t('Use existing template') }}
          </svn-pro-text>

          <div class="w-full flex flex-col sm:flex-row gap-2">
            <svn-pro-text-field
              v-model="title"
              variant="outlined"
              :label="$t('Search')"
              class="w-full sm:w-[65%]"
              prepend-inner-icon="custom:mingcute:search-2-line"
              @update:model-value="search"
            />

            <svn-pro-select
              v-model="typeSelected"
              :items="types"
              density="comfortable"
              variant="outlined"
              item-title="name"
              item-value="value"
              :label="$t('Indicator type')"
              @update:model-value="search"
            />
          </div>

          <div class="w-full flex flex-col gap-2 sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-x-2 sm:gap-y-2">
            <svn-pro-card
              v-for="(template, index) in templates"
              :key="index"
              :active="false"
              color="borderColor"
              @click="createTargetFromTemplate(template)"
            >
              <div class="w-full flex flex-col gap-4 p-6">
                <div class="flex flex-col gap-2">
                  <div class="flex gap-2 items-center">
                    <div>
                      <Icon
                        :icon="getTemplateIconByIndicatorType(template.objective_indicator?.indicator_type)"
                        height="32"
                        width="32"
                        class="text-primary"
                      />
                    </div>

                    <svn-pro-text body-large medium color="primary">
                      {{ getTemplateIndicatorTextByIndicatorType(template.objective_indicator?.indicator_type) }}
                    </svn-pro-text>
                  </div>

                  <svn-pro-text body-medium medium class="line-clamp-2">
                    {{ template.title }}
                  </svn-pro-text>
                </div>

                <div class="flex gap-1">
                  <div>
                    <Icon
                      icon="mingcute:calendar-month-line"
                      height="20"
                      width="20"
                      class="text-onSurfaceVariant"
                    />
                  </div>

                  <svn-pro-text body-medium regular color="onSurfaceVariant">
                    {{ template.due_date || $t('No deadline') }}
                  </svn-pro-text>
                </div>
              </div>
            </svn-pro-card>
          </div>

          <svn-pro-empty-states
            v-if="!templates?.length"
            :variant="'index'"
            :title="$t('Nothing to show for now')"
            :size="isMobile ? 'compact' : 'default'"
            :supporting-text="$t('Hmm, it seems you have no template yet.')"
          />
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { storeToRefs } from "pinia";
import { Icon } from '@iconify/vue';
import { ref, onMounted } from 'vue';
import { useMobileStore } from "@/store/mobile";
import { useRouter } from "vue-router";
import axiosService from "@/tools/axios-service.js";
import TargetNew from '@/views/roadmaps/Targets/New.vue';

onMounted(() => {
  getTemplates();
})

const props = defineProps({
  from: { type: String, default: undefined },
  overlayMode: { type: Boolean, default: false },
  createDraft: { type: Boolean, default: false },
  campaignId: { type: Number, default: undefined },
  usersFromOverlay: { type: Array, default: () => [] },
  objectiveTemplateId: { type: Number, default: undefined },
})

const router = useRouter();
const displayTargetNew = ref(false)
const { isMobile } = storeToRefs(useMobileStore());

const title = ref(null);
const templates = ref([]);
const modalNewTarget = ref(null);
const selectedTemplateId = ref(null);
const typeSelected = ref(null);
const types = [
  { name: i18n.global.t('All'), value: null },
  { name: i18n.global.t('Boolean'), value: 'boolean' },
  { name: i18n.global.t('Numeric value'), value: 'numeric_value' },
  { name: i18n.global.t('Percentage'), value: 'percentage' },
  { name: i18n.global.t('Multi choice'), value: 'multi_choice' },
]

const getTemplates = async (params = null) => {
  const res = await axiosService.get('/api/v1/companies/roadmap_templates', { params });

  templates.value = res.data['objective_elements']
}

const closePopUp = () => {
  modalNewTarget.value.dialog = false
}

const resetDisplayTargetNew = () => {
  const t = setTimeout(() => {
    displayTargetNew.value = false
  }, 1000);

  // clearTimeout(t)
}

const search = debounce(() => {
  getTemplates({ title: title.value, indicator_type: typeSelected.value })
}, 400)

const goToTargetCreate = () => {
  if (props.overlayMode) {
    displayTargetNew.value = true
  } else {
    router.push({ name: 'roadmaps_targets_new', query: {
      usersFromOverlay: props?.usersFromOverlay,
      createDraft: props?.createDraft,
      overlayMode: props?.overlayMode,
      campaign_id: props?.campaignId,
      fromRoute: props?.from,
    }});
  }
}

const createTargetFromTemplate = (template) => {
  if (props.overlayMode) {
    selectedTemplateId.value = template.id
    displayTargetNew.value = true
  } else {
    selectedTemplateId.value = template?.id
    router.push({ name: 'roadmaps_targets_new', query: {
      objectiveTemplateId: selectedTemplateId.value,
      usersFromOverlay: props?.usersFromOverlay,
      createDraft: props?.createDraft,
      overlayMode: props?.overlayMode,
      campaign_id: props?.campaignId,
      fromRoute: props?.from,
    }})
  }
}

const getTemplateIconByIndicatorType = (indicatorType) => {
  if (indicatorType === 'numeric_value') {
    return 'mingcute:hashtag-line';
  }
  else if (indicatorType === 'percentage') {
    return 'mingcute:sale-line';
  }
  else if (indicatorType === 'multi_choice') {
    return 'mingcute:checkbox-line';
  }
  else if (indicatorType === 'boolean') {
    return 'mingcute:toggle-left-line';
  }
}

const getTemplateIndicatorTextByIndicatorType = (indicatorType) => {
  if (indicatorType === 'numeric_value') {
    return i18n.global.t('Number');
  }
  else if (indicatorType === 'percentage') {
    return i18n.global.t('Percent');
  }
  else if (indicatorType === 'multi_choice') {
    return i18n.global.t('Multi-choice');
  }
  else if (indicatorType === 'boolean') {
    return i18n.global.t('True/False');
  }
}

defineExpose({
  modalNewTarget
})
</script>