<template>
  <div class="w-full flex flex-col gap-4">
    <svn-pro-text-field
      v-model="searchUserList"
      :label="$t('Search')"
      class="mt-4"
      prepend-inner-icon="custom:mingcute:search-2-line"
    />

    <div class="flex flex-col gap-2">
      <svn-pro-text subtitle-large medium>
        {{ $t("Selected participants : ") }} {{ campaignDraft?.interviewee_ids?.length || 0 }}
      </svn-pro-text>
      
      <v-data-table
        v-model="campaignDraft.interviewee_ids"
        :headers="users?.length ? headers : []"
        :items="users ?? []"
        :items-per-page="10"
        :page="page"
        :search="searchUserList"
        select-strategy="all"
        :loading="users ? loading : true"
        item-value="id"
        :pagination-total-pages="totalPages"
        :show-select="users?.length ? true : false"
      >
        <template #item="{ item }">
          <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]">
            <!-- Checkbox -->
            <td class="px-2">
              <v-checkbox-btn
                v-model="campaignDraft.interviewee_ids"
                hide-details
                :value="item?.id"
              />
            </td>

            <!-- User with avatar, fullname, email -->
            <td>
              <svn-pro-data-table-cell
                cell-type="avatar_text_supporting_text"
                :avatar="item?.avatar?.['50']"
                :text="item?.fullname"
                :supporting-text="item?.email"
              />
            </td>

            <!-- Tags -->
            <td>
              <svn-pro-data-table-cell
                cell-type="textt"
                :text="item?.job_title"
              />
            </td>
          </tr>
        </template>

        <template #bottom="{ pageCount }">
          <svn-pro-pagination
            v-if="users?.length > 10"
            v-model="page"
            :length="pageCount"
            class="mt-6"
          />
        </template>

        <template #no-data>
          <svn-pro-empty-states
            actions
            :variant="searchUserList ? 'results' : 'index'"
            :title="$t('Nothing to show for now')"
            :size="isMdScreen ? 'default' : 'compact'"
            :supporting-text="searchUserList ? $t('Oops, we didn’t find any results matching your search.') : $t('Hmm, it seems there is no user yet.')"
          />
        </template>

        <template #loading>
          <div class="w-full h-[693px] flex justify-center items-center">
            <svn-pro-progress-circular
              :size="48"
              :width="4"
              indeterminate
            />
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n.js";
import { ref, onMounted, computed } from "vue";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import {useCompanyStore } from "@/store/company.js";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";

const snackbar = useSnackbar();
const { fetchCompanyUsers } = useCompanyStore();

const { users } = storeToRefs(useCompanyStore());
const { isMdScreen } = storeToRefs(useMobileStore());
const { campaignDraft } = storeToRefs(useCampaignDraftStore());

const page = ref(1);
const headers = ref([
  {
    align: "start",
    key: "fullname",
    sortable: true,
    title: i18n.global.t("People"),
    minWidth: 225,
  },
  {
    align: "start",
    key: "job_title",
    sortable: true,
    title:  i18n.global.t("Job title"),
    minWidth: 160,
  },
]);
const loading = ref(false);
const searchUserList = ref("");
const totalPages = computed(() => {
  return (users?.value?.length / 10)
})

onMounted(async() => {
  try {
    if (!users?.value?.length) {
      loading.value = true
      await fetchCompanyUsers()
      loading.value = false
    }
  } catch (error) {
    snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("Error fetching users.").displaySnackBar()
  }
});
</script>