import LearnInput from './learn_input';

export default class Quiz {
  constructor({ id, learnInputs }) {
    this.id = id;
    if (learnInputs) {
      this.learnInputs = [];

      learnInputs.forEach((item) => this.learnInputs.push(LearnInput.fromJson(item)));
    }
  }

  static fromJson(data) {
    return new Quiz({
      id: data['id'],
      learnInputs: data['learn_inputs'],
    });
  }
}
