<template>
  <bkt-pop-up-modal-skeleton
    ref="modalSkeleton"
    fullscreen
  >
    <template #button>
      <div @click="updateBreadcrumbs">
        <slot name="button" />
      </div>
    </template>

    <template #header="{ close }">
      <module-show-header-buttons
        modal
        @close-and-go-back="handleClose"
      />
    </template>

    <template #body-content>

      <div class="flex flex-col gap-8 gap-h-full overflow-y-auto pb-[80px] lg:py-8">
        <!-- Breadcrumbs -->
        <div class="hidden lg:flex">
          <!-- <bkt-breadcrumbs>
            {{ title }}
          </bkt-breadcrumbs> -->
        </div>

        <div class="flex flex-col md:w-[758px] mx-auto px-4 lg:!px-[0px] gap-6 lg:gap-8 w-full">
          <!-- Module Cover -->
          <bkt-image-cover-position
            mode="show"
            :url="learnModule?.cover_url?.['original'] ? learnModule?.cover_url?.['original'] : ''"
            :coordinates="{ left: learnModule?.cover_offset_left ?? 0, top: learnModule?.cover_offset_top ?? 0 }"
          />

          <div class="flex flex-col gap-6">
            <!-- Module title -->
            <svn-pro-title h5 medium>
              {{ learnModule?.title }}
            </svn-pro-title>

            <!-- Bunch of infos -->
            <div class="flex flex-col gap-4">
              <!-- Duration -->
              <div class="flex items-center gap-1">
                <div>
                  <Icon
                    icon="mingcute:time-line"
                    height="16"
                    width="16"
                    class="text-onSurfaceVariant"
                  />
                </div>

                <svn-pro-text body-medium regular color="onSurfaceVariant">
                  {{ $t('Total duration') }}:
                </svn-pro-text>

                <svn-pro-text body-medium bold color="onSurfaceVariant">
                  {{ learnModule?.duration || $t('No duration') }}
                </svn-pro-text>
              </div>

              <!-- Themes -->
              <div
                v-if="learnModule?.themes?.length"
                class="w-full flex items-start content-start gap-2 flex-wrap"
              >
                <svn-pro-info-tag
                  v-for="(theme) in learnModule?.themes"
                  :key="theme"
                  tag-type="info"
                  tag-style="outlined"
                  tag-size="compact"
                  :text="theme?.name"
                />
              </div>

              <!-- No theme -->
              <svn-pro-info-tag
                v-else
                tag-type="info"
                tag-style="outlined"
                tag-size="compact"
                :text="$t('No theme')"
              />

              <!-- Module acquired -->
              <div
                v-if="learnModule?.submission?.status === 'acquired'"
                class="flex itemx-center gap-1"
              >
                <div>
                  <Icon
                    icon="mingcute:check-circle-line"
                    height="16"
                    width="16"
                    class="text-success"
                  />
                </div>

                <svn-pro-text body-medium regular color="success">
                  {{ $t('Acquired on') }} {{ filters.formatDate( learnModuleSubmissionUpdatedAt ? learnModuleSubmissionUpdatedAt : learnModule?.submission_updated_at, 'DD/MM/YYYY') }}
                </svn-pro-text>
              </div>
            </div>

            <!-- Divider -->
            <svn-pro-divider color="[#767680]" class="border-opacity-100" />

            <!-- Content -->
            <div class="w-full flex flex-col gap-4">
              <!-- Content title -->
              <svn-pro-title h6 medium>
                {{ $t('Content') }}
              </svn-pro-title>

              <!-- Rich text editor -->
              <svn-tiptap
              v-if="editorData?.blocks?.length"
              :create-image-url="`/api/v1/editor_contents/fake-id/upload_image`"
                :html-data="editorData?.blocks"
                :extension-selection="AllTipTapPlugins"
                :extension-slash-command="AllTipTapPlugins"
                :is-editable="false"
              />
            </div>
            <div class="w-full flex flex-col gap-8">
              <!-- Evaluations -->
              <div
                v-if="(learnModule?.has_learn_pieces_approval || learnModule?.has_learn_pieces_quiz || learnModule?.has_learn_pieces_face_to_face_evaluation)"
                class="w-full flex flex-col gap-6"
              >
                <!-- Self evaluation is present -->
                <div
                  v-if="learnModule?.has_learn_pieces_approval"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{ $t("🙌 You've reached the end! Did you learn all the following learning objectives?") }}
                    </svn-pro-title>

                    <svn-pro-text-area
                      v-if="learnApprovalInput?.text"
                      v-model="learnApprovalInput.text"
                      :variant="'text'"
                      :readonly="true"
                      :label="null"
                      class="text-onSurfaceVariant text-base font-normal leading-[28px] tracking-[0.15px]"
                    />
                  </div>

                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <svn-pro-button
                    v-bind="props"
                    variant="flat"
                    class="w-full md:!w-fit"
                    :text="$t(`Yes, it's all clear to me!`)"
                  />
                  </div>
                </div>

                <!-- Evaluation Quiz is present -->
                <div
                  v-else-if="learnModule?.has_learn_pieces_quiz"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col gap-[10px]">
                    <svn-pro-title h5 medium>
                      {{ $t("🙌 You’ve reached the end ! Ready for the quiz ?") }}
                    </svn-pro-title>

                    <svn-pro-text subtitle-large regular color="onSurfaceVariant">
                      {{ $t("To complete this module, evaluate yourself with a quiz.") }}
                    </svn-pro-text>
                  </div>

                  <!-- Start the Quiz block -->
                  <div class="flex items-center w-full">
                    <svn-pro-button
                      variant="flat"
                      :text=" $t('Start the quiz !')"
                    />
                  </div>
                </div>

                <!-- Evaluation Face to Face is present -->
                <div
                  v-else-if="learnModule?.has_learn_pieces_face_to_face_evaluation"
                  class="w-full flex flex-col gap-8 p-6 border border-borderColor border-opacity-100 rounded-[8px]"
                >
                  <!-- Titles -->
                  <div class="flex flex-col items-start gap-[10px] self-stretch">
                    <svn-pro-title v-if="learnModule?.submission?.status === 'in_progress'" :h5="isMdScreen" :h6="!isMdScreen" medium>
                      {{ $t("🙌 You’ve reached the end! Validate your evaluation to complete this module.") }}
                    </svn-pro-title>

                    <svn-pro-title v-else-if="learnModule?.submission?.status === 'acquired'" :h5="isMdScreen" :h6="!isMdScreen" medium>
                      {{ $t('✅ Evaluation done, you have acquired this module!') }}
                    </svn-pro-title>

                    <svn-pro-title v-else h5 medium>
                      {{ $t("🙌 You’ve reached the end! Please notify your expert before starting the evaluation.") }}
                    </svn-pro-title>

                    <svn-pro-text v-if="learnModule?.submission?.status !== 'acquired'" subtile-large regular color="onSurfaceVariant">
                      {{ $t('To successfully complete this module, your answers must be reviewed in real time by your expert.') }}
                    </svn-pro-text>

                    <svn-pro-text v-else subtile-large regular color="onSurfaceVariant">
                      {{ $t('This module has been acquired, but you can still restart the evaluation.') }}
                    </svn-pro-text>
                  </div>

                  <!-- Start/Continue/restart evaluation button -->
                  <div class="flex justify-center md:!justify-end items-center w-full">
                    <div class="w-full flex sm:justify-end">
                      <svn-pro-button
                        variant="flat"
                        class="w-full md:!w-fit"
                        :text="$t('Start evaluation')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </bkt-pop-up-modal-skeleton>
</template>

<script setup>
import BktPopUpModalSkeleton from "@/components/BktPopUp/skeleton/BktPopUpModalSkeleton.vue";
import { Icon} from "@iconify/vue";
import { onMounted, onUnmounted, ref } from "vue";
import ReactionCard from "@/components/trainingApp/quiz/ReactionCard.vue";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import ModuleShowHeaderButtons from "@/components/trainingApp/ModuleShowHeaderButtons.vue";
import { storeToRefs } from "pinia";
import { useLearnModuleStore } from "@/store/learn-module";
import { AllTipTapPlugins } from 'svn-ui-library/extensions';
import BktImageCoverPosition from "@/components/image/bkt-image-cover-position.vue";

const props = defineProps({
  title: { type: String, required: true, default: '' },
  cover: { type: String, required: true, default: '' },
  themes: { type: Array, required: true, default: () => [] },
  inputs: { type: Array, required: true, default: () => [] },
  duration: { type: String, required: true, default: '' },
  editorData: { type: Object, required: true, default: () => {} },
  reactions: { type: Object, default: () => {} },
})

const breadcrumbsStore = useBreadcrumbsStore();

const { learnModule, learnApprovalInput } = storeToRefs(useLearnModuleStore());

const { reset, addNode, update } = breadcrumbsStore

const alignTop = ref(false)
const modalSkeleton = ref(null)

const updateBreadcrumbs = () => {
  addNode(props.title, 'learns/catalog')
  update()
}

const resetBreadcrumbs = () => {
  reset()
}

const handleClose = (close) => {
  modalSkeleton.value.dialog = false
}

const alignFunc = () => {
  alignTop.value = (document.body.scrollTop > window.innerHeight / 4 ||
    document.documentElement.scrollTop > window.innerHeight / 4)
}

onMounted(async () => {
  window.addEventListener("scroll", alignFunc);
})

onUnmounted(() => {
  window.removeEventListener("scroll", alignFunc)
})

const goToTop = () => {
  window.scrollTo({top: 0, left: 0, behavior: "smooth"})
}

defineExpose({
  modalSkeleton
})
</script>