<template>
  <svn-pro-card
    :link="false"
    class="p-4 "
    variant="flat"
  >
    <!-- Show employees suggestions if they are present and the interview is submitted -->
    <div
      v-if="(question?.type === InterviewQuestionType.UPDATE_ROADMAP &&
        locked && target?.suggestions && target?.suggestions.length > 0) &&
        interviewKind === 'employee'"
      class="flex flex-col gap-4"
    >
      <div class="flex items-center">
        <svn-pro-text
          medium
          body
        >
          {{ $t('Your suggested update') }}
        </svn-pro-text>

        <svn-pro-tooltip :text="$t(tooltipContent)">
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              class="pl-1 cursor-pointer"
              icon="custom:mingcute:information-line"
              size="24"
            />
          </template>
        </svn-pro-tooltip>
      </div>

      <div class="flex flex-col">

        <!-- Show employee's suggestions if they are present -->
        <div
          class="w-full flex flex-col gap-4"
        >
          <interview-answer
            :user="getSuggessedData('employee')?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :answer="getSuggessedData('employee')?.proposal"
            :comments="getSuggessedData('employee')?.comment"
          />
        </div>
      </div>
    </div>

    <div
      v-else-if="(question?.type === InterviewQuestionType.UPDATE_ROADMAP &&
        target?.suggestions && target?.suggestions.length > 0) &&
        (interviewKind === 'manager' || interviewKind === 'crossed')"
        class="flex flex-col gap-4"
      >
      <div class="flex items-center">
        <svn-pro-text
          medium
          body
        >
          {{ $t('Your suggested update') }}
        </svn-pro-text>

        <svn-pro-tooltip :text="$t(tooltipContent)">
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              class="pl-1 cursor-pointer"
              icon="custom:mingcute:information-line"
              size="24"
            />
          </template>
        </svn-pro-tooltip>
      </div>

      <div class="flex flex-col">
        <div
          class="w-full flex flex-col gap-4"
        >
          <!-- Show employee's suggestions if they are present -->
          <interview-answer
            v-if="getSuggessedData('employee')"
            :user="getSuggessedData('employee')?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :answer="getSuggessedData('employee')?.proposal"
            :comments="getSuggessedData('employee')?.comment"
          />

          <!-- Show manager's suggestions if they are present, manager view -->
          <interview-answer
            v-if="getSuggessedData('manager') && locked && !isLastInterviewCompletedAndLocked"
            :side="false"
            :user="getSuggessedData('manager')?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :answer="getSuggessedData('manager')?.proposal"
            :comments="getSuggessedData('manager')?.comment"
          />

          <!-- Show manager's suggestions if they are present, cross view -->
          <interview-answer
            v-if="getSuggessedData('manager') && interviewKind === 'crossed'"
            :side="false"
            :user="getSuggessedData('manager')?.user"
            :question-type="target?.objective_indicator?.indicator_type"
            :answer="getSuggessedData('manager')?.proposal"
            :comments="getSuggessedData('manager')?.comment"
          />
        </div>
      </div>
    </div>

    <!-- Show inputs if the interview is submitted and it is the last of the set -->
    <div
      v-if="!isDraft && isLastInterviewCompletedAndLocked &&
        (getSuggessedData('manager')?.proposal || getSuggessedData('crossed')?.proposal)"

      class="w-full flex flex-col self-start gap-4"
    >
      <div class="flex items-center">
        <svn-pro-text
          medium
          body
        >
        {{ $t('Final update') }}
      </svn-pro-text>

        <svn-pro-tooltip :text="$t(tooltipContent)">
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              class="pl-1 cursor-pointer"
              icon="custom:mingcute:information-line"
              size="24"
            />
          </template>
        </svn-pro-tooltip>
      </div>

      <interview-answer
        v-if="getSuggessedData('manager')?.proposal || getSuggessedData('crossed')?.proposal"
        :user="null"
        :crossed="isLastInterviewCompletedAndLocked"
        :is-last-interview-completed-and-locked="isLastInterviewCompletedAndLocked"
        :question-type="target?.objective_indicator?.indicator_type"
        :answer="interviewKind === 'manager' ?
          getSuggessedData('manager')?.proposal : interviewKind === 'crossed' ? getSuggessedData('crossed')?.proposal : null"
        :comments="interviewKind === 'manager' ? getSuggessedData('manager')?.comment :
          interviewKind === 'crossed' ?
            getSuggessedData('crossed')?.comment : $t('Comment')"
      />
    </div>
  </svn-pro-card>
</template>

<script setup>
import { computed } from 'vue';
import {storeToRefs} from "pinia";
import InterviewAnswer from '../InterviewAnswer.vue';
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';

const props = defineProps({
  target: { type: Object, required: true },
  question: { type: Object, required: false },
  isDraft: { type: Boolean, default: false },
  employee: { type: Object, required: false },
})

const { interviewKind, locked, status, id, isFinalUpdate, isLastInterviewCompletedAndLocked } = storeToRefs(useInterviewStore())

const tooltipContent = computed (() => {
  if (interviewKind.value === 'employee') {
    return 'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.'
  } else if (interviewKind.value === 'manager') {
    if (isFinalUpdate.value) {
      return 'This answer will be applied to the target when you submit this interview.'
    } else {
      return 'These are updates proposed by the Interviewee and Interviewer. Only the Value update below will be applied to the target after submitting all interviews of the set.'
    }
  } else {
    return 'This answer will be applied to the target when you submit this interview.'
  }
})

const getSuggessedData = (state) => {
  if (state === 'employee') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'employee')
  }
  else if (state === 'manager') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'manager')
  }
  else if (state === 'crossed') {
    return props?.target?.suggestions?.find((suggestion) => suggestion.state === 'crossed')
  }
}

</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px !important;
}
</style>
