<template>
  <div>
    <svn-pro-top-bar-learn-module
      :type="TopBarLearnModuleTypes.quiz"
      @close-and-go-back="router.back()"
    ></svn-pro-top-bar-learn-module>

    <div
      class="flex flex-col justify-center items-center xs:mt-6 xs:mx-5 xs:mb-24 sm:mx-12 md:mx-auto md:max-w-[560px]"
    >
      <div class="mb-2">
        <svn-pro-title h4 medium>
          {{ $t('Quiz') }}
        </svn-pro-title>
      </div>

      <div class="mb-6">
        <svn-pro-title h5 medium color="onSurfaceVariant">
          {{ 'quiz title' }}
        </svn-pro-title>
      </div>

      <div v-if="quiz != null" class="space-y-6 w-full">
        <svn-pro-section-quiz-learn-skt
          v-for="input in quiz.learnInputs.filter((item) => item.isCheckBoxQuestion())"
          :key="input.id"
          :correctionType="SvnProSectionQuizLearnCorrectionType.none"
          :list="input.proposals"
          :title="input.title"
        >
          <template #default="item">
            <svn-pro-quiz-answer-learn-item
              :leftLetter="'a'"
              :optionText="item.proposal"
              :state="
                isPrososalInToSend(input.id, item.proposal)
                  ? SvnProQuizAnswerLearnItemStates.selected
                  : SvnProQuizAnswerLearnItemStates.enable
              "
              @click="selectAnswer($event, input.id, item.proposal)"
            ></svn-pro-quiz-answer-learn-item>
          </template>
        </svn-pro-section-quiz-learn-skt>
      </div>
      <div v-else>loading...</div>

      <div class="w-full flex flex-row justify-center mt-6">
        <svn-pro-button
          variant="flat"
          :text="$t('Validate answers')"
          @click="console.log(toSend)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import i18n from '@/plugins/i18n.js';
import {
  TopBarLearnModuleTypes,
  SvnProQuizAnswerLearnItemStates,
  SvnProSectionQuizLearnCorrectionType,
} from 'svn-ui-library/components';
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import QuizApi from '../../../apis/quiz.api';

const route = useRoute();
const router = useRouter();

const quiz = ref(null);
const toSend = ref([]);

onMounted(async () => {
  if (!route.params.id) return router.back();

  try {
    quiz.value = await QuizApi.get(route.params.id);
    toSend.value = quiz.value.learnInputs.map((item) => ({ inputId: item.id, proposals: [] }));
  } catch (e) {
    console.log('error', e);
  }
});

const selectAnswer = (event, inputId, proposal) => {
  // don't know why event is fired twice
  if (!event) return;
  const idxInput = toSendIdxInput(inputId);
  if (idxInput === -1) return;

  if (isPrososalInToSend(inputId, proposal)) {
    toSend.value[idxInput].proposals = toSend.value[idxInput].proposals.filter(
      (item) => item !== proposal,
    );
  } else {
    toSend.value[idxInput].proposals.push(proposal);
  }
};

const isPrososalInToSend = (inputId, proposal) => {
  const idxInput = toSendIdxInput(inputId);
  if (idxInput === -1) return;

  const idxProposal = toSend.value[idxInput].proposals.findIndex((item) => item === proposal);

  if (idxProposal === -1) {
    return false;
  } else {
    return true;
  }
};

const toSendIdxInput = (inputId) => {
  return toSend.value.findIndex((item) => item.inputId === inputId);
};
</script>
