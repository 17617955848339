<template>
  <dialog-validation-pro
    ref="doNotParticipateRef"
    :width="312"
    :title="$t('Decline survey')"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Decline')"
    :content-text="$t('This survey will no longer appear in your My Interviews section.')"
    @click-primary-button="doNotParticipate"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import axios from "axios";
import { ref } from "vue";
import { storeToRefs } from 'pinia';
import { useSnackbar } from "@/store/snackbar.js";
import { useMyInterviewsStore } from "@/store/my-interviews.js";
import DialogValidationPro from "../BktPopUp/Dialogs/DialogValidationPro.vue";

const props = defineProps({
  id: { type: Number, required: true, default: null },
});

const doNotParticipateRef = ref(null)

const snackbar = useSnackbar();

const { interviews, interviewsToDo, interviewsDone, interviewsMeta, interviewsToDoMeta, interviewsDoneMeta } = storeToRefs(useMyInterviewsStore())

async function doNotParticipate() {
  try {
    await axios.delete(`/api/v2/interview_app/interviews/${props.id}.json`);

    const idx = interviews.value.findIndex((inter) => (inter.interview_id == props.id))
    const idxToDo = interviewsToDo.value.findIndex((inter) => (inter.interview_id == props.id))
    const idxDone = interviewsDone.value.findIndex((inter) => (inter.interview_id == props.id))

    if (idx !== -1) {
      interviews.value.splice(idx, 1)
      if (interviewsMeta?.value?.total_count) {
        interviewsMeta.value.total_count -= 1
      }
    }
    if (idxToDo !== -1) {
      interviewsToDo.value.splice(idxToDo, 1)
      if (interviewsToDoMeta?.value?.total_count) {
        interviewsToDoMeta.value.total_count -= 1
      }
    }
    if (idxDone !== -1) {
      interviewsDone.value.splice(idxDone, 1)
      if (interviewsDoneMeta?.value?.total_count) {
        interviewsDoneMeta.value.total_count -= 1
      }
    }
    snackbar.setMsg("Survey declined successfully.").setBgColor("onSurface").displaySnackBar();
  } catch (e) {
    snackbar.setMsg("Error deleting interview !").setBgColor("onSurface").displaySnackBar();
  }
}

defineExpose({
  doNotParticipateRef
})
</script>