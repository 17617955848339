
<template>
  <svn-pro-modal
    ref="deletedItems"
    :title="contentType === 'user' ? `Deactivated users (${pagination?.total_count || 0})` : `${$t(`Deleted ${contentType}s`)}`"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-4">
        <svn-pro-text v-if="contentType !== 'user'" body-medium regular color="onSurfaceVariant">
          {{ $t(`Deleted ${contentType}s are stored here for 30 days. After this period, they will be permanently deleted.`) }}
        </svn-pro-text>

        <svn-pro-text-field
          v-model="searchText"
          variant="outlined"
          :label="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line"
          @update:model-value="emit('toggle-search', searchText)"
        />

        <svn-pro-data-table
          :headers="items?.length ? headers : []"
          :items="items ?? []"
          class="md:h-[344px]"
          :items-per-page="-1"
          item-value="id"
          :pagination-total-pages="pagination?.total_pages"
          @update-pagination="emit('fetch-new-page', $event)"
        >
          <template #item="{ item }">
            <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="emit('view-item', item?.id)">
              <td v-if="item?.title">
                <svn-pro-data-table-cell
                  medium
                  body-large
                  cell-type="text"
                  :text="item?.title"
                />
              </td>

              <td v-if="headers?.find(col => col?.key === 'fullname')">
                <svn-pro-data-table-cell
                  medium
                  body-large
                  cell-type="avatar_text_supporting_text"
                  :text="item?.employee?.fullname || item?.fullname || item?.creator?.fullname"
                  :avatar="item?.employee?.avatar || item?.avatar || item?.creator?.avatar"
                  :supporting-text="item?.email || null"
                />
              </td>

              <td>
                {{ contentType === 'user' ? $filters.formatSpecificDate(item?.disabled_at, "YYYY-MM-DD hh:mm:ss ZZ", "DD MMM, YYYY") : $filters.formatSpecificDate(item?.deleted_at, "YYYY-MM-DD hh:mm:ss ZZ", "DD MMM, YYYY") }}
              </td>

              <td>
                <svn-pro-tooltip :text="$t(`Restore ${contentType}`)">
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="props"
                      icon="custom:mingcute:back-2-line"
                      color="onSurfaceVariant"
                      variant="text"
                      @click.stop="emit('restore', item?.id)"
                    />
                  </template>
                </svn-pro-tooltip>
              </td>

              <td v-if="withDeleteColumn">
                <svn-pro-tooltip :text="$t(`Delete permanently`)">
                  <template #activator="{ props }">
                    <svn-pro-icon-button
                      v-bind="props"
                      icon="custom:mingcute:delete-2-line"
                      color="onSurfaceVariant"
                      variant="text"
                      @click.stop="closeModal(item?.id)"
                    />
                  </template>
                </svn-pro-tooltip>
              </td>
            </tr>
          </template>

          <template #no-data>
            <svn-pro-empty-states
              :variant="searchText ? 'results' : 'index'"
              :size="isMobile ? 'compact' : 'default'"
              :supporting-text="searchText ? $t('Oops, we didn’t find any results matching your search.') :
                $t(`Hmm, it seems you have no deleted ${contentType}s.`)"
            />
          </template>

          <template #loading>
          </template>
        </svn-pro-data-table>
      </div>
    </template>
  </svn-pro-modal>

  <dialog-validation-pro
    ref="deleteTargetRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`${contentType?.charAt(0)?.toUpperCase()}${contentType?.slice(1)} will be deleted`)"
    :content-text="$t('This is a permanent action.')"
    @click-primary-button="emit('delete-permanently')"
    @click-secondary-button="emit('reopen-modal')"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useMobileStore } from '@/store/mobile';
import DialogValidationPro from '../DialogValidationPro.vue';

const props = defineProps({
  contentType: { type: String, default: null },
  headers: { type: Array, default: () => [] },
  pagination: { type: Object, default: () => {} },
  items: { type: Object, default: () => {} },
  withDeleteColumn: { type: Boolean, default: true },
})

const mobileStore = useMobileStore()

const { isMobile } = storeToRefs(mobileStore);

const searchText = ref('');
const deletedItems = ref(null);
const deleteTargetRef = ref(null);

const emit = defineEmits(['toggle-search', 'delete-permanently', 'restore', 'view-item', 'close-modal', 'fetch-new-page', 'reopen-modal'])

const closeModal = (id) => {
  emit('close-modal', id)
}

defineExpose({
  deletedItems,
  deleteTargetRef,
})
</script>

<style>
</style>
