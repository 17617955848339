<template>
  <v-window-item
    value="draft"
  >
    <v-fade-transition>
      <!-- Skeleton loaders -->
      <div
        v-if="loading"
        class="grid grid-cols-1 mx-4 my-4 xl:!mx-0 gap-4 justify-center items-start self-stretch
          sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        <skeleton-loader-module-card
          v-for="item in 8"
          :key="item"
        />
      </div>

      <!-- Finished loading -->
      <div
        v-else
        class="mx-6 md:!mx-0"
      >
        <!-- Search & Select -->
        <div class="w-full flex gap-4 my-4 flex-wrap justify-center items-center">

          <!-- Search -->
          <svn-pro-text-field
            v-model="searchTermModules"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @input="search"
            class="w[320px]"
            clearable
            clear-icon="custom:mingcute:close-line"
          />

          <!-- Sort & Filter -->
          <div class="flex  jsutify-center items-center">
            <!-- <svn-pro-select
              v-model="selectFilterModules"
              :label="$t('Sort by')"
              class="max-w[320px] min-w[112px]"
              :items="selectFilterList"
              item-title="display"
              item-value="value"
              @update:model-value="search"
            /> -->

            <!-- Filter -->
            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-button
                  v-bind="props"
                  :active="selectedThemesListModules?.length"
                  :text="`${$t('Filter')} ${selectedThemesListModules?.length ? `(${selectedThemesListModules?.length})` : ''}`"
                  prepend-icon="custom:mingcute:filter-2-line"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <v-list-item
                  v-if="themes?.length"
                  v-for="(item, index) in themes"
                  :key="index"
                  :value="item"
                  :title=item?.name
                  :active="selectedThemesListModules?.find(theme => theme === item) !== undefined ? true : false"
                  @click.stop="toggleThemeSelection(item)"
                >
                  <template #prepend="{ isActive }">
                    <v-list-item-action start>
                      <v-checkbox-btn :model-value="isActive" />
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Filter results -->
        <div
          v-if="selectedThemesListModules?.length > 0"
          class="flex justify-between items-start self-stretch pt-4 xl:!px-0 xl:flex-wrap"
        >
          <!-- Themes list -->
          <div
            v-if="selectedThemesListModules?.length"
            class="flex flex-wrap gap-2"
          >
            <svn-pro-chip
              v-for="theme in selectedThemesListModules"
              key="entityTag.id"
              class=""
              :text="theme.name"
              is-slot-append="true"
            >
              <template #append>
                <Icon
                  icon="mingcute:close-line"
                  width="18"
                  height="18"
                  class="ml-2 cursor-pointer"
                  @click="toggleThemeSelection(theme)"
                />
              </template>
            </svn-pro-chip>
          </div>

          <!-- Clear all themes selected -->
          <svn-pro-button
            v-if="selectedThemesListModules?.length > 1"
            @click="clearAllSelectedThemes"
            variant="text"
            :text="$t('Clear all')"
          />
        </div>

        <!-- Modules card list -->
        <v-infinite-scroll
          :items="learnModulesDraft"
          class="overflow-hidden"
          color="primary"
          @load="loadMoreData"
        >
          <div
            class="flex flex-wrap w-full gap-4 justify-center item-center"
          >
            <module-card
              v-for="(module) in learnModulesDraft"
              :learnModule="module"
              :id="module?.id"
              :key="module?.id"
              :module-creator-id="module?.creator_id"
              :background-image="module?.cover_url?.['500']"
              :title="module?.title"
              :reactions="module?.user_reactions"
              :is-favorite="module?.connected_user_favorited"
              :theme-list="module?.themes"
              editable
              :duration="module?.duration"
              class="!w-[172px]"
              :updated-at="filters.formatDate(module?.updated_at, 'DD/MM/YYYY')"
              :submission-status="module?.submission_status"
              :submission-updated-at="module?.submission_updated_at"
              @toggle-favorite="toggleModuleFavorite(module)"
              @go-to-item-show="goToModuleShowEdit(module.id)"
              @duplicate-module="toggleDuplicateModule(module?.id)"
              @delete-module="deleteLearnModule(module?.id)"
            />
          </div

          <template #empty />
        </v-infinite-scroll>

        <!-- No module found after search -->
        <div
          v-if="!learnModulesDraft?.length && searchTermModules.length > 0"
          class="flex flex-col text-center justify-center items-center gap-4 px-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:face-with-monocle"
            height="32"
            width="32"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{ $t("Hmm, it seems we can’t find any results based on your search.") }}
          </p>
        </div>

        <!-- No module in index -->
        <div
          v-if="learnModulesDraft?.length === 0 && searchTermModules.length === 0"
          class="flex flex-col text-center justify-center items-center gap-4 pt-[96px] lg:pt-[64px]"
        >
          <Icon
            icon="noto:eyes"
            height="24"
            width="24"
          />

          <p class="text-fakeBlack text-sm font-medium">
            {{ $t("There are no modules. Create your first module !") }}
          </p>
        </div>
      </div>
    </v-fade-transition>

    <!-- Dialog duplicate module -->
    <dialog-duplicate-module
      ref="duplicateModuleDialog"
      @duplicate-module="duplicateLearnModule"
    />

    <!-- Dialog delete module -->
    <dialog-delete-module
      ref="dialogDeleteModule"
      :trainings="moduleToDeleteTraining"
      @delete-module="deleteLearnModule"
    />
  </v-window-item>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { storeToRefs } from "pinia";
import { ref, onMounted } from 'vue';
import { useSnackbar } from '@/store/snackbar';
import { useMobileStore } from '@/store/mobile';
import { useLearnThemesStore } from '@/store/learn-themes';
import { useLearnModuleStore } from "@/store/learn-module.js";
import DialogDuplicateModule from '@/components/BktPopUp/Dialogs/learn/DialogDuplicateModule.vue';
import DialogDeleteModule from "@/components/BktPopUp/Dialogs/learn/DialogDeleteModule.vue";
import filters from '@/tools/filters';
import ModuleCard from "@/components/trainingApp/ModuleCard.vue";
import { useBreadcrumbsStore } from '@/store/breadcrumbs';
import SkeletonLoaderModuleCard from '@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue';
import { debounce } from 'lodash';
import router from '@/router';
import i18n from '@/plugins/i18n';

const props = defineProps({
  themes: { type: Array, default: null },
})

onMounted(async() => {
  try {
    await fetchModulesDraft().then((res) => {
      totalPages.value = res.data.meta.pagination.total_pages
      loading.value = false
      learnModulesDraft.value = res.data.learn_modules
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
})

const searchTermModules = ref('')

const page = ref(1)

const totalPages = ref(null)

const learnModulesDraft = ref([])

const loading = ref(true)

const filteredThemeSearchModules = ref('')

const selectedThemesListModules = ref([])

const snackbar = useSnackbar()

const { fetchThemes } = useLearnThemesStore()

const breadcrumbsStore = useBreadcrumbsStore();

const { addNode, update } = breadcrumbsStore

const { fetchModulesDraft, toggleFavorite, duplicateModule, deleteModulePermanently } = useLearnModuleStore();

const { isMobile } = storeToRefs(useMobileStore())


const idModuleToDuplicate = ref(null)

const duplicateModuleDialog = ref(false)

const dialogDeleteModule = ref(false)

const moduleToDeleteTraining = ref(null)

const selectFilterList = ref([
  {
    display: i18n.global.t("Decreasing publication date"),
    value: "desc-publication",
  },
  {
    display: i18n.global.t("Increasing publication date"),
    value: "asc-publication",
  },
  {
    display: i18n.global.t("Increasing acquisition date"),
    value: "asc-acquisition",
  },
  {
    display: i18n.global.t("Decreasing acquisition date"),
    value: "desc-acquisition",
  },
])

const selectFilterModules = ref(selectFilterList?.value[0].value)

const toggleDuplicateModule = (id) => {
  idModuleToDuplicate.value = id
  duplicateModuleDialog.value.dialogDuplicateModule = true
}

const toggleThemeSelection = debounce(async(item) => {
  const found = selectedThemesListModules?.value?.find(theme => theme?.id === item?.id);

  if (found) {
    selectedThemesListModules?.value?.splice(selectedThemesListModules?.value?.findIndex((theme) =>
      theme?.id === item?.id), 1);
  }
  else {
    selectedThemesListModules?.value?.push(item);
  }
  search()
}, 200)

const toggleModuleFavorite = debounce(async(module) => {
  try {
    await toggleFavorite(module.id, "draft")

    snackbar.setBgColor('onSurface')
    if (module.connected_user_favorited) {
      snackbar.setMsg('Module added to favorites.')
    } else {
      snackbar.setMsg('Module removed to favorites.')
    }
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('error')
    snackbar.setMsg('Error toggling favorite')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  }
}, 200)

const duplicateLearnModule = async(title) => {
  try {
    const duplicated = await duplicateModule(idModuleToDuplicate.value, title)

    snackbar.setBgColor('positiveGreen')
    snackbar.setMsg('Module duplicated')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()

    idModuleToDuplicate.value = null
    duplicateModuleDialog.value.dialogDuplicateModule = false

    setTimeout(() => {
      router.push({ name: 'module_edit', params: { id: duplicated.id } })
    }, 200);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error duplicating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[80px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const deleteLearnModule = async(id) => {
  try {
    await deleteModulePermanently(id)

    snackbar.setBgColor('onSurface')
    snackbar.setMsg('Module has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()

    router.push({ name: "catalog" })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error deleting module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.displaySnackBar()
  }
}

const goToModuleShowEdit = (id, title) => {
  updateBreadcrumbs(title)
  router.push({ name: 'module_edit', params: { id: id } })
}

const clearAllSelectedThemes = () => {
  selectedThemesListModules.value = [];
}

const searchThemes = async() => {
  try {
    await fetchThemes(filteredThemeSearchModules.value)
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching themes')
    snackbar.displaySnackBar()
  }
}

const search = async() => {
  try {
    const res = await fetchModulesDraft(searchTermModules.value,
      selectedThemesListModules?.value?.map(theme => theme?.id), 1, selectFilterModules.value)
      learnModulesDraft.value = res.data.learn_modules
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules')
    snackbar.displaySnackBar()
  }
}

const updateBreadcrumbs = (title) => {
  addNode(title, 'learns/catalog')
  update()
}

const loadMoreData = async({ done }) => {
  if (page.value < totalPages.value) {
    try {
      const res = await fetchModulesDraft(searchTermModules.value,
        selectedThemesListModules?.value?.map(theme => theme?.id), page.value + 1)

        if (res.data.learn_modules.length) {
          learnModulesDraft.value = [...learnModulesDraft.value, ...res.data.learn_modules]
        }
        done('ok')
        page.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}
</script>