<template>
  <div
    :id="question?.id"
    class="flex flex-col justify-center items-start gap-12"
  >
    <div class="flex flex-col justify-center items-start gap-6 self-stretch">
      <p
        v-if="question?.type !== InterviewQuestionType.PARAGRAPH"
        class="text-[48px] font-medium leading-normal text-fake-black"
      >
        {{ question?.text }}
      </p>

      <quill-editor
        v-if="question?.type === InterviewQuestionType.PARAGRAPH"
        v-model:content="question.text"
        content-type="html"
        :placeholder="' '"
        :read-only="true"
        class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description"
      />

      <quill-editor
        v-else-if="question?.type !== InterviewQuestionType.PARAGRAPH && question?.description"
        v-model:content="question.description"
        content-type="html"
        :placeholder="' '"
        :read-only="true"
        class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description"
      />
    </div>

    <div
      v-if="question?.type !== InterviewQuestionType.PARAGRAPH && question?.type !== InterviewQuestionType.CHAPTER"
      class="flex flex-col justify-center items-start gap-8"
    >
      <div class="flex flex-col justify-center items-start gap-6 pl-6">
        <div class="flex flex-col justify-center items-start gap-6">
          <!-- Interviewee -->
          <div
            v-if="findEmployeeQuestion(question?.id)"
            class="flex flex-col items-start gap-4"
          >
            <div class="flex items-center gap-4">
              <p class="text-[28px] text-fake-black font-semibold leading-normal pl-4">
                •{{ `  ${employee?.lastname} ${employee?.firstname}` }} ({{ $t('participant') }}) {{ $t("answer") }}:
              </p>

              <div
                v-if="question?.type === InterviewQuestionType.RATING || question?.type === InterviewQuestionType.MCQ"
                class="px-3 py-2 bg-very-light-grey rounded"
              >
                <v-rating
                  v-if="question?.type === InterviewQuestionType.RATING"
                  :length="objectFlip(question.options)[1]"
                  :model-value="Number(findEmployeeQuestion(question.id)?.answer)"
                  color="darkGrey"
                  readonly
                  class="my-2"
                >
                  <template #item="iconProps">
                    <v-icon
                      :color="'darkGrey'"
                      class="mr-4"
                      @click="iconProps.onClick"
                    >
                      {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </template>
                </v-rating>

                <p v-else-if="question?.type === InterviewQuestionType.MCQ" class="text-[32px] text-dark-grey font-semibold">
                  {{ findEmployeeQuestion(question.id)?.answer }}
                </p>
              </div>
            </div>

            <div
              v-if="findEmployeeQuestion(question.id)?.answer || findEmployeeQuestion(question.id)?.comments"
              class="flex items-center self-stretch"
            >
              <v-divider class="border-opacity-100 text-dark-grey py-3 pl-5" vertical :thickness="1"></v-divider>

              <quill-editor
                v-if="question?.type === InterviewQuestionType.OPEN"
                v-model:content="findEmployeeQuestion(question.id).answer"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />

              <quill-editor
                v-else-if="question?.type !== InterviewQuestionType.OPEN && findEmployeeQuestion(question?.id)?.comments"
                v-model:content="findEmployeeQuestion(question.id).comments"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />
            </div>
          </div>

          <!-- Interviewer -->
          <div
            v-if="findManagerQuestion(question?.id)"
            class="flex flex-col items-start gap-4"
          >
            <div class="flex items-center gap-4">
              <p class="text-[28px] text-fake-black font-semibold leading-normal pl-4">
                •{{ `  ${interviewer?.lastname} ${interviewer?.firstname}` }} ({{ $t('person in charge') }}) {{ $t("answer") }}:
              </p>

              <div
                v-if="question?.type === InterviewQuestionType.RATING || question?.type === InterviewQuestionType.MCQ"
                class="px-3 py-2 bg-very-light-grey rounded"
              >
                <v-rating
                  v-if="question?.type === InterviewQuestionType.RATING"
                  :length="objectFlip(question.options)[1]"
                  :model-value="Number(findManagerQuestion(question.id)?.answer)"
                  color="darkGrey"
                  readonly
                  class="my-2"
                >
                  <template #item="iconProps">
                    <v-icon
                      :color="'darkGrey'"
                      class="mr-4"
                      @click="iconProps.onClick"
                    >
                      {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </template>
                </v-rating>

                <p v-else-if="question?.type === InterviewQuestionType.MCQ" class="text-[32px] text-dark-grey font-semibold">
                  {{ findManagerQuestion(question.id)?.answer }}
                </p>
              </div>
            </div>

            <div
              v-if="findManagerQuestion(question.id)?.answer || findManagerQuestion(question.id)?.comments"
              class="flex items-center self-stretch"
            >
              <v-divider class="border-opacity-100 text-dark-grey py-3 pl-5" vertical :thickness="1"></v-divider>

              <quill-editor
                v-if="question?.type === InterviewQuestionType.OPEN"
                v-model:content="findManagerQuestion(question.id).answer"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />

              <quill-editor
                v-else-if="question?.type !== InterviewQuestionType.OPEN && findManagerQuestion(question?.id)?.comments"
                v-model:content="findManagerQuestion(question.id).comments"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />
            </div>
          </div>

          <!-- Pairs Or Survey -->
          <div
            v-if="interviewKind === 'pairs' || interviewKind === 'survey' || interviewKind === 'employee'"
            class="flex flex-col items-start gap-4"
          >
            <div class="flex items-center gap-4">
              <p class="text-[28px] text-fake-black font-semibold leading-normal pl-4">
                •{{ `  ${findInterviewAnswer?.user?.fullname}` }} ({{ $t('participant') }}) {{ $t("answer") }}:
              </p>

              <div
                v-if="question?.type === InterviewQuestionType.RATING || question?.type === InterviewQuestionType.MCQ"
                class="px-3 py-2 bg-very-light-grey rounded"
              >
                <v-rating
                  v-if="question?.type === InterviewQuestionType.RATING"
                  :length="objectFlip(question.options)[1]"
                  :model-value="Number(findInterviewAnswer.answer)"
                  color="fakeBlack"
                  readonly
                  class="my-2"
                >
                  <template #item="iconProps">
                    <v-icon
                      :color="'fakeBlack'"
                      class="mr-4"
                      @click="iconProps.onClick"
                    >
                      {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                    </v-icon>
                  </template>
                </v-rating>

                <p v-else-if="question?.type === InterviewQuestionType.MCQ" class="text-[32px] text-fake-black font-semibold">
                  {{ findInterviewAnswer.answer }}
                </p>
              </div>
            </div>

            <div
              v-if="findInterviewAnswer?.answer || findInterviewAnswer?.comments"
              class="flex items-center self-stretch"
            >
              <v-divider class="border-opacity-100 text-dark-grey py-3 pl-5" vertical :thickness="1"></v-divider>

              <quill-editor
                v-if="question?.type === InterviewQuestionType.OPEN && findInterviewAnswer?.answer"
                v-model:content="findInterviewAnswer.answer"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />

              <quill-editor
                v-else-if="question?.type !== InterviewQuestionType.OPEN && findInterviewAnswer?.comments"
                v-model:content="findInterviewAnswer.comments"
                content-type="html"
                :placeholder="' '"
                :read-only="true"
                class="!cursor-default break-words w-full font-normal !text-dark-grey !text-[28px] leading-[48px] description pl-6"
              />
              <!-- {{ findInterviewAnswer }} -->
            </div>
          </div>
        </div>

        <!-- Cross Review Answers -->
        <div
          v-if="interviewKind === 'crossed'"
          class="flex flex-col items-start gap-4"
        >
          <div class="flex items-center gap-4">
            <p class="text-[28px] text-fake-black font-semibold leading-normal">
              {{ $t("Cross review") }} {{ $t("answer") }}:
            </p>

            <div
              v-if="question?.type === InterviewQuestionType.RATING || question?.type === InterviewQuestionType.MCQ"
              class="px-3 py-2 bg-very-light-grey rounded"
            >
              <v-rating
                v-if="question?.type === InterviewQuestionType.RATING"
                :length="objectFlip(question.options)[1]"
                :model-value="Number(findInterviewAnswer.answer)"
                color="fakeBlack"
                readonly
                class="my-2"
              >
                <template #item="iconProps">
                  <v-icon
                    :color="'fakeBlack'"
                    class="mr-4"
                    @click="iconProps.onClick"
                  >
                    {{ iconProps.isFilled ? 'mdi-star' : 'mdi-star-outline' }}
                  </v-icon>
                </template>
              </v-rating>

              <p v-else-if="question?.type === InterviewQuestionType.MCQ" class="text-[32px] text-fake-black font-semibold">
                {{ findInterviewAnswer.answer }}
              </p>
            </div>
          </div>

          <quill-editor
            v-if="question?.type === InterviewQuestionType.OPEN"
            v-model:content="findInterviewAnswer.answer"
            content-type="html"
            :placeholder="' '"
            :read-only="true"
            class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description"
          />

          <!-- Comments -->
          <div v-if="question.allow_comments">
            <quill-editor
              v-model:content="findInterviewAnswer.comments"
              content-type="html"
              :placeholder="' '"
              :read-only="true"
              class="!cursor-default break-words w-full font-normal !text-fake-black !text-[28px] leading-[48px] description"
            />
          </div>
        </div>
      </div>

      <!-- Comments/Messages -->
      <div v-if="findInterviewAnswer && findInterviewAnswer?.messages?.length && campaign?.campaign_type === 'Survey'" class="flex flex-col gap-6">
        <svn-pro-text body-medium medium class="underline">
          {{ $t("comments") }} :
        </svn-pro-text>

        <div class="flex flex-col gap-6">          
          <div
            v-for="(message, index) in findInterviewAnswer?.messages"
            :key="message.id"
          >
            <svn-pro-text body-medium bold>
              {{ message?.user?.fullname || `Participant ${index}` }} <span class="!font-medium">{{ $filters.formatDateHoursWithoutSeconds(new Date(message?.created_at.split(" UTC")[0])) }}</span>
            </svn-pro-text>
  
            <div class="break-words w-full border-l border-opacity-100 border-[#767680] pl-[25.76px]">
              <quill-editor
                v-model:content="message.text"
                content-type="html"
                :placeholder="' '"
                :enable="true"
                class="!cursor-default break-words w-full !text-onSurfaceVariant !text-body-2 font-medium leading-8 border-b-0 description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useInterviewStore } from "@/store/interview.js";
import { computed } from "vue";
import useTools from "@/tools/useTools.js";
import { InterviewQuestionType } from '@/constants/types';

const props = defineProps(["question", "loopIdx"]);

const { objectFlip } = useTools();
const { updateInterviewAnswer } = useInterviewStore();

const {
  interviewKind,
  campaign,
  interviewAnswers,
  locked,
  employee,
  interviewer,
  employeeAnswers,
  managerAnswers,
} = storeToRefs(useInterviewStore());

const findInterviewAnswer = computed(() => {
  return (
    interviewAnswers.value?.find(
      (answer) => answer.question_id === props.question.id
    ) || {}
  );
});

const updateRemote = () => {
  return updateInterviewAnswer(props.loopIdx, props.question.id, {
    answer: findInterviewAnswer.value.answer,
  });
}

const findEmployeeQuestion = (questionId) => {
  return employeeAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};
const findManagerQuestion = (questionId) => {
  return managerAnswers.value?.find(
    (answer) => answer.question_id === questionId
  );
};

const setCrossDefaultAnswer = () => {
  let managerAnswers = findManagerQuestion(props.question.id);
  let employeeAnswers = findEmployeeQuestion(props.question.id);

  if (!findInterviewAnswer.value.answer) {
    findInterviewAnswer.value.answer = managerAnswers
      ? managerAnswers.answer
      : employeeAnswers
        ? employeeAnswers.answer
        : null;
        if (props?.question?.type !== InterviewQuestionType.PARAGRAPH &&
          props?.question?.type !== InterviewQuestionType.UPDATE_ROADMAP &&
            props?.question?.type !== InterviewQuestionType.CREATE_ROADMAP &&
              props?.question?.type !== InterviewQuestionType.CHAPTER) {
          updateRemote();
        }
  }
};

if (interviewKind.value === "crossed" && !locked.value) {
  setCrossDefaultAnswer();
}
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}
</style>