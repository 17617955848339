<template>
  <div class="w-full flex flex-col gap-6">
    <div class="flex flex-col gap-6">
      <!-- Choose your settings -->
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4">
          <svn-pro-text subtitle-large medium>
            {{ $t('Choose your settings') }}
          </svn-pro-text>

          <div class="flex flex-col gap-2">
            <div class="flex gap-2 items-center">
              <svn-pro-switch
                :model-value="isCampaignRecurrent"
                @update:model-value="updateIsCampaignRecurrent"
              />

              <svn-pro-text subtitle-medium medium>
                {{ $t('Recurrent campaign') }}
              </svn-pro-text>
            </div>

            <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
              {{ $t('A recurrent campaign is a campaign which is repeated several times.') }}
            </svn-pro-text>
          </div>
        </div>

        <!-- Period -->
        <div class="flex flex-col gap-4">
          <svn-pro-text subtitle-large medium>
            {{ $t("Define your campaign period") }}
          </svn-pro-text>

          <!-- Start date -->
          <div class="flex flex-col gap-4">
            <div class="flex items-center gap-2">
              <svn-pro-tooltip :text="$t('The campaign will begin at this date')">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <Icon
                      icon="mingcute:information-line"
                      height="16"
                      width="16"
                      class="text-borderColor"
                    />
                  </div>
                </template>
              </svn-pro-tooltip>

              <svn-pro-text body-medium medium>
                {{ $t("Campaign will start on :") }}
              </svn-pro-text>
            </div>

            <div class="flex flex-col gap-4 md:!flex-row">
              <!-- input -->
              <div :class="isCampaignRecurrent ? 'md:!w-1/2' : 'w-full md:!w-1/2'">
                <pop-up-edit-date min @save="updateStartDate">
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-bind="props"
                      variant="outlined"
                      clearable
                      clear-icon="mdi-close"
                      :error="startDateError"
                      v-model="campaignDraft.startline"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      :label="$t('Start date*')"
                      @click:clear="campaignDraft.startline = null"
                    />
                  </template>
                </pop-up-edit-date>
              </div>

              <div
                v-if="isCampaignRecurrent"
                class="md:!w-1/2 flex flex-col md:!flex-row gap-4"
              >
                <!-- Start Time -->
                <pop-up-edit-time @save="updateStartTime">
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-model="campaignDraft.will_launch_at"
                      v-bind="props"
                      clearable
                      :error="startTimeError"
                      class="md:!w-1/2"
                      clear-icon="mdi-close"
                      :label="$t('Time*')"
                      @click:clear="campaignDraft.will_launch_at = null"
                    />
                  </template>
                </pop-up-edit-time>

                <!-- Time zone -->
                <svn-pro-select
                  v-model="campaignDraft.recurrent_time_zone"
                  :label="$t('Zone*')"
                  :error="startTimeZoneError"
                  class="md:!w-1/2"
                  :items="citiesList"
                />
              </div>

              <div class="w-1/2"v-else />
            </div>
          </div>

          <!-- End date -->
          <div class="flex flex-col gap-4">
            <div class="flex items-center gap-2">
              <svn-pro-tooltip :text="$t('The campaign will end at this date')">
                <template #activator="{ props }">
                  <div v-bind="props">
                    <Icon
                      icon="mingcute:information-line"
                      height="16"
                      width="16"
                      class="text-borderColor"
                    />
                  </div>
                </template>
              </svn-pro-tooltip>

              <svn-pro-text v-if="isCampaignRecurrent" body-medium medium>
                {{ $t("Recurrence will stop on :") }}
              </svn-pro-text>

              <svn-pro-text v-else body-medium medium>
                {{ $t("Campaign will end on :") }}
              </svn-pro-text>
            </div>

            <!-- input -->
            <div class="w-full flex gap-4">
              <div class="w-full md:!w-1/2">
                <pop-up-edit-date min @save="updateEndDate">
                  <template #activator="{ props }">
                    <svn-pro-text-field
                      v-if="isCampaignRecurrent"
                      v-bind="props"
                      variant="outlined"
                      clearable
                      :error="recurrenceStopError"
                      clear-icon="mdi-close"
                      v-model="campaignDraft.recurrent_stop_at"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      :label="$t('End date*')"
                      @click:clear="campaignDraft.recurrent_stop_at = null"
                    />

                    <svn-pro-text-field
                      v-else
                      v-bind="props"
                      variant="outlined"
                      clearable
                      :error="endDateError"
                      clear-icon="mdi-close"
                      v-model="campaignDraft.deadline"
                      prepend-inner-icon="custom:mingcute:calendar-2-line"
                      :label="$t('End date*')"
                      @click:clear="campaignDraft.deadline = null"
                    />
                  </template>
                </pop-up-edit-date>
              </div>

              <div class="hidden md:!flex md:!w-1/2" />
            </div>
          </div>

          <div
            v-if="isCampaignRecurrent"
            class="w-full flex flex-col gap-4"
          >
            <!-- Campaign will last -->
            <div class="flex flex-col gap-4">
              <div class="w-full flex items-center gap-2">
                <svn-pro-tooltip :text="$t('The duration of the campaign.')">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <Icon
                        icon="mingcute:information-line"
                        height="16"
                        width="16"
                        class="text-borderColor"
                      />
                    </div>
                  </template>
                </svn-pro-tooltip>

                <svn-pro-text body-medium medium>
                  {{ $t("Campaign will last :") }}
                </svn-pro-text>
              </div>

              <!-- Inputs -->
              <div class="w-full flex flex-col gap-4 md:!flex-row">
                <!-- Duration -->
                <svn-pro-text-field
                  v-model="campaignDraft.deadline_number"
                  :error="durationError"
                  :label="$t('Enter a duration*')"
                  type="number"
                  min="1"
                  class="md:!w-1/2"
                />

                <!-- Duration unit -->
                <div class="md:!w-1/2 flex gap-4">
                  <svn-pro-select
                    v-model="campaignDraft.deadline_duration_unit"
                    :label="$t('Duration unit')"
                    item-title="text"
                    item-value="value"
                    :items="durationUnits"
                    class="md:!w-1/2"
                  />

                  <div class="hidden md:!flex md:!w-1/2" />
                </div>
              </div>
            </div>

            <!-- Repeat -->
            <div class="w-full flex flex-col gap-4">
              <div class="w-full flex items-center gap-2">
                <svn-pro-tooltip :text="$t('The periodicity of the campaign.')">
                  <template #activator="{ props }">
                    <div v-bind="props">
                      <Icon
                        icon="mingcute:information-line"
                        height="16"
                        width="16"
                        class="text-borderColor"
                      />
                    </div>
                  </template>
                </svn-pro-tooltip>

                <svn-pro-text body-medium medium>
                  {{ $t("Repeat :") }}
                </svn-pro-text>
              </div>

              <!-- Inputs -->
              <div class="w-full flex flex-col md:!flex-row gap-4">
                <svn-pro-select
                  v-model="campaignDraft.recreate_repetition"
                  :placeholder="repetition[1].text"
                  :label="$t('Duration unit')"
                  item-title="text"
                  item-value="value"
                  class="md:!w-1/2"
                  :items="repetition"
                />

                <svn-pro-select
                  v-if="campaignDraft.recreate_repetition === 'every_month'"
                  :model-value="campaignDraft.recreate_repetition_option || 'same_day'"
                  :disabled="isCampaignStartlineOnLastDays"
                  :label="$t('Select period')"
                  item-title="text"
                  item-value="value"
                  class="md:!w-1/2"
                  :items="periodicityMonth"
                  @update:model-value="campaignDraft.recreate_repetition_option = $event"
                />

                <div v-else class=" hidden md:!flex md:!w-1/2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Choose parameters -->
      <template v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360 || campaignDraft?.campaign_type === CampaignTypeEnum.SURVEY">
        <div class="flex flex-col gap-4">
          <svn-pro-title subtitle-large medium>
            {{ $t('Choose parameters') }}
          </svn-pro-title>

          <!-- Allow choose reviewer -->
          <div
            v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360"
            class="flex flex-col gap-2"
          >
            <div class="flex gap-4 items-center">
              <svn-pro-switch v-model="campaignDraft.can_choose_pairs" />

              <div class="w-full">
                <svn-pro-text subtitle-medium medium>
                  {{ $t('Allow the participant to choose their reviewer(s)') }}
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
              {{ $t('The participant can choose who will give feedback during this campaign. The person in charge of the Feedback 360 can manage reviewers at any  time.') }}
            </svn-pro-text>
          </div>

          <!-- Allow consult reviewer answer -->
          <div
            v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360"
            class="flex flex-col gap-2"
          >
            <div class="flex gap-4 items-center">
              <svn-pro-switch v-model="campaignDraft.can_view_pairs_answers" />

              <div class="w-full">
                <svn-pro-text subtitle-medium medium>
                {{ $t('Allow the participant to consult reviewer answers') }}
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
              {{ $t('The participant is able to view answers from his/her reviewers.  Otherwise, only the person in charge of the feedback could see answers.') }}
            </svn-pro-text>
          </div>

          <!-- Allow self evaluation -->
          <div
            v-if="campaignDraft?.campaign_type === CampaignTypeEnum.FEEDBACK_360"
            class="flex flex-col gap-2"
          >
            <div class="flex gap-4 items-center">
              <svn-pro-switch v-model="campaignDraft.can_self_evaluate" />

              <div class="w-full">
                <svn-pro-text subtitle-medium medium>
                  {{ $t('Self evaluation') }}
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
              {{ $t('The participant has to answer the feedback questions too.') }}
            </svn-pro-text>
          </div>

          <!-- Allow Anonymise answers -->
          <div
            v-if="campaignDraft?.campaign_type === CampaignTypeEnum.SURVEY"
            class="flex flex-col gap-2"
          >
            <div class="flex gap-4 items-center">
              <svn-pro-switch v-model="campaignDraft.anonymize_answers" />

              <div class="w-full">
                <svn-pro-text subtitle-medium medium>
                  {{ $t('Anonymise answers') }}
                </svn-pro-text>
              </div>
            </div>

            <svn-pro-text subtitle-medium regular color="onSurfaceVariant">
              {{ $t('Pictures and names of participants are anonymised.') }}
            </svn-pro-text>
          </div>
        </div>
      </template>
    </div>

    <!-- Previous & Next button button -->
    <div class="w-full flex justify-end items-center gap-2">
      <svn-pro-button
        variant="outlined"
        :text="$t('Previous')"
        @click="previous"
      />

      <svn-pro-button
        variant="flat"
        :text="$t('Next')"
        :loading="loading"
        prepend-icon="custom:mingcute:arrow-right-line"
        @click="next"
      />
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { debounce } from "lodash";
import i18n from "@/plugins/i18n";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import filters from "@/tools/filters.js";
import { ref, watch, computed, onMounted } from "vue";
import { useSnackbar } from "@/store/snackbar.js";
import citiesList from '../../../tools/world-cities';
import CampaignTypeEnum from "@/constants/CampaignTypeEnum";
import { useCampaignDraftStore } from "@/store/campaign-draft.js";
import PopUpEditTime from "../../popUpComponents/PopUpEditTime.vue";
import PopUpEditDate from "../../popUpComponents/PopUpEditDate.vue";

const emit = defineEmits(["next", "previous"]);

const snackbar = useSnackbar();

const { campaignDraft, loading: campaignDraftLoading } = storeToRefs(useCampaignDraftStore());
const { updateCampaignDraft, fetchCampaignDraft } = useCampaignDraftStore();

onMounted(async() => {
  isCampaignRecurrent.value = campaignDraft.value.is_recurrent
});

const loading = ref(false);
const durationError = ref(false);
const endDateError = ref(false);
const startDateError = ref(false);
const startTimeError = ref(false);
const startTimeZoneError = ref(false);
const recurrenceStopError = ref(false);
const isCampaignRecurrent = ref(false);
const durationUnits = ref([
  {
    text: i18n.global.t("Days"),
    value: 'days'
  },
  {
    text: i18n.global.t("Weeks"),
    value: 'weeks'
  },
  {
    text: i18n.global.t("Months"),
    value: 'months'
  },
  {
    text: i18n.global.t("Years"),
    value: 'years'
  },
]);
const repetition = ref([
  {
    text: i18n.global.t("Every week"),
    value: 'every_week'
  },
  {
    text: i18n.global.t("Every month"),
    value: 'every_month'
  },
  {
    text: i18n.global.t("Every 6 months"),
    value: 'every_six_month'
  },
  {
    text: i18n.global.t("Every year"),
    value: 'every_year'
  },
]);
const periodicityMonth = ref([
  {
    text: i18n.global.t("Same day each month"),
    value: 'same_day'
  },
  {
    text: i18n.global.t("First day of the month"),
    value: 'first_day'
  },
  {
    text: i18n.global.t("Last day of the month"),
    value: 'last_day'
  },
]);

const previous = debounce(() => {
  emit("previous");
});

const next = debounce(async () => {
  loading.value = true
  if (campaignDraft?.value?.is_recurrent) {
    if (!campaignDraft?.value?.startline) {
      startDateError.value = true
      snackbar.setTwoLines(true).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (* and empty fields).").displaySnackBar()
    }
    if (!campaignDraft?.value?.will_launch_at) {
      startTimeError.value = true
      snackbar.setTwoLines(true).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (* and empty fields).").displaySnackBar()
    }
    if (!campaignDraft?.value?.recurrent_time_zone) {
      startTimeZoneError.value = true
      snackbar.setTwoLines(true).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (* and empty fields).").displaySnackBar()
    }if (!campaignDraft?.value?.deadline_number) {
      durationError.value = true
      snackbar.setTwoLines(true).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (* and empty fields).").displaySnackBar()
    }

    if (!campaignDraft?.value?.recurrent_stop_at) {
      recurrenceStopError.value = true
      snackbar.setTwoLines(true).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (* and empty fields).").displaySnackBar()
    }
    if (!startDateError?.value && !startTimeError?.value && !startTimeZoneError?.value && !recurrenceStopError?.value) {
      try {
        await updateCampaignDraft({
          startline: campaignDraft.value.startline,
          deadline: null,
          is_recurrent: campaignDraft.value.is_recurrent,
          deadline_duration_unit: campaignDraft.value.deadline_duration_unit,
          deadline_number: parseInt(campaignDraft.value.deadline_number),
          recreate_repetition_option: campaignDraft.value.recreate_repetition_option,
          recreate_repetition: campaignDraft.value.recreate_repetition,
          will_launch_at: campaignDraft.value.will_launch_at,
          recurrent_time_zone: campaignDraft.value.recurrent_time_zone,
          recurrent_stop_at: campaignDraft.recurrent_stop_at,
          can_choose_pairs: campaignDraft.value.can_choose_pairs,
          can_self_evaluate: campaignDraft.value.can_self_evaluate,
          can_view_pairs_answers: campaignDraft.value.can_view_pairs_answers,
          anonymize_answers: campaignDraft.value.anonymize_answers,
        });

        fetchCampaignDraft();
        emit("next");
      } catch (error) {
        snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again.").displaySnackBar()
      }
    }
  } else {
    if (!campaignDraft?.value?.startline) {
      startDateError.value = true
      snackbar.setTwoLines(false).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar()
    }
    if (!campaignDraft?.value?.deadline) {
      endDateError.value = true
      snackbar.setTwoLines(false).setBgColor("onSurface").setCustomClass('mb-4').setMsg("Please fill all required fields (*).").displaySnackBar()
    }
    if (!startDateError?.value && !endDateError?.value) {
      try {
        await updateCampaignDraft({
          startline: campaignDraft.value.startline,
          deadline: campaignDraft.value.deadline,
          is_recurrent: false,
          deadline_duration_unit: campaignDraft.value.deadline_duration_unit,
          deadline_number: parseInt(campaignDraft.value.deadline_number),
          recreate_repetition_option: campaignDraft.value.recreate_repetition_option,
          recreate_repetition: campaignDraft.value.recreate_repetition,
          can_choose_pairs: campaignDraft.value.can_choose_pairs,
          can_self_evaluate: campaignDraft.value.can_self_evaluate,
          can_view_pairs_answers: campaignDraft.value.can_view_pairs_answers,
          anonymize_answers: campaignDraft.value.anonymize_answers,
        });

        fetchCampaignDraft();
        emit("next");
      } catch (error) {
        snackbar.setBgColor("onSurface").setCustomClass('mb-4').setMsg("An error occured, please try again.").displaySnackBar()
      }
    }
  }
  loading.value = false
}, 300);

const updateStartDate = (value) => {
  campaignDraft.value.startline = moment(value).format('YYYY-MM-DD');
}

const updateEndDate = (value) => {
  if (isCampaignRecurrent?.value) {
    campaignDraft.value.recurrent_stop_at = moment(value).format('YYYY-MM-DD');
  } else {
    campaignDraft.value.deadline = moment(value).format('YYYY-MM-DD');
  }
}

const updateStartTime = (value) => {
  campaignDraft.value.will_launch_at = value
}

const updateIsCampaignRecurrent = (e) => {
  isCampaignRecurrent.value = !isCampaignRecurrent.value

  campaignDraft.value.is_recurrent = e

  endDateError.value = false
  startDateError.value = false
  startTimeError.value = false
  startTimeZoneError.value = false
  recurrenceStopError .value = false
}

const isCampaignStartlineOnLastDays = computed(() => {
  let formattedDate = moment(campaignDraft?.value?.startline).format("YYYY-MM-DD");

  if (formattedDate !== 'Invalid date') {
    return (
      formattedDate?.split('-')?.[2] === '29' ||
      formattedDate?.split('-')?.[2] === '29' ||
      formattedDate?.split('-')?.[2] === '29'
    )
  }
})

watch(campaignDraft?.value, (oldValue, newValue) => {
  if (newValue?.startline && startDateError?.value) {
    startDateError.value = false
  }
  if (campaignDraft?.value?.is_recurrent) {
    if (newValue?.recurrent_stop_at && recurrenceStopError?.value) {
      recurrenceStopError.value = false
    }
    if (newValue?.will_launch_at && startTimeError?.value) {
      startTimeError.value = false
    }
    if (newValue?.recurrent_time_zone && startTimeZoneError?.value) {
      startTimeZoneError.value = false
    }
    if (newValue?.deadline_number && durationError?.value) {
      durationError.value = false
    }
  } else {
    if (newValue?.deadline && endDateError?.value) {
      endDateError.value = false
    }
  }
})
</script>