<template>
  <svn-pro-data-table
    :headers="campaignDrafts?.length ? headers : []"
    :items="campaignDrafts ?? []"
    :items-per-page="-1"
    :page="pagination?.current_page"
    :loading="campaignDrafts ? loading : true"
    item-value="id"
    :pagination-total-pages="campaignPagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr
        class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]"
      >
        <td>
          <div class="w-[125px] flex flex-row items-center gap-2">
            <Icon
              :icon="types[item?.campaign_type]?.icon"
              width="16"
              height="16"
              class="mr-1"
            />

            <svn-pro-data-table-cell
              cell-type="text"
              :text="types[item?.campaign_type]?.title"
            />
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
            <svn-pro-data-table-cell
              medium
              body-medium
              cell-type="text"
              :text=" item?.title"
            />
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
              <svn-pro-text
                color="onSurface"
                class="text-nowrap"
              >
                {{ moment(item?.startline, 'YYYY-MM-DD').format('D MMM, YYYY') }}
              </svn-pro-text>
          </div>
        </td>

        <td>
          <div class="max-w-[400px] flex flex-row items-center gap-2">
            <svn-pro-text
              color="onSurface"
              class="text-nowrap"
            >
              {{ moment(item?.deadline, 'YYYY-MM-DD').format('D MMM, YYYY') }}
            </svn-pro-text>
          </div>
        </td>

        <td class="w-auto">
          <display-tag-in-index
            :tags="item?.categories"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="item?.employees_count"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="getCampaignStartTimeline(item?.startline_datetime)"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <v-list-item
                v-if="!item?.is_recurrent"
                value="edit_starting_line"
                @click="openStartLineDialog(item)"
              >
                {{ $t('Edit starting date') }}
              </v-list-item>

              <v-list-item
                v-if="item?.is_recurrent"
                value="manage_recurrence"
                @click="openManageRecurrenceModal(item)"
              >
                {{ $t('Manage recurrence') }}
              </v-list-item>

              <dialog-validation-pro
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('DELETE')"
                icon="noto:warning"
                :title="item?.is_recurrent ? $t('Recurrence will stop') : $t('Campaign will be deleted')"
                :content-text="item?.is_recurrent ? $t('If you delete this upcoming campaign, all recurrences will be deleted too.') : $t('This is a permanent action.')"
                @click-primary-button="deleteCampaignAndStopRecurrence(item)"
              >
                <template #activator="{ props }">
                  <v-list-item
                    value="delete_campaign"
                    v-bind="props"
                    :active="false"
                  >
                  {{ item?.is_recurrent ? $t('Delete campaign and recurrencee') : $t('Delete campaign') }}
                  </v-list-item>
                </template>
              </dialog-validation-pro>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #loading>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMdScreen ? 'default' : 'compact'"
        :action-primary-title="$t('Create Campaign')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t(`It seems you have no campaigns for the moment`) :
          $t(`All your upcoming campaigns will show up here.`)"
        @click-primary="emit('go-to-new-campaign')"
      />
    </template>
  </svn-pro-data-table>

  <pop-up-edit-date
    ref="startlineModal"
    :date-value="new Date(selectedCampaign?.startline)"
    @save="checkStartLineDate"
    min
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-edit-date>

  <pop-up-manage-recurrence
    ref="manageRecurrencRef"
    :title="i18n.global.t('Manage recurrence')"
    :campaign-id="selectedCampaign.id"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </pop-up-manage-recurrence>

  <dialog-validation-pro
    ref="launchCampaignRef"
    icon="noto:warning"
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('launch now')"
    :title="$t('Launch your campaign now ?')"
    :content-text="$t('The date selected is today. Your campaign will therefore be launched now.')"
    @click-primary-button="editCampaignStartline"
    @click-secondary-button="openStartLineDialog(selectedCampaign)"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
import { useCampaignDraftStore } from "@/store/campaign-draft";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar";
import { usePopUpStore } from "@/store/pop-up.js";
import useTools from "@/tools/useTools.js";
import i18n from "@/plugins/i18n";
import moment from "moment";
import axiosService from "@/tools/axios-service.js";
import { useMobileStore } from "@/store/mobile";
import DialogValidationPro from "@/components/BktPopUp/Dialogs/DialogValidationPro.vue";
import PopUpEditDate from "@/components/popUpComponents/PopUpEditDate.vue";
import PopUpManageRecurrence from "@/components/popUpComponents/PopUpManageRecurrence.vue";
import axios from '@/tools/axios-service.js'
import filters from "@/tools/filters.js";

const props = defineProps({
  search: { type: String, default: '' },
})

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())
const emit = defineEmits(["go-to-new-campaign"])

const headers = [
  {
    align: "start",
    key: "campaign_type",
    title: i18n.global.t("Type"),
    icon: "icon-park-outline:down-c",
    sortable: false,
    width: 20
  },
  {
    align: "start",
    key: "campaign_title",
    title: i18n.global.t("Campaign title"),
    icon: "mdi-format-text",
    sortable: false,
  },
  {
    title: i18n.global.t("Start date"),
    key: "start_date",
    icon: "akar-icons:calendar",
    sortable: false,
  },
  {
    title: i18n.global.t("Deadline"),
    key: "deadline",
    icon: "akar-icons:calendar",
    sortable: false,
  },
  {
    align: "start",
    key: "tags",
    title: i18n.global.t("Tags"),
    icon: "fluent:tag-32-regular",
    sortable: false,
  },
  {
    align: "start",
    key: "participants", title: "Participants", icon: "mdi-account-outline", sortable: false,
  },
  {
    align: "start",
    key: "will_start_on",
    title: i18n.global.t("Will start"),
    icon: "mdi:calendar-start",
    sortable: false,
  },
  {
    align: "start",
    key: "space", title: " ", icon: " ", sortable: false,
  }
];

const startlineModal = ref(null);
const manageRecurrencRef = ref(null);
const selectedCampaign = ref({})
const selectStartlineDate = ref('')
const launchCampaignRef = ref(null);
const { campaignDrafts, pagination, loading } = storeToRefs(useCampaignDraftStore());
const { fetchCampaignDrafts, setCampaignDrafts, updateParams } = useCampaignDraftStore();
const { fetchPage } = useTools();
const snackbar = useSnackbar();
const { closePopUp, openPopUp } = usePopUpStore();

const types = {
  Survey: {
    key: "survey",
    title: i18n.global.t("Survey"),
    icon: "noto:bar-chart"
  },
  OneToOne: {
    key: "one_to_one",
    title: i18n.global.t("One to One"),
    icon: "noto:handshake"
  },
  Feedback: {
    key: "feedback_360",
    title: i18n.global.t("Feedback"),
    icon: "noto:memo"
  }
}

const deleteCampaignAndStopRecurrence = async(item) => {
  try {
    await axiosService.delete(`/api/v2/interview_app/campaign_drafts/${item?.id}`);
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(item?.is_recurrent ? 'Upcoming campaign and its recurrences has been successfully deleted.' : 'Campaign has been deleted successfully.')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.setTwoLines(item?.is_recurrent)
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setMsg(i18n.global.t('Error deleting campaign !'));
    snackbar.setBgColor('error');
    snackbar.displaySnackBar();
  } finally {
    fetchPage(updateParams, fetchCampaignDrafts, pagination.value.current_page);
    closePopUp();
  }
}

const getCampaignStartTimeline = (date) => {
  const today = moment(new Date());
  const startline = moment(date);
  const dayDifference = startline.diff(today, 'days') + 1;
  // true if it is today or false if it's not
  const isToday = moment(date).isSame(moment(), 'day')

  if (isToday) {
    return (i18n.global.t('Today'));
  }
  else if (dayDifference <= 1) {
    return (i18n.global.t('Tomorrow'));
  }
  else if (dayDifference > 1 && dayDifference < 7) {
    return (i18n.global.t('In day(s)', { n: dayDifference }));
  }
  else if (dayDifference === 7) {
    return (i18n.global.t('In week(s)', { n: 1 }));
  }
  else if (dayDifference > 7 && dayDifference < 28) {
    return (i18n.global.t('In week(s)', { n: startline.diff(today, 'weeks') }));
  }
  else if (dayDifference >= 28 && dayDifference <= 32) {
    return (i18n.global.t('In month(s)', { n: 1 }));
  }
  else if (dayDifference >= 28 && dayDifference < 365) {
    return (i18n.global.t('In month(s)', { n: startline.diff(today, 'months') }));
  }
  else if (dayDifference >= 366) {
    return (i18n.global.t('In over a year'));
  }
}

const openStartLineDialog = (campaign) => {
  selectedCampaign.value = campaign
  launchCampaignRef.value.dialogRef.dialog = false
  startlineModal.value.dialog = true
}

const checkStartLineDate = async(date) => {
  selectStartlineDate.value = date
  if (moment(date).isSame(new Date(), "day")) {
    launchCampaignRef.value.dialogRef.dialog = true
  } else {
    editCampaignStartline()
  }
}

const editCampaignStartline = async() => {
  const newCurrentStartline = moment(selectStartlineDate.value).format('DD/MM/YYYY')
  const year = filters.formatDate(newCurrentStartline, "YYYY");
  const month = filters.formatDate(newCurrentStartline, "M");
  const day = filters.formatDate(newCurrentStartline, "DD");

  try {
    await axios.patch(`/api/v2/interview_app/campaigns/${selectedCampaign.value.id}.json`, {
      campaign: {
        startline: {
          year: year,
          month: month,
          day: day,
        }
      },
    });

    snackbar.setBgColor('onSurface');
    snackbar.setMsg('Staring date has been changed successfully.');
    snackbar.displaySnackBar();
  }
  catch (e) {
    snackbar.setBgColor('error');
    snackbar.setMsg('Error updating starting date !');
    snackbar.displaySnackBar();
  }
  finally {
    startlineModal.value.dialog = false
    fetchPage(updateParams, fetchCampaignDrafts, pagination.value.current_page);
    selectedCampaign.value = null
  }
}

const openManageRecurrenceModal = async(campaign) => {
  selectedCampaign.value = campaign
  manageRecurrencRef.value.manageRecurrence.dialog = true

}

const fetchNewPage = (e) => {
  setCampaignDrafts(null);
  updateParams({
    "page[number]": e,
  });
  fetchCampaignDrafts();
};
</script>
