
<template>
  <svn-pro-card
    :link="false"
    class="rounded-2xl bkt-box-shadow px-10 py-8"
  >
    <v-card-title
      class="p-0 pb-2 text-wrap"
    >
      <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
        {{ question.text }}
      </svn-pro-title>
    </v-card-title>

    <v-card-subtitle
      class="my-3 p-0"
    >
      <div
        class="mb-4 bkt-unwrap bkt-v-html-format"
        v-html="question.description"
      />
    </v-card-subtitle>

    <v-card-text
      class="p-0"
    >
      <v-rating
        :length="question.options"
        empty-icon="custom:mingcute:star-line"
        full-icon="custom:mingcute:star-fill"
        color="onSurface"
        size="48"
        class="flex-wrap"
        active-color="onSurface"
      />

      <svn-pro-text-area
        :placeholder="$t('Comment')"
        variant="outlined"
        :rows="6"
        class="mt-4"
        :max-rows="6"
      />
    </v-card-text>
  </svn-pro-card>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps(['question', 'loopIdx'])

</script>