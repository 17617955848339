<template>
  <svn-pro-modal
    ref="addToTrainingModal"
    :width="isMdScreen ? 480 : undefined"
    :sticky-bottom="isMdScreen ? true : false"
    :action-one-title="withValidateButton ? 'validate' : ''"
    @click-primary-button="$emit('update-list'), addToTrainingModal.dialog = false"
    :title="contentType === 'module' ?
      $t('Select modules to add ()', { selectedModules: selectedModulesToAdd?.length || 0 })
      : $t('Select playlists to add ()', { selectedPlaylists: selectedPlaylistsToAdd?.length || 0 })"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-4 h-full md:!h-[440px]">
        <div class="w-full flex flex-col md:!flex-row items-start md:!items-center gap-4">
          <svn-pro-text-field
            v-model="searchText"
            :placeholder="$t('Search')"
            prepend-inner-icon="custom:mingcute:search-2-line"
            @update:model-value="toggleSearch"
            clearable
            class="w-full"
            clear-icon="custom:mingcute:close-line"
          />

          <!-- Filter -->
          <svn-pro-menu>
            <template #activator="{ props }">
              <svn-pro-button
                v-bind="props"
                :active="selectedThemesList?.length"
                :text="`${$t('Filter')} ${selectedThemesList?.length ? `(${selectedThemesList?.length})` : ''}`"
                prepend-icon="custom:mingcute:filter-2-line"
                variant="text"
              />
            </template>

            <template #dropdown>
              <v-list-item
                v-if="trainingThemes?.length"
                v-for="(item, index) in trainingThemes"
                :key="index"
                :value="item"
                :title=item?.name
                :active="selectedThemesList?.find(theme => theme === item) !== undefined ? true : false"
                @click.stop="toggleThemeSelection(item)"
              >
                <template #prepend="{ isActive }">
                  <v-list-item-action start>
                    <v-checkbox-btn :model-value="isActive" />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </svn-pro-menu>
        </div>

        <template v-if="loading">
          <div class="w-full h-full flex flex-col items-center justify-center">
            <svn-pro-progress-circular
              :size="48"
              :width="5"
              indeterminate
            />
          </div>
        </template>

        <div v-else>

          <v-infinite-scroll
            v-if="contents?.length"
            :items="contents"
            class="overflow-hidden w-full"
            color="primary"
            @load="loadMoreData"
          >
            <v-list
              select-strategy="classic"
              color="primary"
            >
              <v-list-item
                v-for="(content, index) in contents"
                :key="index"
                :active="false"
                :value="`${content?.content_type}_${content?.id}`"
                class="border-b border-b-[#767680] border-opacity-100"
              >
                <content-card-item
                  :content="content"
                  @toggle-content="toggleContent(content)"
                />

                <template #append>
                  <v-list-item-action>
                    <v-checkbox-btn
                      v-if="content?.content_type === 'Learn::Module'"
                      color="primary"
                      :class="selectedModulesToAdd?.some((data) => data?.id == content?.id && data?.content_type == content?.content_type) ? 'text-primary' : ''"
                      :model-value="selectedModulesToAdd?.some((data) => data?.id == content?.id && data?.content_type == content?.content_type) ? true : false"
                      @update:model-value="toggleContent(content)"
                    />

                    <v-checkbox-btn
                      v-else
                      color="primary"
                      :class="selectedPlaylistsToAdd?.some((data) => data?.id == content?.id && data?.content_type == content?.content_type) ? 'text-primary' : false"
                      :model-value="selectedPlaylistsToAdd?.some((data) => data?.id == content?.id && data?.content_type == content?.content_type) ? true : false"
                      @update:model-value="toggleContent(content)"
                    />
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list>
          </v-infinite-scroll>
          <div
            v-else-if="!contents?.length && !(searchText || selectedThemesList?.length)"
            class="flex flex-col px-6 py-4 gap-2 justify-center items-center h-[183px] md:!h-[388px] text-center"
          >
            <div>
              <Icon
                icon="noto:face-with-monocle"
                height="48"
                width="48"
              />
            </div>

            <svn-pro-text body-large regular color="onSurfaceVariant">
              {{ $t(`You dont have any ${contentType} for the moment.`) }}
            </svn-pro-text>
          </div>

          <div
            v-else-if="!contents?.length && (searchText || selectedThemesList?.length)"
            class="flex flex-col px-6 py-4 gap-2 justify-center items-center h-[183px] md:!h-[388px] text-center"
          >
            <div>
              <Icon
                icon="noto:face-with-monocle"
                height="48"
                width="48"
              />
            </div>

            <svn-pro-text body-large regular color="onSurfaceVariant">
              {{ $t('Oops, we didn’t find any results matching your search.') }}
            </svn-pro-text>
          </div>
        </div>
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref } from "vue";
import ContentCardItem from "../../../trainingApp/ContentCardItem.vue";
import { Icon } from "@iconify/vue";
import { useLearnModuleStore } from "@/store/learn-module";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useRouter } from "vue-router";
import { debounce } from "lodash";
import { useSnackbar } from "@/store/snackbar";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"

const props = defineProps({
  contentType: { type: String, required: true },
  trainingThemes: { type: Array, default: null },
  contents: { type: Array, required: true },
  loading: { type: Boolean, default: false },
  withValidateButton: { type: Boolean, default: false },
  trainingId: { type: Number, required: true },
  selectedModulesToAdd: { type: Array, default: null },
  selectedPlaylistsToAdd: { type: Array, default: null },
  modulesAndPlaylist: { type: Array, default: null },
})

const searchText = ref('')

const addToTrainingModal = ref(null)

const selectedThemesList = ref([])

const snackbar = useSnackbar()

const router = useRouter()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const { createModule, updateModuleImage } = useLearnModuleStore()

const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const emit = defineEmits(['toggle-search', 'toggle-content-in-training', 'update-list', 'load-more-data'])

const toggleThemeSelection = (item) => {
  const found = selectedThemesList?.value?.find(theme => theme === item);

  if (found) {
    selectedThemesList.value?.splice(selectedThemesList.value?.findIndex((theme) => theme === item), 1);
  }
  else {
    selectedThemesList.value?.push(item);
  }
  toggleSearch()
}

const clearAllSelectedThemes = () => {
  selectedThemesList.value = [];
}

const toggleSearch = debounce(() => {
  if (props?.contentType === 'module') {
    emit('toggle-search', searchText?.value, 'module', selectedThemesList.value.map(theme => theme?.id))
  }
  else if (props?.contentType === 'playlist') {
    emit('toggle-search', searchText?.value, 'playlist', selectedThemesList.value.map(theme => theme?.id))
  }
}, 300)

const goToNewModule = async() => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    const module = await createModule("Module title", "30 minutes")

    loadXHR(randomImage).then(async(blob) => {
      updateModuleImage(module?.data?.id, blob)
    })

    addToTrainingModal.value.dialog = false

    router.push({ name: "module_edit", params: { id: module?.data?.id }, query: { training_id: props.trainingId} })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error creating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const toggleContent = async(content) => {
  emit('toggle-content-in-training', content)
}

const addContentsToTraining = async() => {
  addToTrainingModal.value.dialog = false
}
const loadMoreData = async({ done }) => {
  emit('load-more-data', { done })
}
</script>

<style scoped>
.input :deep(.v-input__details) {
  display: flex;
  flex-direction: row-reverse;
  padding: 0px;
}
</style>
