<template>
  <svn-pro-data-table
    :headers="templates?.length ? headers : []"
    :items="templates ?? []"
    :items-per-page="-1"
    :loading="templates ? loading : true"
    item-value="id"
    :pagination-total-pages="pagination?.total_pages"
    @update-pagination="fetchNewPage"
  >
    <template #item="{ item }">
      <tr class="hover:bg-[#46464F14] active:bg-[#46464F1F]/[0.12]" @click="goToTemplateShow(item?.id)">
        <td>
          <svn-pro-data-table-cell
            medium
            body-large
            cell-type="text"
            :text="item?.title"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.due_date, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td>
          <svn-pro-data-table-cell
            cell-type="text"
            :text="$filters.formatSpecificDate(item?.updated_at, 'YYYY-MM-DD', 'DD MMM, YYYY')"
          />
        </td>

        <td>
          <svn-pro-menu>
            <template #activator>
              <svn-pro-icon-button
                icon="custom:mingcute:more-2-line"
                variant="flat"
                color="surface"
              />
            </template>

            <template #dropdown>
              <dialog-validation-pro
                icon="noto:warning"
                :action-two-title="$t('Cancel')"
                :action-one-title="$t('Delete')"
                :title="$t('Template will be deleted')"
                :content-text="$t('Deleted templates are stored for 30 days. After this period, they will be permanently deleted.')"
                @click-primary-button="deleteTemplate(item?.id)"
              >
                <template #activator="{ props }">
                  <v-list-item v-bind="props" :active="false" density="compact"value="delete_template">
                    <svn-pro-text body-large regular color="error">
                      {{ $t('Delete template') }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </dialog-validation-pro>
            </template>
          </svn-pro-menu>
        </td>
      </tr>
    </template>

    <template #no-data>
      <svn-pro-empty-states
        actions
        :variant="search ? 'results' : 'index'"
        :title="$t('Nothing to show for now')"
        :size="isMobile ? 'compact' : 'default'"
        :action-primary-title="$t('New template')"
        prepend-primary="custom:mingcute:add-line"
        :supporting-text="search ? $t('Oops, we didn’t find any results matching your search.') :
          $t('Hmm, it seems you have no current templates.<br/>Create your first template!')"
        @click-primary="goToNewTemplatePage"
      />
    </template>

    <template #loading>
    </template>
  </svn-pro-data-table>
</template>

<script setup>
import { ref } from 'vue';
import i18n from '@/plugins/i18n';
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMobileStore } from "@/store/mobile";
import DialogValidationPro from "../../BktPopUp/Dialogs/DialogValidationPro.vue";

const props = defineProps({
  search: { type: String, default: null },
  templates: { type: Array, required: false },
  loading: { type: Boolean, required: true },
  pagination: {type: Object, default: () => { return null }}
})

const emit = defineEmits(['fetch-new-page', 'delete-template'])

const { isMobile } = storeToRefs(useMobileStore());

const router = useRouter();

const headers = ref([
  {
    align: "start",
    key: "template_title",
    sortable: true,
    title: i18n.global.t('Targets template'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "due_date",
    sortable: false,
    title: i18n.global.t('Due date'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "last_update",
    sortable: false,
    title: i18n.global.t('Last update'),
    minWidth: 150,
    width: 150,
  },
  {
    align: "start",
    key: "options",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  },
]);

const goToNewTemplatePage = () => {
  router.push({ name: 'roadmaps_templates_new' })
}

const goToTemplateShow = (id) => {
  router.push({ name: 'roadmaps_templates_show', params: { id } });
}

const deleteTemplate = (id) => {
  emit('delete-template', id)
}

const fetchNewPage = (page) => {
  emit('fetch-new-page', page)
}
</script>
