<template>
  <svn-pro-card
    :link="false"
    width="700"
    :rounded="isMobile ? '0' : 'lg'"
    class="p-4"
    :class="isMobile ? 'border-0' : ''"
  >
    <v-card-actions class="!flex md:!flex-row flex-col-reverse gap-3 !p-0 items-start md:items-center md:justify-between">
      <slot name="title" />
      <div  class="flex gap-1 flex-1 items-end md:justify-end justify-between w-full" >
        <svn-pro-icon-button
          variant="text"
          v-if="moveUp"
          color="onSurfaceVariant"
          role="move-up"
          icon="custom:mingcute:arrow-up-line"
          :disabled="isDeleted"
          @click="$emit('moveUp')"
        />
        <svn-pro-icon-button
          variant="text"
          color="onSurfaceVariant"
          v-if="moveDown"
          role="move-down"
          icon="custom:mingcute:arrow-down-line"
          :disabled="isDeleted"
          @click="$emit('moveDown')"
        />
        <svn-pro-icon-button
          v-if="copy"
          variant="text"
          color="onSurfaceVariant"
          icon="custom:mingcute:copy-2-line"
          role="copy"
          :disabled="isDeleted"
          @click="$emit('copy-bkt')"
        />
        <svn-pro-icon-button
          v-if="$props.delete"
          variant="text"
          color="onSurfaceVariant"
          role="delete"
          icon="custom:mingcute:delete-2-line"
          :disabled="isDeleted || isDeleteDisabled"
          @click="$emit('delete')"
        />
      </div>
    </v-card-actions>

    <v-expand-transition>
      <div class="flex flex-col gap-3">
        <v-divider />

        <slot name="body" />
      </div>
    </v-expand-transition>
  </svn-pro-card>
</template>

<script setup>
import {useMobileStore} from "@/store/mobile.js";
import {storeToRefs} from "pinia";

const {isMobile} = storeToRefs(useMobileStore())

defineProps({
  moveUp: {type: Boolean, default: true},
  moveDown: {type: Boolean, default: true},
  copy: {type: Boolean, default: true},
  delete: {type: Boolean, default: true},
  isDeleted: {type: Boolean, required: true},
  isDeleteDisabled: {type: Boolean, required: false}
})

</script>
