<template>
  <svn-pro-card
    :link="false"
    class="p-8"
    >
    <div class="flex flex-col gap-4">
      <svn-pro-title h6 bold>
        <v-icon
          class="text-[#5C95FF]"
          icon="custom:mingcute:target-line"
          size="24"
        />

        {{ target.title }}
      </svn-pro-title>

      <div class="flex justify-between">
        <svn-pro-info-tag
          tag-type="error"
          :text="targetValue"
          tag-style="filled"
          class="!w-fit h-[32px] justify-center"
          leading-item="point"
        />
        <div>
          <v-icon
            v-bind="props"
            class="pl-1 cursor-pointer"
            icon="custom:mingcute:calendar-month-line"
            size="24"
          />
          {{ moment(target.due_date).format('DD MMM') }}
        </div>
      </div>

      <svn-pro-text
        medium
        regular
        color="onSurfaceVariant"
      >
        {{ target?.description }}
      </svn-pro-text>

      <svn-pro-divider class="w-full border-[#767680]"/>

      <div class="gap-3 flex items-center justify-between flex-wrap">
        <svn-pro-text
          medium
          regular
          color="onSurfaceVariant"
        >
          {{ $t('This target is still a draft. Complete all interviews of the set to create it.') }}
        </svn-pro-text>

        <div :class="!isLargeScreen ? 'w-full' : 'w-fit'" class="flex gap-2 text-center justify-between items-center">
          <svn-pro-modal
            ref="editDialog"
            :title="$t('Edit target')"
            :width="872"
          >
            <template #activator>
              <svn-pro-button
                color="primary"
                variant="tonal"
                :disabled="locked"
                :text="$t('Edit target')"
                prepend-icon="custom:mingcute:edit-2-line"
                @click="editDialog.dialog = true"
              />
            </template>

            <template #text>
              <target-new
                :target-id="target?.id"
                :overlay-mode="true"
                :users-from-overlay="[employee]"
                :is-editing-target="true"
                :create-draft="isDraft"
                @close="closePopUpAndReloadInterview"
                from="interview_answer"
              />
            </template>
          </svn-pro-modal>

          <svn-pro-icon-button
            color="onSurface"
            icon="custom:mingcute:delete-2-line"
            variant="outlined"
            :disabled="locked"
            @click="deleteTargetDraft(target?.id)"
          />
        </div>
      </div>
    </div>
  </svn-pro-card>
</template>

<script setup>
import { ref, computed } from 'vue';
import {storeToRefs} from "pinia";
import TargetNew from '@/views/roadmaps/Targets/New.vue';
import { useInterviewStore } from "@/store/interview.js";
import { useSnackbar } from '@/store/snackbar';
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';
import moment from "moment";
import {useMobileStore} from "@/store/mobile.js";

const props = defineProps({
  target: { type: Object, required: true },
  question: { type: Object, required: false },
  isDraft: { type: Boolean, default: false },
  employee: { type: Object, required: false },
})

const { locked } = storeToRefs(useInterviewStore())

const snackbar = useSnackbar();
const { deleteTargetDraftFromInterviewAnswer } = useTargetSuggestionsStore();
const { isLargeScreen } = storeToRefs(useMobileStore());

const editDialog = ref(false);

const emit = defineEmits(['changed']);

const targetValue = computed (() => {
  if (props.target?.objective_indicator?.indicator_type === 'multi_choice') {
    return getMCQPossibleAnswers(props.target?.objective_indicator?.options?.multi_choice_list)
  } else {
    return props.target?.objective_indicator?.options?.target_value.toString() +  (props.target?.objective_indicator?.indicator_type === 'percentage' ? '%' : '') || '-'
  }
})

const getMCQPossibleAnswers = (mcq) => {
  if (mcq) {
    return mcq.filter((option) => option.checkbox === true).length
  }
}

const deleteTargetDraft = async(targetDraftId) => {
  try {
    await deleteTargetDraftFromInterviewAnswer(targetDraftId);
    emit('changed', true)
    snackbar.setBgColor('onSurface').setMsg('Target proposal successfully deleted').displaySnackBar();
  } catch (error) {
    snackbar.setBgColor('onSurface').setMsg('Error deleting target proposal').displaySnackBar();
  }
}

const closePopUpAndReloadInterview = (newTargetData) => {
  editDialog.value.dialog = false
  emit('changed', newTargetData)
}
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px !important;
}
</style>
