<template>
  <svn-pro-modal
    v-model="dialogEditPlaylist"
    :width="isMdScreen ? '580' : ''"
    :title="$t('Update playlist')"
    no-click-animation
    :actionOneTitle="$t('Validate')"
    :sticky-bottom="isMdScreen ? true : false"
    :close-button-closes-modal="false"
    :primary-loading="formLoading"
    @click-close="dialogEditPlaylist = false"
    @click-primary-button="emit('update-playlist', playlistTitle, playlistDescription)"
    @click-outside="dialogEditPlaylist = false"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>

    <template #text>
      <div class="flex flex-col gap-6">
        <!-- Title input -->
        <svn-pro-text-field
          v-model="playlistTitle"
          variant="outlined"
          color="onSurfaceVariant"
          :label="('Playlist title*')"
          :counter="true"
          maxlength="70"
        />

        <!-- Description input -->
        <svn-pro-text-area
          v-model="playlistDescription"
          :label="$t('Your message*')"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :counter="true"
          :maxlength="200"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import { storeToRefs } from 'pinia';
import { Icon } from '@iconify/vue';
import { useMobileStore } from '@/store/mobile';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  }
})

const dialogEditPlaylist = ref(false)

const playlistTitle = ref('')

const playlistDescription = ref('')

const { isMdScreen } = storeToRefs(useMobileStore())

const emit = defineEmits(['update-playlist'])

watch(props ,() => {
  playlistTitle.value = props.title
  playlistDescription.value = props.description
})

defineExpose({
  dialogEditPlaylist
})
</script>