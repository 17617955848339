<template>
  <div class="w-full h-full relative bg-surface">
    <div class="w-full h-full max-w-[1136px]  mx-auto flex flex-col gap-4 items-center pt-5 px-5 md:!px-12">
      <!-- Header Container -->
      <div class="w-full flex flex-col gap-4">
        <div class="w-full flex gap-4 justify-between items-center">
          <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
            {{ $t(`Reports (${(reportPagination?.total_count || 0)})`) }} {{  }}
          </svn-pro-title>

          <div class="flex items-center gap-2">
            <svn-pro-button
              variant="flat"
              prepend-icon="custom:mingcute:add-line"
              :text="$t('New report')"
              class="hidden md:flex"
              @click="goToNewReport"
            />

            <svn-pro-menu>
              <template #activator="{ props }">
                <svn-pro-icon-button
                  v-bind="props"
                  icon="custom:mingcute:more-2-fill"
                  color="onSurfaceVariant"
                  variant="text"
                />
              </template>

              <template #dropdown>
                <!-- Modal Deleted Items to CUSTOMIZE -->
                <modal-view-deleted-items
                  ref="deletedItemsRef"
                  content-type="report"
                  :contents="trashedReports"
                  font-weight="font-medium"
                  :user-attribute-name="'creator'"
                  title="fullname"
                  :avatar-size="40"
                  :has-user="true"
                  :has-user-with-created-at="true"
                  :has-title="false"
                  @view-item="downloadMessage"
                  @delete-permanently="deletePermanentlyDeletedItems"
                  @restore="restoreDeletedItems"
                  @toggle-search="toggleSearchDeletedItems"
                  :headers="trashesHeaders"
                  :items="trashedReports"
                  :pagination="trashReportPagination"
                  @fetch-new-page="fetchTrashedReports('', $event)"
                  @close-modal="closeModal"
                >
                    <template #activator="{ props }">
                      <v-list-item
                        v-bind="props"
                        :active="false"
                        density="compact"
                        value="view_deleted"
                      >
                        <svn-pro-text body-large regular>
                          {{ $t('View deleted report') }}
                        </svn-pro-text>
                      </v-list-item>
                    </template>
                  </modal-view-deleted-items>
              </template>
            </svn-pro-menu>
          </div>
        </div>

        <!-- Search -->
        <svn-pro-text-field
          v-model="searchReport"
          variant="outlined"
          :placeholder="$t('Search')"
          prepend-inner-icon="custom:mingcute:search-2-line"
          @update:model-value="search"
        />

        <!-- Table -->
        <interview-report-table
          :search="searchReport"
        />
      </div>
    </div>

    <svn-pro-fab
      v-if="!isMdScreen"
      extended
      class="fixed bottom-4 right-4"
      variant="primary"
      :text="$t('New report')"
      prepend-icon="custom:mingcute:add-line"
      @click="goToNewReport"
    />
  </div>
</template>
<script setup>
import BktIndexSkeleton from "@/components/BktIndexSkeleton.vue";
import BktCreateEntityFromIndex from "@/components/BktCreateEntityFromIndex.vue";
import { useReportStore } from "@/store/reports.js";
import { storeToRefs } from "pinia";
import InterviewReportTable from "@/components/interviewApp/reports/InterviewReportTable.vue";
import { useActionCable } from "@/store/cable.js";
import { onBeforeUnmount, ref } from "vue";
import BktLearnListSettings from '@/components/BktLearnListSettings.vue'
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import i18n from '@/plugins/i18n';
import { debounce } from 'lodash';
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile";
import {useRouter} from "vue-router";

const router = useRouter();
const snackbar = useSnackbar();
const { isMobile, isMdScreen } = storeToRefs(useMobileStore())

const { reports, reportPagination, trashedReports, trashReportPagination } = storeToRefs(useReportStore());
const { fetchReports, fetchTrashedReports, restoreReport, deletePermanentlyReport } = useReportStore();

const { cable } = storeToRefs(useActionCable());

const reportChannel = ref(null);
const searchReport = ref('');
const deletedItems = ref(null)
const deletedItemsRef = ref(null);
const reportToDelete = ref(null);

const trashesHeaders = ref([
  {
    align: "start",
    key: "fullname",
    sortable: true,
    title: i18n.global.t('Creator'),
    minWidth: 200,
  },
  {
    align: "start",
    key: "deleted_at",
    sortable: false,
    title: i18n.global.t('Deleted on'),
    minWidth: 150,
  },
  {
    align: "start",
    key: "restore",
    sortable: false,
    title:"",
    width: 40,
    minWidth: 40,
  },
  {
    align: "start",
    key: "delete",
    sortable: false,
    title: "",
    width: 40,
    minWidth: 40,
  }
])

const closeModal = (id) => {
  deletedItemsRef.value.deletedItems.dialog = false
  deletedItemsRef.value.deleteTargetRef.dialogRef.dialog = true

  reportToDelete.value = id
}

fetchReports();
fetchTrashedReports()
reportChannel.value = cable.value?.subscriptions.create(
  {
    channel: "ReportChannel"
  },
  {
    connected() {},
    disconnected() {},
    received(reportData) {
      const reportIndex = reports.value.findIndex(r => r?.id === reportData?.id);

      if (reportIndex !== -1) {
        reports.value[reportIndex] = { ...reports.value[reportIndex], ...reportData };
      }
    },
  }
);

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashedReports(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const search = debounce(async() => {
  try {
    fetchReports(searchReport.value);
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching reports')
    snackbar.displaySnackBar()
  }
}, 300)

const deletePermanentlyDeletedItems = async () => {
  try {
    await deletePermanentlyReport(reportToDelete.value)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(i18n.global.t('Report has been deleted successfully.'))
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg(i18n.global.t('Error while deleting permanently a report'))
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const openDeletedItems = () => {
  deletedItems.value.modalDeletedItems = true
}

const settingsOnAllItems = ref([
  { name: i18n.global.t('View deleted reports'),
    onClick: openDeletedItems
  }
])

const restoreDeletedItems = async (id) => {
  try {
    await restoreReport(id)
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(i18n.global.t('Report has been restored successfully.'))
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg(i18n.global.t('Error while restoring a report'))
    snackbar.setCustomClass(isMobile.value ? 'mb-[report]' : 'mb-[42px]')
    snackbar.displaySnackBar()
  }
}

const downloadMessage = async (id) => {
  snackbar.setBgColor('onSurface')
  snackbar.setMsg(i18n.global.t('You must restore the report to download it'))
  snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[42px]')
  snackbar.displaySnackBar()
}

const goToNewReport = () => {
  router.push({ name: 'edit_report' })
}

onBeforeUnmount(() => {
  if(reportChannel.value) {
    reportChannel.value.unsubscribe();
  }
});
</script>