<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <template #activator="{ props, isActive }">
      <slot :props="props" name="activator" :isActive="isActive">
        <svn-pro-button text="Activate" />
      </slot>
    </template>
    <template #default>
      <svn-pro-date-picker
        :width="'100%'"
        actions
        :border="0"
        :elevation="0"
        :min="min ? moment().subtract(1, 'days').toDate() : null"
        :model-value="date"
        @update:model-value="updateDate"
      >
        <template #actions>
          <svn-pro-button
            variant="text"
            :text="$t('Cancel')"
            @click="dialog = false"
          />

          <svn-pro-button
            variant="text"
            text="Ok"
            @click="save"
          />
        </template>
      </svn-pro-date-picker>
    </template>
  </v-dialog>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import moment from "moment";

const props = defineProps({
  dateValue: { type: Date, default: undefined },
  min: { type: Boolean, default: false },
})

const emit = defineEmits(['save'])

onMounted(() => {
  date.value = props.dateValue || new Date()
})

const date = ref(null);
const dialog = ref(false);

const save = () => {
  emit('save', date.value)
  dialog.value = false
}

const updateDate = (value) => {
  date.value = value
}

watch(props, (newValue, oldValue) => {
  if (newValue.dateValue) {
    date.value = newValue.dateValue
  }
})

defineExpose({
  dialog,
})
</script>