import axiosService from '@/tools/axios-service';
import Quiz from '../models/quiz';

class QuizApi {
  async get(id) {
    const res = await axiosService.get(`/api/v1/learn/quizzes/${id}`);

    return Quiz.fromJson(res.data);
  }
}

const instance = new QuizApi();

export default instance;
