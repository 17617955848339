<template>
  <svn-modal-skeleton
    ref="modalFaceToFace"
    fullscreen
    persistent
  >
    <template #activator-div>
      <div class="w-full flex sm:justify-end">
        <svn-pro-button
          v-if="learnModule?.submission?.status === 'in_progress'"
          variant="flat"
          class="w-full md:!w-fit"
          :text="$t('Continue evaluation')"
          @click="startEvaluation"
        />

        <svn-pro-button
          v-else-if="learnModule?.submission?.status === 'acquired'"
          variant="flat"
          class="w-full md:!w-fit"
          :text="$t('Restart evaluation')"
          @click="startEvaluation"
        />

        <svn-pro-button
          v-else
          variant="flat"
          class="w-full md:!w-fit"
          :text="$t('Start evaluation')"
          @click="startEvaluation"
        />
      </div>
    </template>

    <template #header="{ close }">
      <div class="w-full h-[72px] flex justify-end items-center gap-2 self-stretch px-4 sticky top-0 border-b border-b-middle-grey">
        <div class="flex justify-end items-center gap-2 flex-1">
          <svn-icon-button
            icon="material-symbols:download"
            variant="noOutlined"
            button-size="lg"
            @click="initPdfDownload"
          />

          <svn-icon-button
            icon="ic:baseline-close"
            variant="noOutlined"
            button-size="lg"
            @click="close"
          />
        </div>
      </div>
    </template>

    <template #content-body>
      <div class="w-full flex flex-col px-5 py-6 items-center mx-auto self-stretch md:max-w-[758px] md:py-8 mb-[200px]">
        <div class="flex flex-col items-start gap-6 self-stretch">
          <div class="flex flex-col items-start gap-2 self-stretch">
            <svn-title h1 semi-bold class="text-center self-center">
              {{ $t('Face-to-face evaluation') }}
            </svn-title>

            <svn-title h2 regular class="!text-center self-center">
              {{ learnModule?.title }}
            </svn-title>
          </div>

          <div class="flex flex-col items-center gap-8 self-stretch">
            <face-to-face-evaluation-question-card
              v-for="question in inputsFaceToFace"
              :key="question?.id"
              :question="question"
              @update-question-answer="updateQuestionAnswer(question, $event)"
            />

            <!-- Select your expert -->
            <div class="flex flex-col p-6 md:p-10 gap-4 items-start self-stretch border-[0.5px] border-middle-grey rounded-[8px]">
              <div class="w-full flex flex-col items-start gap-[10px] self-stretch">
                <svn-title h3 medium>
                  {{ $t('Select your expert') }}
                </svn-title>

                <svn-text sm regular color="dark-grey">
                  {{ $t('Please select the expert who will review your answers. After validation, they will receive a copy of this evaluation by email.') }}
                </svn-text>
              </div>

              <div>
                <svn-autocomplete
                  v-if="users"
                  v-model="learnModule.submission.expert"
                  avatar
                  :label="$t('My expert')"
                  :error="expertError.error"
                  :error-messages="expertError.errorMessage"
                  :hide-details="expertError.hideDetails"
                  :items="users"
                  @update:model-value="updateSelectedExpert"
                  @intersect="loadMoreUsers"
                  @input="searchExpert"
                  @clear="clearSearch"
                />
              </div>
            </div>

            <!-- Validate answers -->
            <svn-pro-button
              v-bind="props"
              variant="flat"
              class="w-fit"
              :loading="loading"
              :text="$t('Submit answers')"
              @click="validateEvaluation"
            />
          </div>
        </div>
      </div>
    </template>
  </svn-modal-skeleton>

  <dialog-validation-pro
    ref="dialogModuleAcquired"
    icon="noto:party-popper"
    :title="$t('Module acquired!')"
    :content-text="$t('Congratulations, keep it up! Your answers have been sent to', { user: firstname, expertFirstname: selectedExpert?.firstname, expertLastname: selectedExpert?.lastname })"
    :action-two-title="$t('Cancel')"
    :action-one-title="nextModuleId ? $t('Next module') : $t('Go to catalog')"
    @click-primary-button="handleSubmit('save')"
    @click-secondary-button="handleSubmit('cancel')"
    @click-outside="handleSubmit('cancel')"
  >
    <template #activator="{ props }">
      <div class="hidden opacity-0" />
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { useSnackbar } from "@/store/snackbar";
import { useMobileStore } from "@/store/mobile";
import { useActionCable } from "@/store/cable.js";
import { LearnInputType } from '@/constants/types';
import axiosService from "@/tools/axios-service.js";
import { useLearnModuleStore } from "@/store/learn-module";
import { watch, ref, onMounted, onBeforeUnmount } from 'vue';
import DialogValidationPro from '../../Dialogs/DialogValidationPro.vue';
import { downloadPdf, downloadPdfWithoutToast } from "@/tools/pdf-proxy-service.js";
import FaceToFaceEvaluationQuestionCard from '../../../learnApp/FaceToFaceEvaluationQuestionCard.vue';

const emit = defineEmits(['start-evaluation', 'open-modal-reaction']);

const props = defineProps({
  nextModuleId: { type: Number, default: null },
})

onMounted(async() => {
  try {
    await fetchInputsFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id);
    const data = await searchEmployess();
    users.value = data.users
    selectedExpertId.value = learnModule?.value?.submission?.expert?.id;

    currentPage.value = data.meta.pagination?.current_page;
    totalPages.value = data.meta.pagination?.total_pages;
  } catch (error) {
    console.log(error)
  }
})

const { cable } = storeToRefs(useActionCable());
const { firstname } = storeToRefs(useUserStore());
const { isMdScreen } = storeToRefs(useMobileStore());
const { learnModule, inputsFaceToFace, submission } = storeToRefs(useLearnModuleStore());

const snackbar = useSnackbar();
const { searchEmployess } = useUserStore();
const { fetchInputsFaceToFace, validateSubmissionFaceToFace } = useLearnModuleStore();

const users = ref([])
const currentPage = ref(1);
const totalPages = ref(null);
const dialogModuleAcquired = ref(null);
const expertError = ref({ error: false, errorMessage: '', hideDetails: true })
const selectedExpertId = ref(null);
const selectedExpert = ref({});
const inputChannel = ref(null);
const responseChannel = ref(null);
const modalFaceToFace = ref(null);
const loading = ref(false);

const startEvaluation = () => {
  emit('start-evaluation');
}

const initPdfDownload = async() => {
  const unscrollElement = document.getElementsByClassName("v-overlay-scroll-blocked")?.[0];

  if (unscrollElement) {
    unscrollElement.style.position = "static";
  }

  try {
    await downloadPdf(`/pdf/face_to_face_answer/${learnModule?.value?.id}/${learnModule?.value?.editor_content_id}`, `face_to_face_evaluation${learnModule?.value?.id}`)
  } catch (error) {
    console.log(error);
  }
}

const validateModule = async () => {
  try {
    let res = await downloadPdfWithoutToast(`/pdf/face_to_face_answer/${learnModule?.value?.id}/${learnModule?.value?.editor_content_id}`, `face_to_face_evaluation${learnModule?.value?.id}`);
    let pdfName = null

    if (import.meta.env.VITE_PDF_PROXY_URL) {
      let pdfProxyUrl = import.meta.env.VITE_PDF_PROXY_URL.replace('/pdf', '')  + '/'
      pdfProxyUrl = pdfProxyUrl.replace('http:', "https:")

      let pdfUrl = res?.href.replace('http:', "https:")
      pdfName = pdfUrl.replace(pdfProxyUrl, "")
    }

    let params = {
      learn_module_id: learnModule?.value?.id,
      pdf_name: pdfName,
    }

    await getExpertDetails();
    const { data } = await validateSubmissionFaceToFace(learnModule?.value?.learn_pieces_face_to_face_evaluation?.id, params);
    learnModule.value.submission.status = data?.status
    loading.value = false
  } catch (error) {
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').setMsg('An error occurred, please try again').displaySnackBar();
  }
}

const validateEvaluation = async() => {
  loading.value = true
  let firstNotAnsweredQuestion = inputsFaceToFace?.value?.find(el =>
    el.type !== LearnInputType.PARAGRAPH &&
    !learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === el?.id)?.text
  );

  if (!selectedExpertId?.value) {
    expertError.value = { error: true, errorMessage: 'Please select an expert to continue.', hideDetails: false }
  }
  if (firstNotAnsweredQuestion) {
    for (let question of inputsFaceToFace?.value) {
      if (!learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === question?.id)?.text) {
        question.error = true;
      }
    }
    document.getElementById(`question_${firstNotAnsweredQuestion?.id}`).scrollIntoView({ behavior: 'smooth' });

    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[88px]').setMsg('Please fill all the required (*) fields.').displaySnackBar();
  }

  if (selectedExpertId?.value && !firstNotAnsweredQuestion) {
    await validateModule();

    dialogModuleAcquired.value.dialogRef.dialog = true;
  }
  loading.value = false
}

const updateSelectedExpert = (expertId) => {
  if (!inputChannel.value) {
    websocketMethod()
  }
  inputChannel.value.update(expertId)
}

const updateQuestionAnswer = (data, answer) => {
  let question = inputsFaceToFace?.value?.find(el => el?.id === data?.id);

  if (question?.error && learnModule?.value?.submission?.learn_responses_answers?.find(input => input?.input_id === question?.id)?.text) {
    question.error = false;
  }
  if (!responseChannel.value) {
    websocketResponseChannel()
  }
  responseChannel.value.update(answer)
}

const websocketResponseChannel = () => {
  const subscribeOptions = {
    channel: "Learn::ResponseChannel", submission_id: submission?.value?.id
  }
  responseChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },
    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },
    received: function(data) {
      if (data.status === "update") {
        const index = submission?.value?.learn_responses_answers?.findIndex(x => x.id === data.entity.id);
        submission.value.learn_responses_answers[index].text = data.entity.text;
        learnModule.value.submission.learn_responses_answers[index].text = data.entity.text;
      }
    },
    update: async function(data) {
      responseChannel.value.perform('update', data);
      submission.value.status = "in_progress"
    },
  });
}

const loadMoreUsers = async() => {
  if (currentPage?.value < totalPages?.value) {
    currentPage.value++
    try {
      const data = await searchEmployess('', currentPage?.value)
      const loadMoreUsers = data.users
      users.value = [ ...users?.value, ...loadMoreUsers ]
    } catch (error) {
      console.log(error)
    }
  }
}

const handleSubmit = (action) => {
  modalFaceToFace.value.dialog = false

  emit('open-modal-reaction', action)
}

const getExpertDetails = async() => {
  if (selectedExpert?.value?.id !== selectedExpertId?.value) {
    try {
      const { data } = await axiosService.get(`/api/v1/users/${selectedExpertId?.value}`);
      selectedExpert.value = {};
      selectedExpert.value = data?.user;
    } catch (error) {
      console.log(error)
    }
  }
}

const searchExpert = debounce(async(search) => {
  try {
    const data = await searchEmployess(search)
    users.value = data.users
  } catch (error) {
    console.log(error)
  }
}, 300);

const clearSearch = debounce(async(e) => {
  try {
    const data = await searchEmployess('')
    users.value = data.users
  } catch (error) {
    console.log(error)
  }
}, 300);

const websocketMethod = () => {
  const subscribeOptions = {
    channel: "Learn::SubmissionChannel", submission_id: submission?.value?.id
  }
  inputChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
    connected: function() {
      // Called when the subscription is ready for use on the server
    },
    disconnected: function() {
      // Called when the subscription has been terminated by the server
    },
    received: function(data) {
      if (data.status === "update") {
        submission.value = JSON.parse(data.entity);
        learnModule.value.submission = JSON.parse(data.entity);
      }
    },
    update: async function(id) {
      let data = {
        expert_id: id,
      }
      inputChannel.value.perform('update', data);
      selectedExpertId.value = id;
    },
  });
}

onBeforeUnmount(() => {
  inputChannel?.value?.unsubscribe();
  responseChannel?.value?.unsubscribe();
});

watch(selectedExpertId, (newValue, oldValue) => {
  if (newValue && expertError?.value?.error) {
    expertError.value = { error: false, errorMessage: '', hideDetails: true };
  }
});

defineExpose({
  modalFaceToFace
})
</script>