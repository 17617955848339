<template>
  <div
    v-if="interviewForm"
    class="w-full flex flex-col gap-6"
  >
    <div
      class="break-words w-full description text-center"
      v-html="interviewForm?.description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="100%"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          interviewForm?.video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <component
      :is="question?.componentName"
      v-for="(question, idx) in questionsComponent"
      :key="idx"
      :loop-idx="idx"
      :question="question?.data"
    />
  </div>
</template>

<script>
import { useFeedbackStore } from "@/store/feedback";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import ChapterAnswer from "@/components/interviewApp/feedback_answers/ChapterAnswer.vue";
import OpenQuestionAnswer from "@/components/interviewApp/feedback_answers/OpenQuestionAnswer.vue";
import ParagraphAnswer from "../interview_answers/ParagraphAnswer.vue";
import RatingAnswer from "@/components/interviewApp/feedback_answers/RatingAnswer.vue";
import McqAnswer from "@/components/interviewApp/feedback_answers/McqAnswer.vue";
import { InterviewQuestionType } from '@/constants/types';

export default {
  components: {
    ChapterAnswer,
    ParagraphAnswer,
    OpenQuestionAnswer,
    RatingAnswer,
    McqAnswer,
  },
  setup() {
    const { questions, campaign, participant, interviewForm } = storeToRefs(useFeedbackStore());
    const isValidLink = computed(() => {
      return interviewForm.value.video && interviewForm.value.video.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return questions.value.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER)
          componentName = "chapter-answer";
        else if (question.type === InterviewQuestionType.OPEN)
          componentName = "open-question-answer";
        else if (question.type === InterviewQuestionType.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question.type === InterviewQuestionType.RATING)
          componentName = "rating-answer";
        else if (question.type === InterviewQuestionType.MCQ) componentName = "mcq-answer";

        return { data: question, componentName };
      });
    });

    return {
      questions,
      participant,
      campaign,
      questionsComponent,
      interviewForm,
      isValidLink
    };
  },
};
</script>