<template>
  <div class="w-full gap-6 flex flex-col max-w-[1136px] mx-auto my-[20px] md:my-6 overflow-hidden">
    <svn-pro-header-section
      :size="isMdScreen ? 'default' : 'compact'"
      :title="$t('Catalog')"
      :subtitle="$t('Here is all contents created in your company.')"
    />

    <div class="flex flex-col gap-4">
      <div class="flex justify-between  md:!mx-0 mx-5">
        <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
          {{ $t('Contents') }}
        </svn-pro-title>

        <div class="flex gap-2">
          <svn-pro-button
            v-if="isMdScreen"
            @click="openCreatePlaylist.dialog = true"
            variant="outlined"
            prepend-icon="custom:mingcute:add-line"
            :text="$t('New playlist')"
          />

          <svn-pro-button
            v-if="isMdScreen"
            @click="goToNewModule"
            variant="flat"
            prepend-icon="custom:mingcute:add-line"
            :text="$t('New module')"
          />

          <svn-pro-menu v-if="['module', 'playlist'].includes(tab)">
            <template #activator="{ props }">
              <svn-pro-icon-button
                v-bind="props"
                icon="custom:mingcute:more-2-fill"
                color="onSurfaceVariant"
                variant="text"
              />
            </template>

            <template #dropdown>
              <modal-view-deleted-items
                v-if="tab"
                ref="deletedItems"
                :items="tab === 'module' ? learnTrashModules : learnTrashPlaylists"
                :content-type="tab"
                @view-item="viewDeletedItem"
                @restore="restoreDeletedItems"
                @toggle-search="toggleSearchDeletedItems"
                @delete-permanently="deletePermanentlyDeletedItems"
              >
                <template #activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    :active="false"
                    value="view_deleted"
                  >
                    <svn-pro-text body-large regular>
                      {{ $t(`View deleted ${tab}s`) }}
                    </svn-pro-text>
                  </v-list-item>
                </template>
              </modal-view-deleted-items>
            </template>
          </svn-pro-menu>
        </div>
      </div>

      <div>
        <!-- Tabs -->
        <v-tabs
          v-model="tab"
          class="w-full md:w-fit"
          align-tabs="center"
        >
          <v-tab
            v-for="(tab_data, index) in tab_datas"
            :key="index"
            :value="tab_data.key"
            class="normal-case"
            :class="tab_datas.length === 2 ? 'w-1/2' : 'w-1/3'"
            color="trainingOrange"
            :grow="true"
          >
            {{ $t(tab_data.title) }}
          </v-tab>
        </v-tabs>

        <!-- Windows -->
        <v-window
          v-model="tab"
        >
          <!-- Modules tab -->
          <catalog-module :themes="learnThemes" />

          <!-- Playlists tab -->
          <catalog-playlist :themes="learnThemes" />

          <!-- Modules Drafts tab -->
          <catalog-module-draft :themes="learnThemes" />

        </v-window>
      </div>
    </div>
  </div>

  <!-- Scroll to top button -->
  <!-- <svn-pro-fab
    to-top
    size="small"
    color="primary"
    variant="tonal"
    :rounded="'lg'"
    class="fixed bottom-4 right-4 bg-white"
    icon="custom:mingcute:arrow-to-up-line"
  /> -->

  <!-- SpeedDial button -->
  <svn-floating-action-button
    :menu="true"
    icon="ic:baseline-plus"
  >
    <template #default>
      <div class="flex flex-col !items-end gap-4">
        <!-- New module button -->
        <div
          class="flex justify-center items-center gap-2 cursor-pointer"
          @click="goToNewModule"
        >
          <p class="text-white text-xl font-semibold textShadow">
            {{ $t('New module') }}
          </p>

          <div
            class="w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-full bg-trainingOrange"
          >
            <Icon
              icon="mdi:puzzle"
              height="24"
              width="24"
              class="text-white"
            />
          </div>
        </div>

        <!-- New playlist button -->
        <dialog-create-new-playlist
          title="Create new playlist"
          text-confirm="Validate"
        >
          <template #button>
            <div class="flex justify-center items-center gap-2 cursor-pointer">
              <p class="text-white text-xl font-semibold textShadow">
                {{ $t('New playlist') }}
              </p>

              <div
                class="w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-full bg-trainingOrange"
              >
                <Icon
                  icon="ic:outline-play-circle"
                  height="24"
                  width="24"
                  class="text-white"
                />
              </div>
            </div>
          </template>

          <template #close="{ close }">
            <div
              class="self-end"
              @click="close"
            >
              <Icon
                :icon="'material-symbols:close'"
                class="h-6 w-6 text-dark-grey hover:text-fake-black cursor-pointer"
              />
            </div>
          </template>
        </dialog-create-new-playlist>
      </div>
    </template>
  </svn-floating-action-button>

  <dialog-create-new-playlist
    ref="openCreatePlaylist"
    title="Create new playlist"
    text-confirm="Validate"
  >
    <template #activator="{ props }">
      {{ null }}
    </template>
  </dialog-create-new-playlist>

  <svn-pro-fab
    v-if="!isMdScreen"
    extended
    class="fixed bottom-4 right-4"
    variant="primary"
    :text="tab === 'playlist' ? $t('New Playlist') : $t('New Module')"
    prepend-icon="custom:mingcute:add-line"
    @click="tab === 'playlist' ? openCreatePlaylist.dialog = true : goToNewModule()"
  />

</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import CatalogModule from "./CatalogModule.vue";
import CatalogPlaylist from "./CatalogPlaylist.vue";
import CatalogModuleDraft from "./CatalogModuleDraft.vue";
import { useMobileStore } from "@/store/mobile";
import { useUserStore } from "@/store/user.js";
import { useRoute, useRouter } from "vue-router";
import { useLearnModuleStore } from "@/store/learn-module";
import { useSnackbar } from "@/store/snackbar.js";
import { useBreadcrumbsStore } from "@/store/breadcrumbs.js";
import { useLearnThemesStore } from "@/store/learn-themes.js";
import DialogCreateNewPlaylist from "@/components/BktPopUp/Dialogs/learn/DialogCreateNewPlaylist.vue";
import i18n from "@/plugins/i18n.js";
import imageTest1 from "@/assets/images/learnImages/img1.png";
import imageTest2 from "@/assets/images/learnImages/img2.png"
import imageTest3 from "@/assets/images/learnImages/img3.png"
import imageTest4 from "@/assets/images/learnImages/img4.png"
import imageTest5 from "@/assets/images/learnImages/img5.png"
import imageTest6 from "@/assets/images/learnImages/img6.png"
import imageTest7 from "@/assets/images/learnImages/img7.png"
import imageTest8 from "@/assets/images/learnImages/img8.png"
import imageTest9 from "@/assets/images/learnImages/img9.png"
import imageTest10 from "@/assets/images/learnImages/img10.png"
import imageTest11 from "@/assets/images/learnImages/img11.png"
import imageTest12 from "@/assets/images/learnImages/img12.png"
import imageTest13 from "@/assets/images/learnImages/img13.png"
import imageTest14 from "@/assets/images/learnImages/img14.png"
import imageTest15 from "@/assets/images/learnImages/img15.png"
import ModalViewDeletedItems from '@/components/BktPopUp/Dialogs/learn/ModalViewDeletedItems.vue';
import { debounce } from 'lodash';
import { useLearnPlaylistStore } from "@/store/learn-playlist.js";

onMounted(async() => {
  window.addEventListener("scroll", alignFunc);

  try {
    await fetchThemes()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching themes')
    snackbar.displaySnackBar()
  }
  try {
    await fetchTrashPlaylists()
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error fetching trash playlists')
    snackbar.displaySnackBar()
  }
  try {
    await fetchTrashModules()
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trash modules')
    snackbar.displaySnackBar()
  }
})

const router = useRouter()

const alignTop = ref(false)
const openCreatePlaylist = ref(false)

const tab = ref(null);

const route = useRoute()

const imagesList = ref([
  imageTest1,
  imageTest2,
  imageTest3,
  imageTest4,
  imageTest5,
  imageTest6,
  imageTest7,
  imageTest8,
  imageTest9,
  imageTest10,
  imageTest11,
  imageTest12,
  imageTest13,
  imageTest14,
  imageTest15,
])

const snackbar = useSnackbar();

const { fetchThemes } = useLearnThemesStore();

const { createModule, updateModuleImage, fetchTrashModules, restoreModule, deleteModulePermanently } = useLearnModuleStore()
const { restorePlaylist, deletePlaylistPermanently, fetchTrashPlaylists } = useLearnPlaylistStore();

const { learnTrashModules } = storeToRefs(useLearnModuleStore());
const { learnTrashPlaylists } = storeToRefs(useLearnPlaylistStore());

const { isManagerCreatorOrAbove } = useUserStore();

const breadcrumbsStore = useBreadcrumbsStore();

const { reset, addNode, update } = breadcrumbsStore

const { isMobile } = storeToRefs(useMobileStore())

const { learnThemes } = storeToRefs(useLearnThemesStore());

const { isMdScreen } = storeToRefs(useMobileStore());

const tab_datas = computed(() => {
  if (isManagerCreatorOrAbove()) {
    return [
      {
        id: 0,
        title: "Modules",
        key: "module",
      },
      {
        id: 1,
        title: "Playlists",
        key: "playlist",
      },
      {
        id: 2,
        title: "Drafts",
        key: "draft",
        noContentText: "There are no drafts. Create your first module !",
      },
    ]
  }
  else {
    return [
      {
        id: 0,
        title: "Modules",
        key: "module",
        noContentText: "There are no modules for the moment, come later to see what’s new !",
      },
      {
        id: 1,
        title: "Playlists",
        key: "playlist",
        noContentText: "There are no playlists. Create your first playlist !",
      },
    ]
  }
})

const goToNewModule = async() => {
  // get random index value
  const randomIndex = Math.floor(Math.random() * imagesList.value.length);

  // get random Image
  const randomImage = imagesList.value[randomIndex];

  try {
    let module = await createModule("Module title", "30 minutes")

    loadXHR(randomImage).then(async(blob) => {
      updateModuleImage(module?.data?.id, blob)
    })

    router.push({ name: "module_edit", params: { id: module?.data?.id } })
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error creating module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  }
}

const loadXHR = (url) => {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
          if (xhr.status === 200) {resolve(xhr.response)}
          else {reject("Loading error:" + xhr.statusText)}
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
}

const alignFunc = () => {
  alignTop.value = (document.body.scrollTop > window.innerHeight / 4 ||
  document.documentElement.scrollTop > window.innerHeight / 4)
}

const toggleSearchDeletedItems = debounce(async (value) => {
  try {
    await fetchTrashModules(value)
  } catch (e) {
    console.error(e)
  }
}, 300)

const restoreDeletedItems = async (id) => {
  try {
    if (tab.value === 'module') {
      await restoreModule(id)
    } else {
      await restorePlaylist(id)
    }
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(`${tab.value === 'module' ? 'Module' : 'Playlist'} restored successfully, ${tab.value} has been moved to draft`)
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg(`Error while restoring a ${tab.value}`)
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  }
}

const deletePermanentlyDeletedItems = async (id) => {
  try {
    if (tab.value === 'module') {
      await deleteModulePermanently(id, false)
    } else {
      await deletePlaylistPermanently(id)
    }
    snackbar.setBgColor('onSurface')
    snackbar.setMsg(`${tab.value === 'module' ? 'Module' : 'Playlist'} deleted successfully`)
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg(`Error while deleting permanently a ${tab.value}`)
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  }
}

const viewDeletedItem = async (id) => {
  try {
    router.push({ name: tab.value == 'module' ? 'module_show' : 'playlist_show', params: { id: id } })
  } catch (e) {
    snackbar.setBgColor('error')
    snackbar.setMsg('Error while showing deleted module')
    snackbar.setCustomClass(isMobile.value ? 'mb-[96px]' : 'mb-[24px]')
    snackbar.displaySnackBar()
  }
}
</script>

<style scoped>
.input :deep(.v-input__control) {
  border-radius: 4px;
}

.textShadow {
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (min-width: 768px) {
  :deep(.v-window-item) {
    transition: 0.8s cubic-bezier(.24, .2, .56, 1) !important;
  }
}
</style>
