<template>
  <svn-pro-modal
    ref="moduleReactionsModal"
    :sticky-modal="!isMdScreen"
    :action-one-title="$t('Continue')"
    :width="isMdScreen ? 600 : undefined"
    :title="$t('What did you think of the module?')"
    @click-close="clickClose"
    @click-outside="clickOutside"
    @click-primary-button="clickPrimaryButton"
    @click-secondary-button="clickSecondaryButton"
  >
    <template #activator="{ props }">
      <slot name="activator" :props="props" />
    </template>

    <template #text>
      <div class="w-full flex flex-col gap-6">
        <svn-pro-text
          regular
          subtitle-large
          color="onSurfaceVariant"
        >
          {{ $t('Your feedback helps us improve this module.') }}
        </svn-pro-text>

        <svn-pro-card-reaction
          :reactions="reactions"
          @toggle-reaction="toggleReaction"
        />
      </div>
    </template>
  </svn-pro-modal>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const props = defineProps({
  reactions: {
    type: Array,
    default: () => [],
  },
})

const { isMdScreen } = storeToRefs(useMobileStore());

const emit = defineEmits('click-primary-button', 'click-secondary-button', 'click-outside', 'update-reaction');

const moduleReactionsModal = ref(null);
const reactionKeys = ref({
  "like": "Reaction::Like",
  "recommend": "Reaction::Recommend"
});

const toggleReaction = (reactionType) => {
  emit('update-reaction', reactionKeys?.value?.[reactionType]);
}

const clickClose = () => {
  emit('click-close')
}

const clickOutside = () => {
  emit('click-outside')
}

const clickPrimaryButton = () => {
  emit('click-primary-button')
}

const clickSecondaryButton = () => {
  emit('click-secondary-button')
}

defineExpose({
  moduleReactionsModal
})
</script>