<template>
  <div
    class="w-full flex gap-2"
    :class="side ? '' : ' self-start'"
  >
    <svn-pro-avatar
      v-if="side && user"
      :size="32"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      :image="user?.avatar?.['50']"
    />

    <div class="w-full  flex flex-col gap-2">
      <!-- user name -->
      <svn-pro-text
        v-if="!crossed"
        body-large
        medium
        :class="side ? '' : 'self-end'"
      >
        {{ user?.fullname }}
      </svn-pro-text>

      <div
        class="rounded-lg p-4 flex flex-col gap-2"
        :class="[isLastInterviewCompletedAndLocked ? 'bg-surfaceVariant' : 'bg-surfaceLight']"
      >
        <v-rating
          v-if="selectedValue && questionType === 'InterviewApp::Questions::Rating'"
          :disabled="true"
          :length="ratingLength"
          :model-value="selectedValue"
          empty-icon="custom:mingcute:star-line"
          full-icon="custom:mingcute:star-fill"
          color="onSurface"
          size="small"
          active-color="onSurface"
        />

        <svn-pro-info-tag
          v-else-if="selectedValue"
          tag-type="primary"
          :text="selectedValue"
          tag-style="outlined"
          class="w-fit h-fit"
          :class="[side ? '' : 'self-end']"
        />

        <!-- Comments input -->
        <quill-editor
          v-if="commentsRef"
          v-model:content="commentsRef"
          content-type="html"
          :placeholder="' '"
          :read-only="true"
          :class="side ? '' : 'input !self-end'"
          class="mt-2 text-fake-black text-sm font-normal leading-5 description  break-words w-full"
        />
      </div>
    </div>

    <svn-avatar
      v-if="!side && user"
      :size="32"
      :firstname="user?.firstname"
      :lastname="user?.lastname"
      :image="user?.avatar?.['50']"
    />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from "vue";

const props = defineProps({
  isLastInterviewCompletedAndLocked: { type: Boolean, default: false },
  crossed: { type: Boolean, default: false },
  user: { type: Object, required: true },
  answer: { type: String, required: true },
  comments: { type: String, required: false },
  questionType: { type: String, required: false },
  ratingLength: { type: String, required: false },
  side: { type: Boolean, default: true },
})

onMounted(() => {
  useCommentForAnswers()
})

const commentsRef = ref(null);

const selectedValue = computed(() => {
  if (props.questionType === 'InterviewApp::Questions::Open') return null
  if (props.questionType === 'percentage') {
    return props.answer ? `${props.answer}%` : props.answer
  } else {
    return props.answer
  }
})

const useCommentForAnswers = () => {
  if (props.questionType === 'InterviewApp::Questions::Open') {
    commentsRef.value = props?.answer
  } else {
    commentsRef.value = props?.comments
  }
}

watch(props, (newValue, oldValue) => {
  if (newValue) {
    useCommentForAnswers()
  }
});
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}

:deep(.input .ql-editor p) {
  text-align: end !important;
}
</style>