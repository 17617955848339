<template>
  <v-menu :close-on-content-click="false">
    <template #activator="{ props }">
      <svn-pro-icon-button
        v-bind="props"
        :icon="impersonateAdminUser ? 'custom:mingcute:user-visible-fill' : 'custom:mingcute:user-visible-line'"
        color="onSurfaceVariant"
        variant="text"
      />
    </template>

    <div class="w-[280px] md:!w-[300px] p-4 flex flex-col gap-2 bg-surface rounded-[16px] menuShadow">
      <svn-pro-text body-large bold>
        {{ 'Impersonnate:' }}
      </svn-pro-text>

      <svn-pro-autocomplete
        v-model="selectedUser"
        v-model:search="searchText"
        :autofocus="false"
        variant="outlined"
        clearable
        color="primary"
        hide-details
        :list-props="{ color: 'primary' }"
        clear-icon="mdi-close"
        :menu-props="{ maxHeight: 208 }"
        :item-title="(item) => { return `${item?.fullname}`}"
        item-value="id"
        :items="usersList"
        :label="$t('User')"
        @input="searchUserList"
        @update:model-value="updateImpersonateUser"
        @click:clear="logoutImpersonateUser"
      />
    </div>
  </v-menu>
</template>

<script setup>
import { debounce } from "lodash";
import { storeToRefs } from "pinia";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/user.js";
import { useSnackbar } from "@/store/snackbar.js";
import { useImpersonateStore } from "@/store/impersonate.js";

const { selectedUser, impersonateAdminUser } = storeToRefs(useImpersonateStore());

const router = useRouter();
const snackbar = useSnackbar();
const { superAdminSearchUser } = useUserStore();
const { impersonateUser, impersonateUserLogout, setSelectedUser } = useImpersonateStore();

const usersList = ref([]);
const searchText = ref(null);

const logoutImpersonateUser = async () => {
  if (impersonateAdminUser?.value) {
    impersonateUserLogout();
    router.go();
  }
}

onMounted(async () => {
  try {
    usersList.value = await superAdminSearchUser();
  } catch (error) {
    console.log(error)
  }
})

const updateImpersonateUser = async (userId) => {
  if (userId) {
    try {
      const user = usersList.value.find(element => element.id === userId)
      await setSelectedUser(user)
  
      await impersonateUser(user)
      router.go()
    } catch (error) {
      snackbar.setBgColor('error')
      snackbar.setMsg('Error while impersonating')
      snackbar.displaySnackBar()
    }
  }
}

const searchUserList = debounce(async (value) => {
  usersList.value = await superAdminSearchUser(value)
}, 200)
</script>

<style scoped>
.menuShadow {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.20);
}
</style>