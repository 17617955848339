<template>
  <div class="flex flex-col w-full h-full bg-surface">
    <div class="flex flex-col w-full lg:!max-w-[928px] xl:!max-w-[1136px] p-5 2xl:pt-10 mx-auto h-full relative gap-6">
      <div class="flex flex-col gap-4 justify-center sm:items-start">
        <svn-pro-header-section
          emoji="noto:waving-hand"
          :size="isMobile ? 'compact' : 'default'"
          :title="$t('Hello,', { firstname: firstname })"
          :interview="applications?.includes('interviews')"
          :training="applications?.includes('trainings')"
          :roadmap="applications?.includes('objectives')"
          :interviews-submitted="interviews_submitted > 1 ? $t('interviews_submitted_count', { count: interviews_submitted }) : $t('interviews_submitted_no_count', { count: interviews_submitted })"
          :modules-acquired="modules_acquired > 1 ? $t('modules_acquired_count', { count: modules_acquired }) : $t('modules_acquired_no_count', { count: modules_acquired })"
          :current-targets="current_targets > 1 ? $t('current_targets_count', { count: current_targets }) : $t('current_targets_no_count', { count: current_targets })"
        />
      </div>

      <div class="w-full h-full flex flex-col gap-6 pb-16">
        <div class="flex gap-4 h-[128px] lg:h-[230px] desktop:h-[272px] w-full">
          <div
            :style="{'background-image': `url('${homeBanner?.['1000']}')`, 'background-size': 'cover'} "
            class="w-full h-[128px] lg:h-[230px] desktop:h-[272px] p-10 bg-neutral-100 !rounded-xl bg-center flex items-center justify-center relative overflow-hidden"
          />
        </div>
  
        <div class="flex flex-col md:flex-row gap-4 justify-center">
          <svn-pro-card-homepage
            v-for="(app, idx) in applicationsFiltered"
            :key="idx"
            :app-label="app?.beta ? 'Beta' : undefined"
            :app-icon="app?.icon"
            :app-name="app?.displayName"
            :app-description="app?.text"
            app-color="primary"
            @action-clicked="router.push(app?.linkName)"
          />
        </div>

        <information-card
          v-if="links?.length"
          :company-id="id"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/store/company.js";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user.js";
import { computed } from "vue";
import InformationCard from "@/components/home/InformationCard.vue";
import { useMobileStore } from "../store/mobile"
import { useRouter } from 'vue-router'

const router = useRouter()
const { isMobile } = storeToRefs(useMobileStore());
const { applications, homeBanner, id, links } = storeToRefs(useCompanyStore());
const { firstname, interviews_submitted, modules_acquired, current_targets } = storeToRefs(useUserStore());

const appsConfigs = [
  {
    name: "interviews",
    displayName: "Interview",
    icon: "mingcute:chat-3-line",
    text: "Annual reviews, onboardings, discovery reports and many more are all processed here.",
    subText: "Go to Interview",
    color: "#3177B7",
    linkName: "/v2/interviews/my_interviews",
  },
  {
    name: "trainings",
    displayName: "Learn",
    icon: "mingcute:mortarboard-line",
    text: "Professional trainings, skill upgrades, and all kinds of contents in a single app.",
    subText: "Go to learn",
    color: "#EF8C64",
    linkName: "/learns/my_learnings",
    beta: true,
  },
  {
    name: "objectives",
    displayName: "Roadmap",
    icon: "mingcute:target-line",
    text: "View, create and update all your targets and team targets. Follow their completion here.",
    subText: "Go to Roadmap",
    color: "#5C95FF",
    linkName: "/roadmaps/my_roadmaps",
  },
];

const applicationsFiltered = computed(() => {
  return appsConfigs.filter((app) => applications.value.includes(app.name));
});
</script>