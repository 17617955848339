<template>
  <div
    ref="tagContainer"
    class="bkt-tag-container flex-row-start-centered "
  >
    <div
    class="flex gap-2"
    >
    <svn-pro-info-tag
        v-for="tag in displayTags()"
        :key="tag.id"
        tag-type="info"
        :text="tag.title"
        class="w-fit h-fit"
        tag-size="compact"
        />
    </div>
    <svn-pro-info-tag
      v-if="remainingTags > 0"
      tag-type="info"
      :text="'+' + remainingTags"
      class="w-fit h-fit ml-2"
      tag-size="compact"
    />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue'
  import BktTag from "../components/BktTag.vue";

  const props = defineProps(
    {
      tags: { type: Array, default: () => [] }
    }
  )

  const maxLen = ref(20)

  const displayTags = () => {
    let len = 0
    return props.tags.filter(tag => {
      len += tag.title.length
      return (len < maxLen.value)
    })
  }
  const remainingTags = computed(() => {
    return props.tags.length - displayTags().length
  })
</script>
