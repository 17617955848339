<template>
  <svn-pro-card-learn
    :state="submissionStatus"
    card-type="module"
    :card-image="backgroundImage"
    :cardTitle="title"
    :duration="duration"
    :isFavorited="isFavorite"
    :reactionCount="reationCount"
    :reactionList="reationTypes"
    :shareable="true"
    :editable="editable"
    :favoriteIcon="isFavorite ? 'custom:mingcute:heart-fill' : 'custom:mingcute:heart-line'"
    shareableIcon="custom:mingcute:link-line"
    :isAcquired="submissionStatus == 'acquired'"
    @on-favorite="toggleFavorite"
    @on-share="copyModuleLink"
    @go-to-item-show="$emit('go-to-item-show')"
    :themes="themeList"
    editableIcon
  >
    <template v-if="editable" #menu>
      <svn-pro-menu>
        <template #activator>
          <svn-pro-icon-button
            icon="custom:mingcute:more-2-line"
            variant="elevated"
            color="surface"
          />
        </template>

        <template #dropdown>
          <v-list-item
            value="edit_module"
            :active="false"
            @click="duplicateModule"
          >
            {{ $t('Duplicate module') }}
          </v-list-item>

          <v-list-item
            value="delete_module"
            :active="false"
            v-if="learnModule.status !==  'draft'"
            @click="addModuleToPlaylist"
          >
            {{ $t('Add to playlist') }}
          </v-list-item>

          <v-list-item
            value="delete_module"
            :active="false"
            @click="openDialogDeleteModude"
            class="text-error"
          >
            {{ $t('Delete module') }}
          </v-list-item>
        </template>
      </svn-pro-menu>
    </template>
  </svn-pro-card-learn>

  <!-- Dialog edit Playlist -->
  <dialog-delete-content
    v-if="learnModule?.learn_trainings?.length"
    ref="deleteModuleDialog"
    :items="learnModule?.learn_trainings"
    :title="$t(`Module will be deleted with their trainings`)"
    :description="$t(`If this module is the only content of a training, the training will be deleted. Training(s) containing only this module :`)"
    @delete-content="deleteModule"
  />

  <dialog-validation-pro
    v-else
    ref="deleteModuleDialog"
    icon="noto:warning"F
    :action-two-title="$t('Cancel')"
    :action-one-title="$t('Delete')"
    :title="$t(`Module will be deleted`)"
    :content-text="learnModule.status === 'draft' ? $t('This is a permanent action.') : $t('Deleted modules are stored for 30 days. After this period, they will be permanently deleted.')"
    @click-primary-button="deleteModule"
  >
    <template #activator="{ props }">
      <div class="hidden"/>
    </template>
  </dialog-validation-pro>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar";
import { useUserStore } from "@/store/user.js";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import filters from "@/tools/filters";
import DialogValidationPro from "@/components/BktPopUp/Dialogs/DialogValidationPro.vue";
import DialogDeleteContent from '@/components/BktPopUp/Dialogs/learn/DialogDeleteContent.vue';

const { isMobile } = storeToRefs(useMobileStore());
const deleteModuleDialog = ref(false)

const { amICreatorOfEntityOrAdmin } = useUserStore();

const props = defineProps({
  id: { type: Number, default: null },
  learnModule: { type: Object, default: {} },
  moduleCreatorId: { type: Number, default: null },
  backgroundImage: { type: String, default: '' },
  isFavorite: { type: Boolean, default: false },
  title: { type: String, default: '' },
  toDiscover: { type: Boolean, default: false },
  isDraft: { type: Boolean, default: false },
  editable: { type: Boolean, default: false },
  link: { type: String, default: '' },
  themeList: { type: Array, default: null },
  reactions: { type: Object, default: null },
  duration: { type: String, default: '' },
  updatedAt: { type: String, default: '' },
  submissionStatus: { type: String, default: '' },
  submissionUpdatedAt: { type: String, default: '' },
})

const slicedThemesList = computed(() => {
  if (isMobile.value) {
    return props?.themeList?.slice(0, 1)
  }
  else {
    return props?.themeList?.slice(0, 2)
  }
})


const reationCount = computed(() => {
  let count = 0
  filteredReations.value.forEach((r) => {
    if (r.count) {
      count = count + r.count
    }
  })

  return count
})

const reationTypes = computed(() => {
  let types = []
  filteredReations.value.forEach((r) => {
    if (r.count && !types.includes(r.type)) {
      types.push(r.type)
    }
  })

  return types
})

const filteredReations = computed(() => {
  return props.reactions?.filter(r => r.type === 'like' || r.type === 'recommend' )
})
const router = useRouter()

const emit = defineEmits(['duplicate-module', 'toggle-favorite', 'add-to-playlist', 'delete-module', 'go-to-item-show'])

const snackbar = useSnackbar()

const copyModuleLink = () => {
  const link = router.resolve({  name: 'module_show', params: { id: props?.id } })

  navigator.clipboard.writeText(new URL(link.href, window.location.origin).href).then(() => {
    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-[16px]')
    snackbar.setMsg('Link copied to clipboard.')
    snackbar.displaySnackBar()
  }).catch(() => {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : '')
    snackbar.displaySnackBar('Error copying link to clipboard')
  })
}

const openDialogDeleteModude = () => {
  if (props.learnModule?.learn_trainings?.length) {
    deleteModuleDialog.value.deleteDialog = true
  } else {
    deleteModuleDialog.value.dialogRef.dialog = true
  }
}

const toggleFavorite = () => {
  emit('toggle-favorite')
}

const duplicateModule = () => {
  emit('duplicate-module')
}

const addModuleToPlaylist = () => {
  emit('add-to-playlist')
}

const getReactionsIcons = (reaction) => {
  if (reaction?.type === 'like') {
    return 'noto:thumbs-up'
  }
  if (reaction?.type === 'recommend') {
    return 'noto:star-struck'
  }
  if (reaction?.type === 'question') {
    return 'noto:face-with-raised-eyebrow'
  }
  if (reaction?.type === 'remark') {
    return 'noto:index-pointing-up'
  }
}

const getTotalReactionsCount = () => {
  let totalReactions = 0;
  props?.reactions?.forEach(reaction => {
    totalReactions += reaction?.count
  })
  return totalReactions
}

const deleteModule = () => {
  emit('delete-module', props.id)
}
</script>

<style scoped>
</style>
