<template>
  <div
    :id="question.id"
  >
    <div
      id="roadmapBlock"
      class="flex flex-col gap-2 pb-6"
    >
      <svn-pro-title h6 bold>
        {{ question.text }}
      </svn-pro-title>

      <svn-pro-divider v-if="question.text && question?.description" class="w-full border-[#767680]"/>

      <div
        v-if="question?.description"
        class="text-dark-grey text-sm font-normal leading-5 description break-words w-full"
        v-html="question.description"
      />
    </div>

    <div
      v-if="snapshotsCreated?.length"
      class="w-full flex flex-col gap-2 py-6 gap-6"
    >
      <roadmap-block-target
        v-for="(draftTarget, idx) in snapshotsCreated"
        :key="idx"
        :target="draftTarget"
        :question="question"
        :is-draft="true"
        :employee="employee"
        @changed="fetchUpdatedTarget"
      />
    </div>

    <svn-pro-card
      v-if="!locked"
      :link="false"
      class="p-8 flex flex-col items-center justify-center gap-4"
    >
      <div class="flex items-center gap-2 justify-center">
        <v-icon
          color="primary"
          icon="custom:mingcute:target-line"
          size="24"
          theme="roadmap"
        />
        <svn-pro-title h6 bold>
          {{ $t('New targets') }}
        </svn-pro-title>
      </div>

      <svn-pro-text body-medium regular class="line-clamp-1">
        {{ $t('Targets added will be create and add to Roadmap when the Cross Review will be submitted.') }}
      </svn-pro-text>

      <modal-create-new-target
        ref="refModalCreateNewTarget"
        :users-from-overlay="[employee]"
        :overlay-mode="true"
        :create-draft="true"
        :question="question"
        :interview-id="id"
        :campaign-id="campaignId"
        from="interview_answer"
        >
        <template #activator="{ props }">
          <svn-pro-button
            v-bind="props"
            variant="flat"
            prepend-icon="custom:mingcute:add-line"
            :text="$t('Create new target')"
            class="hidden md:flex"
          />
        </template>
      </modal-create-new-target>
    </svn-pro-card>

    <div
      v-else
      class="flex flex-col justify-center items-center mx-auto my-10"
    >
      <p class="text-darkGrey text-sm font-normal">
        {{ $t('This interview is already submitted, so you cannot add any new targets for the moment.') }}
      </p>
    </div>
  </div>
</template>

<script setup>
import {storeToRefs} from "pinia";
import RoadmapBlockTarget from "./CreateRoadmapBlockCard.vue";
import { useInterviewStore } from "@/store/interview.js";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";
import { ref } from 'vue'
import ModalCreateNewTarget from '../../BktPopUp/Modals/ModalCreateNewTarget.vue';

const {
  id,
  locked,
  employee,
  campaignId,
} = storeToRefs(useInterviewStore())
const refModalCreateNewTarget = ref(null)

const { fetchCreatedSnapshots } = useTargetSuggestionsStore();

const { snapshotsCreated } = storeToRefs(useTargetSuggestionsStore());

const props = defineProps(['question'])

const fetchUpdatedTarget = (newTargetData) => {
  if (newTargetData) {
    fetchDraftTargets()
  }
}

const fetchDraftTargets = async () => {
  try {
    await fetchCreatedSnapshots(employee?.value?.id, id?.value)
  } catch (e) {
    console.log("couldn't get draft targets", e)
  }
}
fetchDraftTargets()
</script>
