<template>
  <div class="mt-4 flex items-center flex-col">
    <div :class="isMobile ? 'flex-col gap-2 justify-center ' : 'justify-between'" class="flex items-center mb-4 w-full px-5">
      <span class="text-onSurfaceVariant-500 text-center">
        {{ templateType != 'Survey' ?
          $t("Participant’s firstname interview with Person in charge fullname") :
          $t('Survey - x participants') }}
      </span>

      <span  :class="isMobile ? 'self-center' : 'self-end'"  class="text-right text-onSurfaceVariant-500">
        {{ $t("Deadline : dd/mm/yyyy") }}
      </span>
    </div>

    <div
      v-if="description"
      class="mt-8 p-9 bkt-unwrap bkt-v-html-format max-w-[752px]"
      v-html="description"
    />

    <div
      v-if="isValidLink"
      class="p-4 mt-4 md:w-full mx-auto max-w-[720px]"
    >
      <iframe
        id="ytplayer"
        class="mx-auto"
        type="text/html"
        width="100%"
        height="360"
        :src="
          'https://www.youtube.com/embed/' +
            video?.match(
              /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
            )[1]
        "
      />
    </div>

    <div class="max-w-[752px] mb-4 w-full px-5">
      <component
        :is="question.componentName"
        :id="idx"
        v-for="(question, idx) in questionsComponent"
        :key="idx"
        :question="question.data"
        :loop-idx="idx"
        class="my-8"
      />
    </div>
  </div>
</template>

<script>
import { useInterviewStore } from "@/store/interview.js";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Chapter from "@/components/interviewApp/interview_answers/ChapterAnswer.vue";
import OpenQuestion from "@/components/interviewApp/template/Show/OpenQuestion.vue";
import Rating from "@/components/interviewApp/template/Show/Rating.vue";
import Mcq from "@/components/interviewApp/template/Show/Mcq.vue";
import ParagraphAnswer from "@/components/interviewApp/interview_answers/ParagraphAnswer.vue";
import RoadmapBlock from "@/components/interviewApp/interview_answers/RoadmapBlock.vue";
import { InterviewQuestionType } from '@/constants/types';
import { useMobileStore } from "@/store/mobile";

export default {
  components: {
    Chapter,
    OpenQuestion,
    Rating,
    Mcq,
    RoadmapBlock,
    ParagraphAnswer
  },
  props: ["interviewQuestions", "title", "description", "video", "templateType"],
  setup(props) {
    const { interviewForm } = storeToRefs(useInterviewStore());
    const { isMobile } = storeToRefs(useMobileStore());

    const isValidLink = computed(() => {
      return props.video && props.video.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return props.interviewQuestions.map((question) => {
        let componentName = null;

        if (question.type === InterviewQuestionType.CHAPTER) componentName = "chapter";
        else if (question.type === InterviewQuestionType.OPEN)
          componentName = "open-question";
        else if (question.type === InterviewQuestionType.RATING) componentName = "rating";
        else if (question.type === InterviewQuestionType.MCQ) componentName = "mcq";
        else if (question.type === InterviewQuestionType.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question.type === InterviewQuestionType.UPDATE_ROADMAP)
          componentName = "roadmap-block";
        else if (question.type === InterviewQuestionType.CREATE_ROADMAP)
          componentName = "roadmap-block";

        return { data: question, componentName };
      });
    });

    return {
      interviewForm,
      questionsComponent,
      isValidLink,
      isMobile
    };
  },
};
</script>
