<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <template #activator="{ props }">
      <svn-pro-icon-button
        v-if="isMobile"
        color="onSurfaceVariant"
        variant="text"
        v-bind="props"
        :icon="'custom:mingcute:eye-2-line'"
        @click="fetchInterviewForm(route.params.id)"
        :disabled="isDeleted"

      />
      <svn-pro-button
      v-else
        variant="outlined"
        v-bind="props"
        :disabled="isDeleted"
        :prepend-icon="'custom:mingcute:eye-2-line'"
        :text="$t('see template preview')"
        class="pl-[16px] pr-[24px]"
        @click="fetchInterviewForm(route.params.id)"
      />
    </template>
    <v-card class="!bg-surfaceBright">
      <v-layout
        full-height
        style="position: fixed; width: 100%;"
      >
        <v-app-bar
          color="surface"
          elevation="0"
          class="border-b !border-surfaceBright border-opacity-100"
          prominent
        >
          <svn-pro-title
            v-if="!isMobile"
            h6
            medium
            color="onSurface"
            class="flex text-center justify-center w-full"
          >
            {{ title }}
          </svn-pro-title>

          <span v-else class="flex text-center justify-center w-full"></span>

          <v-app-bar-nav-icon
            variant="text"
            :color="drawer ? 'primary' : 'onSurfaceVariant'"
            @click.stop="drawer = !drawer"
            icon="custom:mingcute:list-ordered-line"
          />

          <v-app-bar-nav-icon
            variant="text"
            color="onSurfaceVariant"
            @click="dialog = false"
            icon="custom:mingcute:close-line"
          />
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          width="320"
          disable-resize-watcher
          location="right"
          class="!h-auto"
        >
          <div class="w-full break-words">
            <div class="w-full text-start font-semibold text-gray-300 text-2xl ">
              <div class="flex justify-between items-center  pt-3 pr-3 pl-6">
                <svn-pro-title h5 large color="onSurfaceVariant">
                  {{ $t( 'Chapters' ) }}
                </svn-pro-title>

                <div class="cursor-pointer">
                  <svn-pro-icon-button
                    color="onSurfaceVariant"
                    variant="text"
                    @click.stop="drawer = !drawer"
                    icon="custom:mingcute:close-line"
                  >
                  </svn-pro-icon-button>
                </div>
              </div>
            </div>

            <div
              v-for="(item, i) in headlines"
              :key="i"
              class="w-full my-2"
              :class="i === headlines.length - 1 ? '!pb-[200px]' : ''"
            >
              <div
                v-if="item.type === InterviewQuestionType.CHAPTER"
                class="uppercase font-semibold break-words px-4 pb-2"
              >
                <svn-pro-text body-large bold color="onSurface">
                  {{ item.title }}
                </svn-pro-text>
              </div>

                <svn-pro-list-item
                  v-else-if="item.type !== InterviewQuestionType.PARAGRAPH"
                  :title="item.title "
                  class="uppercase font-semibold break-words px-4 pb-2"
                  :leading-icon="item.icon"
                  leading-color="onSurfaceVariant"
                  leading="icon"
                  @click="scrollToElement(i)"
                />
            </div>
          </div>
        </v-navigation-drawer>

        <v-main
          class="overflow-y-auto flex justify-center items-start bg-neutral-100"
        >
          <div class="!w-[1280px]">
            <interview-template-show-list
              :title="title"
              :description="description"
              :interview-questions="interviewQuestions"
              :video="video"
              :template-type="templateType"
            />
          </div>
        </v-main>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {computed, ref, watch } from "vue";
import {useInterviewFormStore} from "@/store/interview-form.js";
import {useRoute} from "vue-router";
import {storeToRefs} from "pinia";
import useTools from "@/tools/useTools.js";
import InterviewTemplateShowList from "@/components/interviewApp/template/Show/InterviewTemplateShowList.vue";
import {useMobileStore} from "@/store/mobile.js";
import {Icon} from "@iconify/vue";
import { InterviewQuestionType } from '@/constants/types';


const props = defineProps({
  isDeleted: { type: Boolean, required: true }
});

const dialog = ref(false)
const {isLargeScreen, isMobile} = storeToRefs(useMobileStore())

const route = useRoute()
const { fetchInterviewForm } = useInterviewFormStore()
const { deduceMatIconFromInterviewQuestionType } = useTools()
const { title, description, interviewQuestions, id, video, templateType } = storeToRefs(useInterviewFormStore())

const drawer = ref(false)

const chapters = computed(() => {
  return interviewQuestions?.value?.filter(question => question?.type === InterviewQuestionType.CHAPTER)
})

const headlines = computed(() => {
  return interviewQuestions.value.map((question) => ({
    id: question?.id,
    title: question?.text,
    type: question?.type,
    icon: deduceMatIconFromInterviewQuestionType(question?.type),
    Chapterpos: question?.type === InterviewQuestionType.CHAPTER ? chapters?.value?.indexOf(question) + 1 : null,
  }))
})

const scrollToElement = (index) => {
  let element = document.getElementById(index)
  if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

watch(isLargeScreen, (newValue) => {
  if (!newValue) {
    drawer.value = false
  }
})
</script>
