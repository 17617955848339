<template>
  <div :id="question.id" class="flex flex-col items-start gap-[120px] self-stretch mt-[80px]">
    <div class="flex flex-col items-start gap-14 self-stretch">
      <!-- Header -->
      <div class="w-full py-8 flex justify-center items-center gap-4 border border-[#767680] border-opacity-100 rounded-[16px]">
        <div>
          <Icon
            icon="mingcute:target-line"
            width="24"
            height="24"
            class="text-onSurface"
          />
        </div>

        <p class="text-[24px] font-medium leading-8 text-onSurface">
          {{ question.type === InterviewQuestionType.UPDATE_ROADMAP ? $t('Roadmap - update targets') : $t('Roadmap - create targets') }}
        </p>
      </div>

      <div class="w-full flex flex-col items-start self-stretch">
        <div class="flex flex-col items-start gap-6">
          <h5 class="text-[24px] text-onSurface font-medium leading-8">
            {{ question?.text }}
          </h5>

          <quill-editor
            v-model:content="question.description"
            content-type="html"
            :placeholder="' '"
            :read-only="true"
            class="!cursor-default break-words w-full !text-onSurface !text-[28px] leading-[48px] description"
          />
        </div>

        <v-divider class="border-opacity-100 text-fakeblack"></v-divider>

        <div
          v-for="(target, idx) in targets"
          :key="idx"
          class="w-full flex flex-col justify-center items-start gap-6"
          :class="idx !== targets?.length - 1 ? 'mb-20' : ''"
        >
          <div class="w-full flex flex-col justify-center items-start gap-6">
            <div class="flex flex-col justify-center items-start gap-6 self-stretch">
              <div class="flex items-center gap-4 self-stretch">
                <div>
                  <Icon
                    :icon="getIconAccordingToType(target?.objective_indicator?.indicator_type)"
                    width="24"
                    height="24"
                    class="text-onSurface"
                  />
                </div>

                <p class="text-[16px] text-onSurface font-bold leading-6">
                  {{ target?.title }}
                </p>
              </div>

              <div class="flex justify-between gap-10 items-center self-stretch">
                <!-- Current value -->
                <div class="flex items-center gap-4">
                  <p class="text-base font-medium leading-6 text-onSurface">
                    {{ $t("Current value") }}:
                  </p>

                  <div class="flex px-3 py-2 rounded bg-surfaceLight">
                    <p class="text-[14px] font-bold leading-5 text-onSurface">
                      {{ target?.objective_indicator?.options?.current_value ?? $t('Not set yet') }}
                    </p>
                  </div>
                </div>

                <!-- Deadline -->
                <div class="flex items-center justify-center gap-[6px]">
                  <p class="text-[14px] font-medium leading-5 text-onSurfaceVariant whitespace-nowrap">
                    {{ $t('Deadline') }}:
                  </p>

                  <p class="text-[14px] font-normal leading-5 text-onSurfaceVariant whitespace-nowrap">
                    {{ $t(target?.due_date ? $filters.formatDate(target?.due_date, "DD[/]MM[/]YYYY") : 'No deadline') }}
                  </p>
                </div>
              </div>

              <div
                v-if="target?.description"
                class="whitespace-pre-wrap !cursor-default break-words w-full !text-onSurface !text-[14px] font-normal leading-5 description"
                v-html="target?.description"
              >
              </div>
<!--              <quill-editor-->
<!--                v-if="target?.description"-->
<!--                v-model:content="target.description"-->
<!--                content-type="html"-->
<!--                :placeholder="' '"-->
<!--                :read-only="true"-->
<!--                class="!cursor-default break-words w-full !text-onSurface !text-[28px] font-normal leading-[48px] description"-->
<!--              />-->
            </div>

            <div
              v-if="question?.type !== InterviewQuestionType.CREATE_ROADMAP"
              class="flex flex-col justify-center items-start gap-6"
            >
              <div class="flex flex-col justify-center items-start gap-6">
                <div
                  v-if="target?.suggestions?.length"
                  class="flex flex-col justify-center items-start gap-6"
                >
                  <div
                    v-for="(suggestion, idx) in target?.suggestions?.filter(el => !el?.final_update)"
                    :key="idx"
                    class="flex flex-col items-start gap-4"
                  >
                    <div class="w-full flex items-center gap-6">
                      <p class="text-[28px] text-onSurface font-bold leading-normal pl-4">
                        •{{ `  ${suggestion?.user?.fullname}` }} {{ $t(" suggested update") }}:
                      </p>

                      <div class="w-full px-3 py-2 bg-very-light-grey rounded">
                        <p class="text-[28px] text-onSurface font-bold leading-normal">
                          {{ suggestion?.proposal ?? $t('Not set') }}
                        </p>
                      </div>
                    </div>

                    <div
                      v-if="suggestion?.comment"
                      class="flex items-center self-stretch"
                    >
                      <v-divider class="border-opacity-100 text-onSurface py-3 pl-5" vertical :thickness="1"></v-divider>

                      <div
                        class="whitespace-pre-wrap !cursor-default break-words w-full font-normal !text-onSurface !text-[28px] leading-[48px] description pl-6"
                        v-html="suggestion.comment"
                      />
                    </div>
                  </div>
                </div>

                <p v-else class="font-normal !text-onSurface !text-[16px] leading-[20px]">
                  {{ $t('No suggestions yet.') }}
                </p>
                <!-- <div v-else class="py-8">
                </div> -->

                <div v-if="target?.suggestions?.find(el => el?.final_update)" class="flex flex-col items-start gap-4">
                  <div class="flex items-center gap-4">
                    <p class="text-[32px] text-onSurface font-bold leading-normal">
                      {{ $t('Final update') }}
                    </p>

                    <div class="flex items-start px-3 py-2 rounded bg-very-light-grey">
                      <p class="text-[32px] text-onSurface font-bold leading-normal">
                        {{ target?.suggestions?.find(el => el?.final_update)?.proposal }}
                      </p>
                    </div>
                  </div>

                  <div
                    v-if="target?.suggestions?.find(el => el?.final_update)?.comment"
                    class="whitespace-pre-wrap !cursor-default break-words w-full font-normal !text-onSurface !text-[28px] leading-[48px] description"
                    v-html="target?.suggestions?.find(el => el?.final_update)?.comment"
                  />
                </div>
              </div>
            </div>
          </div>

          <v-divider v-if="idx !== targets?.length - 1" :thickness="1" class="w-full border border-opacity-100 text-onSurface"></v-divider>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { storeToRefs } from "pinia";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";
import axiosService from "@/tools/axios-service.js";
import {useInterviewStore} from "@/store/interview.js";
import { onMounted, ref } from 'vue'
import { InterviewQuestionType } from '../../../../constants/types';

const { id, employee, campaignId, targetHistories, targetDraftHistories, isLastInterviewCompletedAndLocked } = storeToRefs(useInterviewStore())
const { fetchCreatedSnapshots, fetchUpdatedSnapshots, clearTargetsAndSuggestions } = useTargetSuggestionsStore();
const { snapshotsCreated } = storeToRefs(useTargetSuggestionsStore());

const props = defineProps(['question'])

onMounted(async () => {
  if (props.question?.type == InterviewQuestionType.UPDATE_ROADMAP) {
    targets.value = await fetchUpdatedSnapshots(employee?.value?.id, campaignId.value, id?.value)
  } else {
    await fetchTargetDrafts()
  }
})

const targets = ref([])

const fetchTargets = async () => {
  try {
    const res = await axiosService.get(`/api/v1/roadmaps/employees/${employee.value.id}/unarchived_target?campaign_id=${campaignId.value}`)

    targets.value = res.data?.objective_elements
  } catch (e) {
    console.log("couldn't get target", e)
  }
}

const fetchTargetDrafts = async () => {
  clearTargetsAndSuggestions()
  try {
    await fetchCreatedSnapshots(employee?.value?.id, id?.value)
    targets.value = snapshotsCreated.value

  } catch (e) {
    console.log("couldn't get draft targets", e)
  }
}

const getIconAccordingToType = (type) => {
  switch (type) {
    case 'percentage':
      return 'ic:baseline-percent'
    case 'numeric_value':
      return 'ic:baseline-numbers'
    case 'multi_choice':
      return 'material-symbols:check-box-outline'
    case 'boolean':
      return 'mdi:toggle-switch-outline'
    default:
      return ''
  }
}
</script>

<style scoped>
:deep(.ql-toolbar) {
  display: none !important;
}

:deep(.ql-container) {
  cursor: default !important;
  border: 0px none !important;
  border-bottom: black 0px solid !important;
}

:deep(.ql-editor) {
  padding: 0px !important;
}
</style>