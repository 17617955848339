<template>
  <v-app>
    <v-main class="relative bg-surface">
      <BktToasts />

      <router-view />

      <v-dialog
        v-model="data.open"
        width="auto"
        :persistent="data.event !== null"
        @click:outside="clickOutside"
      >
        <bkt-pop-up />
      </v-dialog>

      <v-snackbar
        width="100%"
        max-width="344"
        :color="snackbar.bgColor"
        v-model="snackbar.display"
        :vertical="snackbar.vertical"
        :content-class="snackbar.customClass"
      >
        <div class="w-full" :class="snackbar.twoLines ? 'h-10' : 'h-5'">
          <svn-pro-text
            bodyMedium
            color="onPrimary"
            :class="[`line-clamp-${snackbar.twoLines ? 2 : 1}`, `text-${snackbar.textColor}`]"
          >
            {{ $t(snackbar.msg) }}
          </svn-pro-text>
        </div>

        <template v-if="snackbar.action || snackbar.closeButton" #actions>
          <svn-pro-button
            v-if="snackbar.action"
            variant="text"
            :text="snackbar?.action.name"
            @click="snackbar?.action?.callback()"
          />

          <svn-pro-icon-button
            v-if="snackbar.closeButton"
            color="onPrimary"
            icon="mdi-close"
            variant="text"
            @click="snackbar?.closeButton?.callback()"
          />
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script setup>
import BktPopUp from "./components/popUpComponents/BktPopUp.vue";
import { usePopUpStore } from "./store/pop-up.js";
import { storeToRefs } from "pinia";
import { useSnackbar } from "@/store/snackbar.js";
import BktToasts from "@/components/toastsComponent/BktToasts.vue";
import { onBeforeUnmount, ref, watch } from "vue";
import { useToastStore } from "@/store/toast.js";
import { useActionCable } from "@/store/cable.js";
import { useUserStore } from "@/store/user.js";

const { id } = storeToRefs(useUserStore());
const { data } = storeToRefs(usePopUpStore());
const { cable } = storeToRefs(useActionCable());

const snackbar = useSnackbar();
const { addToast } = useToastStore();
const { closePopUp } = usePopUpStore();

snackbar.init();

const clickOutside = () => {
  closePopUp()
  try {
    data?.value?.event();
  } catch (error) {}
};

const notificationChannel = ref(null)

watch(id, () => {
  if (id.value) {
    if (cable.value) {
      const subscribeOptions =
        {
          channel: "NotificationChannel", user_id: id.value
        }

      notificationChannel.value = cable?.value?.subscriptions.create(subscribeOptions, {
        connected: function() {
          // Called when the subscription is ready for use on the server
        },

        disconnected: function() {
          // Called when the subscription has been terminated by the server
        },

        received: function(data) {
          // Called when there's incoming data on the websocket for this channel
          if (data.status === "send") {
            addToast(
              'info',
              data.content.title,
              data.content.description,
              false,
              {name: 'View my badges', link: '/people/user_show/' + id.value},
              data.content.notification_type,
              data.content.icon,
              data.content.badge,
            )
          }
        },

        update: function() {
        },
      });
    }
  }
})

onBeforeUnmount(() => {
  notificationChannel.value.unsubscribe();
})

</script>

<style lang="scss">
@import "@/assets/stylesheets/config/nest.scss";
</style>
