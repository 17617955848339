<template>
  <svn-pro-modal
    ref="modalAddUser"
    :persistent="true"
    no-click-animation
    :title="$t('Create user')"
    :actionOneTitle="$t('Create')"
    :actionTwoTitle="$t('Create and send invitation')"
    :sticky-bottom="true"
    :close-button-closes-modal="false"
    :primary-loading="formLoading"
    @click-close="resetFields"
    @click-primary-button="createUser"
    @click-secondary-button="createUser('?invite=true')"
    @click-outside="resetFields"
  >
    <template #activator>
      {{ null }}
    </template>

    <template #text>
      <v-form
        id="top_anchor"
        ref="form"
        v-model="valid"
        class="w-full flex flex-col gap-8 items-start self-stretch"
        lazy-validation
      >
        <!-- Personal information -->
        <div class="w-full flex flex-col gap-4">
          <svn-pro-text semi-bold medium>
            {{ $t('Personal information') }}
          </svn-pro-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
            <!-- Firstname -->
            <svn-pro-text-field
              v-model="user.firstname"
              :label="$t('First name*')"
              variant="outlined"
              class="h-fit"
              :rules="firstnameRules"
            />

            <!-- Lastname -->
            <svn-pro-text-field
              v-model="user.lastname"
              :label="$t('Last name*')"
              variant="outlined"
              class="h-fit"
              :rules="lastnameRules"
            />

            <!-- Email -->
            <svn-pro-text-field
              v-model="user.email"
              :label="$t('Email*')"
              variant="outlined"
              class="h-fit"
              :rules="emailRules"
              :error="emailError.error"
              :error-messages="emailError.errorMessage"
            />

            <!-- Birthdate -->
            <pop-up-edit-date @save="updateBirthDate">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-bind="props"
                  variant="outlined"
                  v-model="user.birth_date"
                  clearable
                  class="h-fit"
                  hide-details
                  clear-icon="mdi-close"
                  prepend-inner-icon="custom:mingcute:calendar-2-line"
                  :label="$t('Birth date')"
                />
              </template>
            </pop-up-edit-date>

            <!-- Gender -->
            <svn-pro-select
              v-model="user.gender"
              :items="[
                { title: $t('Female'), value: 'female'},
                { title: $t('Male'), value: 'male'},
                { title: $t('Other'), value: 'other'},
              ]"
              :label="$t('Gender')"
            />

            <!-- Profile picture -->
            <svn-pro-text-field
              v-model="avatarFilename"
              :label="$t('Profile picture')"
              prepend-inner-icon="custom:mingcute:attachment-line"
              variant="outlined"
              clearable
              clear-icon="mdi-close"
              class="h-fit"
              @click="openFilePicker"
              @lear="removeAvatarPicture"
            />
          </div>
        </div>

        <!-- Hr information -->
        <div class="w-full flex flex-col gap-4">
          <svn-pro-text semi-bold medium>
            {{ $t('HR information') }}
          </svn-pro-text>

          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
            <!-- Access level -->
            <svn-pro-select
              v-model="user.access_level"
              :items="[
                { title: $t('Employee'), value: 'employee' },
                { title: $t('Manager'), value: 'manager' },
                { title: $t('Manager Creator'), value: 'manager_creator' },
                { title: $t('Admin'), value: 'admin' },
              ]"
              :label="$t('Access level')"
            />

            <!-- Manager -->
            <svn-pro-autocomplete
              v-model="user.manager"
              :label="$t('Manager')"
              item-value="id"
              item-title="fullname"
              :items="managers"
            />

            <!-- Hire Date -->
            <pop-up-edit-date @save="updateHireDate">
              <template #activator="{ props }">
                <svn-pro-text-field
                  v-bind="props"
                  variant="outlined"
                  v-model="user.hire_date"
                  clearable
                  clear-icon="mdi-close"
                  color="primary"
                  prepend-inner-icon="custom:mingcute:calendar-2-line"
                  :label="$t('Hire date')"
                />
              </template>
            </pop-up-edit-date>

            <!-- Tags -->
            <svn-pro-autocomplete
              v-for="(category, index) in tagCategories"
              :key="user.tags[category.id]"
              v-model="user.tags[category.id]"
              :items="category.tags"
              return-object
              item-title="tagName"
              :label="capitalize(category.name)"
              @update:search="tagCategorySearch[index] = $event"
            />
          </div>
        </div>
      </v-form>
    </template>
  </svn-pro-modal>

  <!-- Hidden File Picker -->
    <input
    id="file_picker"
    class="w-0 h-0 overflow-hidden opacity-0"
    type="file"
    accept=".jpg,.jpeg,.png,.webp,.gif"
    @change="uploadFile"
  />
</template>

<script setup>
import axios from "axios";
import moment from "moment";
import { storeToRefs } from "pinia";
import i18n from "@/plugins/i18n.js";
import { useRouter } from "vue-router";
import { ref, watch, onMounted } from "vue";
import caseStyles from "@/tools/caseStyles.js";
import { useMobileStore } from "@/store/mobile";
import { useSnackbar } from "@/store/snackbar.js";
import { useCompanyStore } from "@/store/company.js";
import ModalSkeleton from '../skeleton/BktPopUpModalSkeleton.vue';
import PopUpEditDate from "../../popUpComponents/PopUpEditDate.vue";

onMounted(async() => {
  try {
    await getManagers();
  } catch (error) {
    console.log(error);
  }
})

const props = defineProps({
  title: { type: String, default: "" },
})

const { isMdScreen } = storeToRefs(useMobileStore());
const { tagCategories, managers } = storeToRefs(useCompanyStore());

const router = useRouter();
const snackbar = useSnackbar();
const { fetchCompanyManagers } = useCompanyStore();

const form = ref();
const valid = ref(false);
const isTaken = ref(false)
const invalidEmail = ref(null)
const modalAddUser = ref(null)
const formLoading = ref(false);
const managerLoading = ref(false);
const avatarFilename = ref(null);
const user = ref({
  access_level: null,
  firstname: null,
  lastname: null,
  email: null,
  manager: null,
  birth_date: null,
  hire_date: null,
  gender: null,
  avatar: null,
  companyUid: null,
  tags: [],
  job_title: null,
});
const tagCategorySearch = ref(tagCategories.value.map((_) => ""));
const firstnameError = ref({ error: false, errorMessage: '', hidedetails: true });
const lastnameError = ref({ error: false, errorMessage: '', hidedetails: true });
const emailError = ref({ error: false, errorMessage: '', hidedetails: true });
const firstnameRules = [(v) => !!v || "First name is required"]
const lastnameRules = [(v) => !!v || "Last name is required"]
const emailRules = [(v) => !!v || "Email is required"]

const createUser = async (query = "") => {
  formLoading.value = true;
  isTaken.value = false

  if (user?.value?.firstname === null || user?.value?.lastname === null || user?.value?.email === null) {
    const top = document.getElementById('top_anchor')
    if (top) {
      top.scrollIntoView({ behavior: 'smooth' })
    }
  }

  try {
    const { valid } = await form.value.validate();

    if (valid) {
      const formData = new FormData();

      if (user?.value?.access_level) formData.append("user[access_level]", user?.value?.access_level);
      if (user?.value?.firstname) formData.append("user[firstname]", user?.value?.firstname);
      if (user?.value?.lastname) formData.append("user[lastname]", user?.value?.lastname);
      if (user?.value?.email) formData.append("user[email]", user?.value?.email);
      if (user?.value?.hire_date) formData.append("user[hire_date]", JSON.stringify(user?.value?.hire_date));
      if (user?.value?.birth_date) formData.append("user[birth_date]", JSON.stringify(user?.value?.birth_date));
      if (user?.value?.gender) formData.append("user[gender]", user?.value?.gender ? user?.value?.gender : "");
      if (user?.value?.job_title) formData.append("user[job_title]", user?.value?.job_title);
      if (user?.value?.manager) formData.append("user[manager_id]", user?.value?.manager || user?.value?.manager.id);  if (user?.value?.tags) formData.append("user[tags]", JSON.stringify(user?.value?.tags));
      if (user?.value?.avatar) formData.append("user[avatar]", user?.value?.avatar);
      if (user?.value?.companyUid) formData.append("user[company_uid]", user?.value?.companyUid ? user?.value?.companyUid : "");
      // if (user?.value?.tags) formData.append("user[tags]", JSON.stringify(caseStyles.convertKeysToSnake(user?.value?.tags)));

      const headers = { "Content-Type": "multipart/form-data" };
      const res = await axios.post(`/api/v1/users${query}`, formData, {
        headers,
      });

      resetFields()

      snackbar.setBgColor('onSurface').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[152px]').setMsg("User has been created successfully.").displaySnackBar()
    }
  } catch (e) {
    emailError.value.error = true
    const message = e?.response?.data?.message

    if (message === "Email is not an email" || message === "Email n'est pas une adresse e-mail") {
      emailError.value.errorMessage = 'The email is not valid'
    } else if (message === "Email has already been taken") {
      emailError.value.errorMessage = 'Email already used.'
      invalidEmail.value = user.value.email
    }
    snackbar.setBgColor('onSurface').setCustomClass(isMdScreen?.value ? 'mb-4' : 'mb-[152px]').setMsg('Email incorrect or already in use.').displaySnackBar()
  }
  formLoading.value = false;
}

const updateBirthDate = (value) => {
  user.value.birth_date = moment(value).format('DD/MM/YYYY')
}

const updateHireDate = (value) => {
  user.value.hire_date = moment(value).format('DD/MM/YYYY')
}

const getManagers = async () => {
  try {
    await fetchCompanyManagers({ disableCache: true });
  } catch (e) {
    console.log("error", e);
  } finally {
    managerLoading.value = false;
  }
};

const resetFields = () => {
  modalAddUser.value.dialog = false
  avatarFilename.value = null
  user.value = {
    access_level: null,
    firstname: null,
    lastname: null,
    email: null,
    manager: null,
    birth_date: null,
    hire_date: null,
    gender: null,
    avatar: null,
    companyUid: null,
    tags: [],
    job_title: null,
  };
}

const uploadFile = (e) => {
  const { files } = e.target;

  if (files && files[0]) {
    avatarFilename.value = files[0]?.name
    user.value.avatar = files[0];
  }
};

const openFilePicker = () => {
  let element = document.getElementById('file_picker')
  if (element) {
    element.click()
  }
}

const removeAvatarPicture = () => {
  avatarFilename.value = null
  user.value.avatar = null
}

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

watch(user.value, () => {
  if (invalidEmail.value !== user.value.email && emailError.value.error) {
    emailError.value.error = false
    emailError.value.errorMessage = ''
  }
})

defineExpose({
  modalAddUser
})
</script>

<style scoped>
.input :deep(.v-field) {
  border-radius: 8px;
}

.v-text-field :deep(.v-input__details) {
  padding-inline-start: 0px;
}
</style>
