
<template>
  <svn-pro-card
    :link="false"
    class="rounded-2xl bkt-box-shadow px-10 py-8"
  >
    <v-card-title
      class="p-0 py-1 text-wrap"
    >
      <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
        {{ question.text }}
      </svn-pro-title>

    </v-card-title>

    <v-card-subtitle
      class="my-3 p-0"
    >
      <div
        class="mb-4 bkt-unwrap bkt-v-html-format"
        v-html="question.description"
      />

      <!-- <svn-pro-text body-large medium>
        {{ question.description }}
      </svn-pro-text> -->

    </v-card-subtitle>

    <v-card-text
      class="p-0"
    >
      <svn-pro-text-area
        v-model="description"
        :placeholder="$t('Description')"
        variant="outlined"
        :rows="6"
        :max-rows="6"
        :label="$t('Answer')"
        :textarea="true"
      />
    </v-card-text>
  </svn-pro-card>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());

const props = defineProps(['question', 'loopIdx'])

</script>