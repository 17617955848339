<template>
  <svn-pro-card
    :link="false"
    class="p-8"
    >
    <div class="flex flex-col gap-4">
      <svn-pro-title h6 bold>
        <v-icon
          class="text-[#5C95FF]"
          icon="custom:mingcute:target-line"
          size="24"
        />

        {{ target.title }}
      </svn-pro-title>

      <div class="flex justify-between">
        <svn-pro-info-tag
          tag-type="error"
          :text="targetValue"
          tag-style="filled"
          class="!w-fit h-[32px] justify-center"
          leading-item="point"
        />
        <div>
          <v-icon
            v-bind="props"
            class="pl-1 cursor-pointer"
            icon="custom:mingcute:calendar-month-line"
            size="24"
          />
          {{ moment(target.due_date).format('DD MMM') }}
        </div>
      </div>

      <svn-pro-text
        medium
        regular
        color="onSurfaceVariant"
      >
        {{ target?.description }}
      </svn-pro-text>

      <target-suggestions
        :target="target"
        :question="question"
        :is-draft="false"
        :employee-id="employee?.id"
      />

      <div v-if="status !== 'submitted'" class="flex flex-col gap-4" >
        <v-radio-group
          v-if="target?.objective_indicator?.indicator_type === 'multi_choice'"
          v-model="indicatorCurrentValue"
          hide-details
        >
          <div
            v-for="item of getMCQOptions(target.objective_indicator.options.multi_choice_list)"
            class="flex items-center w-fit"
          >
            <svn-pro-radio-button
              :value="item"
              :id="item"
              color="primary"
              @click="updateSnapshot"
            />
            <label :for="item" class="cursor-pointer">
              <svn-pro-text body-large>
                {{ item }}
              </svn-pro-text>
            </label>
          </div>
        </v-radio-group>

        <v-radio-group
          v-else-if="target?.objective_indicator?.indicator_type === 'boolean'"
          v-model="indicatorCurrentValue"
          hide-details
        >

        <div
          v-for="item of targetBooleanValue"
          class="flex items-center w-fit"
        >
          <svn-pro-radio-button
            :value="item"
            :id="item"
            color="primary"
            @click="updateSnapshot"
          />
          <label :for="item" class="cursor-pointer">
            <svn-pro-text body-large>
              {{ item }}
            </svn-pro-text>
          </label>
        </div>
        </v-radio-group>

        <svn-pro-text-field
          v-if="target?.objective_indicator?.indicator_type === 'percentage' ||
            target?.objective_indicator?.indicator_type === 'numeric_value' && !locked"
          :label="$t('Answer')"
          variant="outlined"
          class="h-fit"
          :disabled="locked"
          type="number"
          :placeholder="isFinalUpdate ? $t('Final value') : $t('Suggested value')"
          v-model="indicatorCurrentValue"
          @update:model-value="updateSnapshot"
        />

        <svn-pro-text-area
          v-model="targetComments"
          :placeholder="$t('Comment')"
          variant="outlined"
          :rows="6"
          :max-rows="6"
          :label="$t('Comment')"
          :textarea="true"
          @update:model-value="updateSnapshot"
        />
      </div>
    </div>
  </svn-pro-card>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import {storeToRefs} from "pinia";
import TargetSuggestions from '@/components/interviewApp/interview_answers/TargetSuggestions.vue';
import { useInterviewStore } from "@/store/interview.js";
import { useSnackbar } from '@/store/snackbar';
import { useUserStore } from "@/store/user.js";
import axiosService from "@/tools/axios-service.js";
import { debounce } from 'lodash';
import moment from "moment";
import { useTargetSuggestionsStore } from "@/store/targets-suggestions";

const props = defineProps({
  target: { type: Object, required: true },
  question: { type: Object, required: false },
  isDraft: { type: Boolean, default: false },
  employee: { type: Object, required: false },
})

const snackbar = useSnackbar();
const { fetchUpdatedSnapshots } = useTargetSuggestionsStore();

const { id: currentUserId } = storeToRefs(useUserStore());
const { employee, campaignId, interviewKind, locked, status, id, isFinalUpdate } = storeToRefs(useInterviewStore())


const indicatorCurrentValue = ref(null);
const targetComments = ref(null);

const getMCQOptions = (mcq) => {
  if (mcq) {
    return mcq.map((option) => {
      return option.option
    })
  }
}

onMounted(async () => {

  if (!props?.target?.current_suggestion && interviewKind.value == 'crossed') {
    const previousSuggestion = props.target.suggestions.find((suggestion) =>
      suggestion.user.id === currentUserId.value &&
      suggestion.state === 'manager'
    );

    indicatorCurrentValue.value = previousSuggestion?.proposal;
    targetComments.value = previousSuggestion?.comment;
  } else {
    indicatorCurrentValue.value = props?.target?.current_suggestion?.proposal;
    targetComments.value = props?.target?.current_suggestion?.comment;
  }
});

const targetValue = computed (() => {
  if (props.target?.objective_indicator?.indicator_type === 'multi_choice') {
    return getMCQPossibleAnswers(props.target?.objective_indicator?.options?.multi_choice_list)
  } else {
    return props.target?.objective_indicator?.options?.target_value.toString() +  (props.target?.objective_indicator?.indicator_type === 'percentage' ? '%' : '') || '-'
  }
})

const targetBooleanValue = computed (() => {
  if (props.target?.objective_indicator?.indicator_type === 'boolean') {
    return [props.target?.objective_indicator?.options?.starting_value, props.target?.objective_indicator?.options?.target_value]
  } else {
    return []
  }
})

const getMCQPossibleAnswers = (mcq) => {
  if (mcq) {
    return mcq.filter((option) => option.checkbox === true).length
  }
}

const updateSnapshot = debounce(async() => {
  try {
    await axiosService.patch(
      `/api/v1/roadmaps/indicators/${props?.target?.objective_indicator?.id}`,
      {
        current_value: indicatorCurrentValue?.value,
        comment:  targetComments?.value,
        interview_id: id?.value
      }
    );
    await fetchUpdatedSnapshots(employee?.value?.id, campaignId?.value, id?.value)
  } catch (error) {
    console.log(error)
    snackbar.setBgColor("negativeRed")
    snackbar.displaySnackBar("Could not update suggestion ! Please try again.");
  }
}, 500)
</script>

<style scoped>
* :deep(.v-field) {
  border-radius: 8px !important;
}
</style>
