<template>
  <div
    v-if="interviewForm"
    class="w-full flex flex-col gap-6"
  >
    <div
      class="break-words w-full description text-center"
      v-html="interviewForm?.description"
    />

    <iframe
      v-if="isValidLink"
      id="ytplayer"
      class="mx-auto"
      type="text/html"
      width="100%"
      height="360"
      :src="
        'https://www.youtube.com/embed/' +
          interviewForm?.video?.match(
            /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
          )[1]
      "
    />

    <component
      :is="question?.componentName"
      v-for="(question, idx) in questionsComponent"
      :key="idx"
      :loop-idx="idx"
      :question="question?.data"
    />
  </div>
</template>

<script>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import ParagraphAnswer from "./ParagraphAnswer.vue";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';
import ChapterAnswer from "@/components/interviewApp/interview_answers/ChapterAnswer.vue";
import OpenQuestionAnswer from "@/components/interviewApp/interview_answers/OpenQuestionAnswer.vue";
import RatingAnswer from "@/components/interviewApp/interview_answers/RatingAnswer.vue";
import McqAnswer from "@/components/interviewApp/interview_answers/McqAnswer.vue";
import RoadmapBlockUpdate from "@/components/interviewApp/interview_answers/RoadmapBlockUpdate.vue";
import RoadmapBlockCreate from "@/components/interviewApp/interview_answers/RoadmapBlockCreate.vue";

export default {
  components: {
    RoadmapBlockUpdate,
    RoadmapBlockCreate,
    ParagraphAnswer,
    ChapterAnswer,
    OpenQuestionAnswer,
    RatingAnswer,
    McqAnswer,
  },
  setup() {
    const { interviewForm } = storeToRefs(useInterviewStore());
    const isValidLink = computed(() => {
      return interviewForm?.value?.video && interviewForm?.value?.video?.match(/youtube\.com/)
    })

    const questionsComponent = computed(() => {
      return interviewForm?.value?.questions?.map((question) => {
        let componentName = null;

        if (question?.type === InterviewQuestionType?.CHAPTER)
          componentName = "chapter-answer";
        else if (question?.type === InterviewQuestionType?.OPEN)
          componentName = "open-question-answer";
        else if (question?.type === InterviewQuestionType?.PARAGRAPH)
          componentName = "paragraph-answer";
        else if (question?.type === InterviewQuestionType?.RATING)
          componentName = "rating-answer";
        else if (question?.type === InterviewQuestionType?.MCQ)
          componentName = "mcq-answer";
        else if (question?.type === InterviewQuestionType?.UPDATE_ROADMAP)
          componentName = "roadmap-block-update";
        else if (question?.type === InterviewQuestionType?.CREATE_ROADMAP)
          componentName = "roadmap-block-create";

        return { data: question, componentName };
      });
    });
    return {
      interviewForm,
      questionsComponent,
      isValidLink,
    };
  },
};
</script>

<style scoped>
#ytplayer {
  max-width: 640px;
}
</style>