<template>
  <div
    :id="question.id"
  >
    <svn-pro-card
      :link="false"
      id="roadmapBlock"
      class="rounded-2xl bkt-box-shadowx p-4"
    >

      <v-card-text>
        <svn-pro-title :h5="!isMobile" :h6="isMobile" medium>
          {{ question.text }}
        </svn-pro-title>

        <div
          v-html="question.description"
        />
      </v-card-text>
    </svn-pro-card>
  </div>
</template>

<script setup>
const props = defineProps(['question'])
import { storeToRefs } from "pinia";
import { useMobileStore } from "@/store/mobile";

const { isMobile } = storeToRefs(useMobileStore());
</script>
