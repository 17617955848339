import { LearnInputType } from 'svn-ui-library/components';

export default class LearnInput {
  constructor({ id, type, title, text, proposals, editorContent }) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.text = text;
    this.proposals = proposals;
    this.editorContent = editorContent;
  }

  isCheckBoxQuestion() {
    return this.type === LearnInputType.CHECKBOX;
  }

  static fromJson(data) {
    return new LearnInput({
      id: data['id'],
      type: data['type'],
      title: data['title'],
      text: data['text'],
      proposals: data['proposals'],
      editorContent: data['editor_content'],
    });
  }
}
