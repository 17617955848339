<template>
  <div>
    <div class="bg-surface flex flex-col w-full lg:!max-w-[928px] xl:!max-w-[1136px] 2xl:pt-10 mx-auto sm:pt-5 pt-6 md:!max-w-[672px] mx-auto h-full gap-10">
      <div class="flex flex-col gap-6 px-12 md:!px-0">
        <svn-pro-header-section
          :size="isMdScreen ? 'default' : 'compact'"
          :title="$t('My Trainings')"
          :subtitle="$t('Here is the list of all your trainings.')"
        />
        <div class="flex  gap-2 md:!gap-4 flex-wrap justify-center md:!justify-start">
          <svn-pro-info-tag
            :text="`${myLearningsCompleted?.completed_learn_training_count || 0} ` + $t('Trainings completed')"
            tag-type="info"
            tag-style="filled"
            tag-size="compact"
            :break-all="true"
            class="max-w-[900px] justify-self-start p-2"
            leading-item="icon"
            icon="noto:smiling-face-with-sunglasses"
          />

          <svn-pro-info-tag
            :text="`${myLearningsCompleted?.completed_learn_modules_count || 0} ` + $t('Modules acquired')"
            tag-type="info"
            tag-style="filled"
            tag-size="compact"
            :break-all="true"
            class="max-w-[900px] justify-self-start p-2"
            leading-item="icon"
            icon="noto:open-book"
          />
        </div>
      </div>

      <div>
        <!-- Tabs -->
        <v-tabs
          v-model="tab"
          color="primary"
          :grow="isMobile"
        >
          <!-- To do Tab -->
          <v-tab
            value="todo"
            class="w-1/2 md:w-auto"
            :text="`${$t('To do')} (${myLearningsTodo?.todo_learn_training_count || 0})`"
          />

          <!-- Archived targets Tab -->
          <v-tab
            value="completed"
            class="w-1/2 md:w-auto"
            :text="`${$t('Completed')} (${myLearningsCompleted?.completed_learn_training_count || 0})`"
          />
        </v-tabs>

        <!-- Windows -->
        <v-window
          v-model="tab"
          class="w-full pt-4"
        >
          <!-- ToDo window -->
          <v-window-item
            value="todo"
          >
            <!-- Infinite scroll -->
            <v-infinite-scroll
              :items="trainingToDo.learn_trainings"
              color="primary"
              class="overflow-hidden px-5 md:!px-0"
              @load="loadMoreDataTrainingToDo"
            >
            <div
              class="flex flex-col flex-wrap lg:!grid  lg:!grid-cols-2 gap-4 justify-center"
            >
            <svn-pro-training-card
              v-if="trainingToDo?.todo_learn_training_count > 0"
              v-for="training in trainingToDo?.learn_trainings"
              :card-size="isMdScreen ? 'default' : 'compact'"
              :key="training?.id"
              :title="training?.title"
              :duration="training?.all_modules_duration"
              :status="training?.status"
              :image="training?.cover_url?.['500']"
              :progress="(training?.module_completion / training?.module_count) * 100"
              @click-primary-button="goToTrainingShow(training)"
              @go-to-item-show="goToTrainingShow(training)"
              class="cursor-pointer"
              :primary-button-text="training?.module_completion ? 'Keep going!': 'Start training!'"
            />
            </div>

            <template #empty>
              <svn-pro-empty-states
                v-if="tab === 'todo' && trainingToDo?.todo_learn_training_count === 0"
                :variant="'index'"
                :title="$t('Nothing to show for now')"
                :size="isMdScreen ? 'default' : 'compact'"
                supporting-text="You don't have any training to do yet. </br> Come back later to see your trainings!"
              />
            </template>

            </v-infinite-scroll>
          </v-window-item>

          <!-- ToDo window -->
          <v-window-item
            value="completed"
          >
          <!-- Infinite scroll -->
            <v-infinite-scroll
              :items="trainingCompleted.learn_trainings"
              class="overflow-hidden px-5 md:!px-0"
              color="primary"
              @load="loadMoreDataTrainingCompleted"
            >

              <div
                class="flex flex-col flex-wrap lg:!grid  lg:!grid-cols-2 gap-4 justify-center"
              >
                <svn-pro-training-card
                  v-if="trainingCompleted.learn_trainings?.length"
                  v-for="training in trainingCompleted.learn_trainings"
                  :card-size="isMdScreen ? 'default' : 'compact'"
                  :key="training?.id"
                  :title="training?.title"
                  :duration="training?.all_modules_duration"
                  :status="training?.status"
                  :image="training?.cover_url?.['500']"
                  :progress="(training?.module_completion.module_completion / training?.module_count) * 100"
                  @click-primary-button="goToTrainingShow(training)"
                  @go-to-item-show="goToTrainingShow(training)"
                />
              </div>

              <template #empty>
                <svn-pro-empty-states
                  v-if="tab === 'completed' && trainingCompleted.learn_trainings?.length === 0"
                  :variant="'index'"
                  :title="$t('Nothing to show for now')"
                  :size="isMdScreen ? 'default' : 'compact'"
                  supporting-text="No training completed...yet!"
                />
              </template>
            </v-infinite-scroll>
          </v-window-item>
        </v-window>
      </div>

      <!-- Discover -->
      <div
        class="flex flex-col py-2 mb-[80px] lg:!py-0 mx-5 md:!mx-0"
      >
        <div
          class="flex flex-col items-center gap-y-6 overflow-hidden"
        >
          <!-- Header with texts -->
          <div class="w-full flex flex-col px-4 lg:!px-0">
            <svn-pro-title h5 medium color="onSurface" class="flex gap-2 items-center" >
              <icon icon="noto:eyes" class="text-onSurface"/> {{ $t('New modules to discover!') }}
            </svn-pro-title>

            <svn-pro-text body-large  regular color="onSurfaceVariant">
              {{ $t('Explore the latest modules added to the catalog.') }}
            </svn-pro-text>
          </div>

          <!-- Skeleton loaders -->
          <div
            v-if="loadingModules"
            class="flex flex-col justify-center px-4 items-start self-stretch
              overflow-x-auto lg:!px-0 lg:!overflow-x-visible"
          >
            <div
              class="flex justify-center items-start py-2 gap-2
                  lg:gap-6 lg:!overflow-x-visible lg:!justify-start"
            >
              <skeleton-loader-module-card
                v-for="item in (isMobile ? 1 : 4)"
                :key="item"
              />
            </div>
          </div>

          <!-- Finished loading -->
          <div
            v-else
            class="flex flex-col justify-center items-start self-stretch overflow-x-auto"
          >
            <!-- Modules card list -->
            <div
              v-if="myLearningsModulesToDiscover"
              class="flex justify-center items-start py-2
                gap-4 lg:!overflow-x-visible lg:!justify-start"
            >
              <module-card
                v-for="(module) in modulesToDisplay"
                :id="module?.id"
                :key="module?.id"
                :module-creator-id="module?.creator_id"
                :to-discover="true"
                :background-image="module?.cover_url?.['500']"
                :title="module?.title"
                :reactions="module?.user_reactions"
                :is-favorite="module?.connected_user_favorited"
                :theme-list="module?.themes"
                :duration="module?.duration"
                :updated-at="filters.formatDate(module?.updated_at, 'DD/MM/YYYY')"
                :submission-status="module?.submission_status"
                :submission-updated-at="module?.submission_updated_at"
                @toggle-favorite="toggleModuleFavorite(module?.id, module?.status)"
                @go-to-item-show="goToModuleShow(module)"
              />

            </div>
            <div class="md:flex-col items-end self-stretch flex pt-3">
              <!-- See all modules -->
              <svn-pro-button
                v-if="isMobile || myLearningsModulesToDiscover?.length !== learnModules?.length"
                variant="text"
                :text="$t('See all')"
                @click="seeAllModules"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top button -->
      <svn-pro-fab
        to-top
        size="small"
        color="primary"
        variant="tonal"
        :rounded="'lg'"
        class="fixed bottom-4 right-4 bg-white"
        icon="custom:mingcute:arrow-to-up-line"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import TrainingCard from "@/components/trainingApp/TrainingCard.vue";
import ModuleCard from "@/components/trainingApp/ModuleCard.vue";
import HeaderChip from "@/components/trainingApp/HeaderChip.vue";
import { useUserStore } from "@/store/user.js";
import { useMobileStore } from "@/store/mobile";
import SkeletonLoaderModuleCard from "@/components/skeletonLoaders/SkeletonLoaderModuleCard.vue";
import SkeletonLoaderTrainingCard from "@/components/skeletonLoaders/SkeletonLoaderTrainingCard.vue";
import { useLearnMyLearningsStore } from "@/store/learn-my-learnings.js";
import { useLearnModuleStore } from "@/store/learn-module";
import { useBreadcrumbsStore } from "@/store/breadcrumbs";
import filters from "@/tools/filters";
import { useSnackbar } from "@/store/snackbar";

onMounted(async() => {
  window.addEventListener('resize', updateWindowWidth);

  try {
    await fetchMyLearningsTodo().then((res) => {
      trainingToDo.value = res.data.my_learnings
      trainingToDo.value.learn_trainings = trainingToDo?.value?.learn_trainings?.sort((a, b) =>
        new Date(b.launched_at) - new Date(a.launched_at))
      // totalPagesTrainingToDo.value = res.data.meta.pagination.total_pages
      loadingTodo.value = false
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings to do')
    snackbar.displaySnackBar()
  }

  try {
    await fetchMyLearningsCompleted().then((res) => {
      trainingCompleted.value = res.data.my_learnings
      // totalPagesTrainingCompleted.value = res.data.meta.pagination.total_pages
      loadingCompleted.value = false
    })
  } catch (error) {
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching trainings completed')
    snackbar.displaySnackBar()
  }

  try {
    await fetchModulesToDiscover()
    loadingModules.value = false
  } catch (error) {
    console.log(error)
    snackbar.setBgColor('negativeRed')
    snackbar.setMsg('Error fetching modules completed')
    snackbar.displaySnackBar()
  }
})

const pageTrainingToDo = ref(1)

const pageTrainingCompleted = ref(1)

const pageModules = ref(1)

const tab = ref(null);

const router = useRouter()

const alignTop = ref(false)

const loadingTodo = ref(true)

const loadingCompleted = ref(true)

const loadingModules = ref(true)

const trainingToDo = ref([])

const trainingCompleted = ref([])

const totalPagesTrainingToDo = ref(null)

const totalPagesTrainingCompleted = ref(null)

const { addNode, update } = useBreadcrumbsStore()

const { toggleFavorite } = useLearnModuleStore()

const { fetchMyLearningsTodo, fetchMyLearningsCompleted, fetchModulesToDiscover } = useLearnMyLearningsStore()

const { isMobile, isXlScreen, isMdScreen } = storeToRefs(useMobileStore())

const { firstname } = storeToRefs(useUserStore());

const snackbar = useSnackbar()

const { learnModules } = storeToRefs(useLearnModuleStore())

const { myLearningsTodo, myLearningsCompleted, myLearningsModulesToDiscover } = storeToRefs(useLearnMyLearningsStore())

const seeAllModules = () => {
  router.push({ name: 'catalog' })
}

const toggleModuleFavorite = async(moduleId, status) => {
  try {
    const updatedModule = await toggleFavorite(moduleId, status)
    for (let element of myLearningsModulesToDiscover?.value) {
      if (element?.id === moduleId) {
        element.connected_user_favorited = updatedModule?.connected_user_favorited
      }
    }
    snackbar.setBgColor('onSurface')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-4')
    snackbar.displaySnackBar(updatedModule?.connected_user_favorited ? 'Module added to favorites' : 'Module removed from favorites')
  } catch (error) {
    snackbar.setBgColor('error')
    snackbar.setCustomClass(isMobile.value ? 'mb-[72px]' : 'mb-4')
    snackbar.displaySnackBar('Error toggling favorite')
  }
}

const goToModuleShow = (module) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'module_show', params: { id: module?.id } })
}

const goToTrainingShow = (training) => {
  // updateBreadcrumbs(i18n.global.t('My Learnings'))
  router.push({ name: 'training_show', params: { id: training?.id } })
}

const loadMoreDataTrainingToDo = async({ done }) => {
  if (pageTrainingToDo.value < totalPagesTrainingToDo.value) {
    try {
      const res = await fetchMyLearningsTodo(pageTrainingToDo.value + 1)

        if (res.data.my_learnings.length) {
          trainingToDo.value = [...trainingToDo.value, ...res.data.my_learnings]
        }
        done('ok')
        pageTrainingToDo.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

const loadMoreDataTrainingCompleted = async({ done }) => {
  if (pageTrainingCompleted.value < totalPagesTrainingCompleted.value) {
    try {
      const res = await fetchMyLearningsCompleted(pageTrainingCompleted.value + 1)

        if (res.data.my_learnings.length) {
          trainingCompleted.value = [...trainingCompleted.value, ...res.data.my_learnings]
        }
        done('ok')
        pageTrainingCompleted.value++
    } catch (error) {
      done('error')
    }
  }
  else {
    done('empty')
  }
}

// Reactive reference for window width
const windowWidth = ref(window.innerWidth);

// Function to update window width
const updateWindowWidth = () => {
  windowWidth.value = window.innerWidth;
};

// Remove event listener on unmount
onUnmounted(() => {
  window.removeEventListener('resize', updateWindowWidth);
});

// Computed property to determine the modules to display based on window width
const modulesToDisplay = computed(() => {
  if (windowWidth.value > 1281) {
    // More than 1281px, show 4 modules
    return myLearningsModulesToDiscover.value.slice(0, 4);
  } else if (windowWidth.value > 1025) {
    // Between 1025px and 1280px, show 3 modules
    return myLearningsModulesToDiscover.value.slice(0, 3);
  } else if (windowWidth.value > 600) {
    // Between 600px and 1024px, show 2 modules
    return myLearningsModulesToDiscover.value.slice(0, 2);
  } else {
    // Less than or equal to 600px, show 1 module
    return myLearningsModulesToDiscover.value.slice(0, 1);
  }
});
</script>
