<template>
  <div class="w-full h-full flex flex-col bg-surface">
    <!-- Header with actions -->
    <div class="w-full h-[64px] sticky top-[64px] flex items-center justify-end gap-4 border-b border-borderColor border-opacity-100 px-5 md:!px-4 py-2 bg-surfaceBright z-[1]">
      <!-- Saved text -->
      <template v-if="!locked">
        <svn-pro-info-tag
        v-if="isInterviewLoading"
          tag-type="info"
          :text="$t('Saving...')"
        />
  
        <svn-pro-info-tag
          v-else
          tag-type="info"
          :text="$t('Saved')"
        />
      </template>
  
      <!-- History button -->
      <svn-pro-tooltip :text="$t('Interview updates')">
        <template #activator="{ props }">
          <svn-pro-icon-button
            v-bind="props"
            variant="text"
            :color="historyTooltip ? 'primary' : 'onSurfaceVariant'"
            icon="custom:mingcute:history-anticlockwise-line"
            @click="historyModal = !historyModal"
          />
        </template>
      </svn-pro-tooltip>

      <!-- History Modal -->
      <pop-up-history-updates
        v-model="historyModal"
        :history="historyRef"
        :pagination="paginationHistory"
        :interview-id="Number(route?.params?.id)"
        @close="historyModal = !historyModal"
        @load-more="loadMoreHistory"
      >
        <template #activator>
          {{ null }}
        </template>
      </pop-up-history-updates>
  
      <!-- Summary button -->
      <svn-pro-icon-button
        variant="text"
        :color="(drawer || drawerLarge) ? 'primary' : 'onSurfaceVariant'"
        icon="custom:mingcute:list-ordered-line"
        @click="isLargeScreen ? openDrawer() : drawer = !drawer"
      />
  
      <!-- Download PDF button -->
      <bkt-download-button
        v-if="isMdScreen"
        :loading="downloadLoading"
        @click="clickDownload"
      />
  
      <!-- Submit button -->
      <bkt-submit-interview-answer-button
        v-if="isMdScreen && !locked && interviewForm && interviewAnswers && interviewKind"
        @click="updateAnswersErrorState"
      />
    </div>

    <div class="w-full h-full flex">
      <div class="w-full flex flex-col gap-8 px-5 md:!px-0 py-5 md:!max-w-[674px] lg:!max-w-[614px] xl:!max-w-[752px] desktop:!max-w-[1136px] md:!py-6 mx-auto">
        <div class="flex flex-col md:!flex-row justify-between gap-2">
          <svn-pro-text
            subtitle-medium
            medium
            color="onSurfaceVariant"
            class="text-center"
          >
            {{
              $t("'s Interview with", {
                interviewee: employee?.firstname,
                interviewer: interviewer?.firstname,
              })
            }}
          </svn-pro-text>

          <!-- Deadline -->
          <svn-pro-text
            subtitle-medium
            medium
            color="onSurfaceVariant"
            class="text-center"
          >
            {{ moment(campaign?.deadline).format('DD/MM/YYYY') || $t("No deadline") }}
          </svn-pro-text>
        </div>

        <div class="flex flex-col gap-4">
          <!-- Campaign title -->
          <svn-pro-title :h5="!isMdScreen" :h4="isMdScreen" medium class="text-center">
            {{ campaign?.title }}
          </svn-pro-title>

          <!-- Question list -->
          <interview-answer-list />
        </div>
      </div>

      <!-- Drawer Large -->
      <div
        class="h-full fixed right-0  overflow-hidden flex flex-col border-l border-borderColor border-opacity-100 transition-all pb-6"
        :class="drawerLarge ? 'w-[320px]' : 'w-0'"
      >
        <!-- Header -->
        <div class="w-full sticky top-0 z-[5] flex justify-between items-center pl-6 pr-3 pt-3 pb-4 bg-surface">
          <svn-pro-title h6 regular color="onSurfaceVariant">
            {{ $t( 'Chapters' ) }}
          </svn-pro-title>

          <!-- Close button -->
          <svn-pro-icon-button
            variant="text"
            color="onSurfaceVariant"
            icon="custom:mingcute:close-line"
            @click.stop="drawerLarge = !drawerLarge"
          />
        </div>

        <div class="flex flex-col pb-[115px] overflow-hidden">
          <!-- Questions List -->
          <div class="flex flex-col overflow-y-auto">
            <div
              v-for="(item, i) in headlines"
              :key="i"
              class="w-full flex flex-col gap-2"
            >
              <template v-if="interviewKind === 'crossed' || item?.visible_for === interviewKind || item?.visible_for === 'all' && item?.type !== InterviewQuestionType?.PARAGRAPH">
                <svn-pro-text
                  v-if="item?.type === InterviewQuestionType?.CHAPTER"
                  body-large
                  bold
                  class="px-4"
                  color="onSurface"
                >
                  {{ item?.title }}
                </svn-pro-text>
    
                <v-list-item
                  v-else-if="item.type !== InterviewQuestionType?.PARAGRAPH"
                  :active="false"
                  :value="item?.id"
                  @click="scrollToQuestion(item?.id)"
                >
                  <template #default>
                    <div class="flex items-center gap-4">
                      <div>
                        <Icon
                          :icon="item?.icon"
                          height="24"
                          width="24"
                          class="text-onSurfaceVariant"
                        />
                      </div>
    
                      <svn-pro-text subtitle-large regular class="line-clamp-1">
                        {{ item?.title }}
                      </svn-pro-text>
                    </div>
                  </template>
                </v-list-item>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FABs -->
  <div v-if="!isMdScreen" class="flex flex-col items-end gap-4 fixed bottom-4 right-4">
    <svn-pro-button
      v-if="locked"
      :height="56"
      color="primary"
      :loading="downloadLoading"
      :text="$t('Download pdf')"
      prepend-icon="custom:mingcute:download-2-line"
      @click="clickDownload"
    />

    <svn-pro-icon-button
      v-else
      variant="tonal"
      color="primary"
      rounded="lg"
      elevation="4"
      :loading="downloadLoading"
      icon="custom:mingcute:download-2-line"
      class="bg-white"
      @click="clickDownload"
    />

    <svn-pro-button
      v-if="!locked && interviewForm && interviewAnswers && interviewKind"
      variant="flat"
      :height="56"
      color="primary"
      :text="$t('Submit')"
      :loading="submitLoading"
      prepend-icon="custom:mingcute:send-line"
      @click="updateAnswersErrorState"
    />
  </div>

  <!-- Drawer mobile -->
  <v-navigation-drawer
    v-model="drawer"
    temporary
    location="right"
    :width="320"
    disable-resize-watcher
    class="!rounded-l-[16px]"
  >
    <div class="w-full h-full flex flex-col pb-6 overflow-hidden">
      <!-- Header -->
      <div class="w-full flex justify-between sticky top-0 items-center pl-6 pr-3 pt-3 pb-4 bg-surface z-[1]">
        <svn-pro-title h6 regular color="onSurfaceVariant">
          {{ $t( 'Chapters' ) }}
        </svn-pro-title>

        <!-- Close button -->
        <svn-pro-icon-button
          variant="text"
          color="onSurfaceVariant"
          icon="custom:mingcute:close-line"
          @click.stop="drawer = !drawer"
        />
      </div>

      <!-- Questions List -->
      <div class="flex flex-col overflow-y-auto">
        <div
          v-for="(item, i) in headlines"
          :key="i"
          class="w-full flex flex-col gap-2"
        >
          <template v-if="interviewKind === 'crossed' || item?.visible_for === interviewKind || item?.visible_for === 'all' && item?.type !== InterviewQuestionType?.PARAGRAPH">
            <svn-pro-text
              v-if="item?.type === InterviewQuestionType?.CHAPTER"
              body-large
              bold
              color="onSurface"
            >
              {{ item?.title }}
            </svn-pro-text>
  
            <v-list-item
              v-else-if="item.type !== InterviewQuestionType?.PARAGRAPH"
              :active="false"
              :value="item?.id"
              @click="scrollToQuestion(item?.id)"
            >
              <template #default>
                <div class="flex items-center gap-4">
                  <div>
                    <Icon
                      :icon="item?.icon"
                      height="24"
                      width="24"
                      class="text-onSurfaceVariant"
                    />
                  </div>
  
                  <svn-pro-text subtitle-large regular class="line-clamp-1">
                    {{ item?.title }}
                  </svn-pro-text>
                </div>
              </template>
            </v-list-item>
          </template>
        </div>
      </div>
    </div>
  </v-navigation-drawer>

  <!-- Submit with targets -->
  <pop-up-submit-answers-with-roadmap
    ref="submitWithRoadmap"
    @close-modal="submitWithoutRoadmap.modalSubmitWithoutRoadmap.dialog = true"
  />

  <!-- Submit without targets -->
  <pop-up-submit-interview-answers ref="submitWithoutRoadmap" />
</template>

<script setup>
import axios from "axios";
import moment from "moment";
import { storeToRefs } from "pinia";
import { Icon } from "@iconify/vue";
import { useRoute } from "vue-router";
import useTools from "@/tools/useTools.js";
import { useUserStore } from "@/store/user.js";
import { useSnackbar } from "@/store/snackbar.js";
import { useMobileStore } from "@/store/mobile.js";
import { useInterviewStore } from "@/store/interview.js";
import { InterviewQuestionType } from '@/constants/types';
import { downloadPdf } from "@/tools/pdf-proxy-service.js";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useTargetSuggestionsStore } from '@/store/targets-suggestions';
import BktDownloadButton from "@/components/button/BktDownloadButton.vue";
import PopUpHistoryUpdates from "@/components/popUpComponents/PopUpHistoryUpdates.vue";
import BktSubmitInterviewAnswerButton from "@/components/button/BktSubmitInterviewAnswerButton.vue";
import InterviewAnswerList from "@/components/interviewApp/interview_answers/InterviewAnswerList.vue";
import PopUpSubmitInterviewAnswers from "../../../components/popUpComponents/PopUpSubmitInterviewAnswers.vue";
import PopUpSubmitAnswersWithRoadmap from "../../../components/popUpComponents/PopUpSubmitAnswersWithRoadmap.vue";

const route = useRoute();
const snackbar = useSnackbar();
const { fetchInterview } = useInterviewStore();
const { deduceMatIconFromInterviewQuestionType } = useTools();

const { id: currentUserId } = storeToRefs(useUserStore());
const { isMdScreen, isLargeScreen } = storeToRefs(useMobileStore());
const { snapshotsUpdated, snapshotsCreated } = storeToRefs(useTargetSuggestionsStore());
const {
  campaign,
  interviewKind,
  isInterviewLoading,
  interviewForm,
  locked,
  interviewAnswers,
  interviewTouched,
  isFinalUpdate,
  employee,
  interviewer,
  label
} = storeToRefs(useInterviewStore());

const openDrawer = () => {
  drawerLarge.value = !drawerLarge?.value;
}

const drawerLarge = ref(false);
const historyTooltip = ref(false);
const historyModal = ref(false);
const drawer = ref(false);
const submitLoading = ref(false);
const downloadLoading = ref(false);
const errorQuestions = ref([]);
const submitWithRoadmap = ref(null);
const submitWithoutRoadmap = ref(null);
const historyRef = ref([]);
const paginationHistory = ref(null);

onMounted(async() => {
  await fetchInterview(route?.params?.id);
  const { data } = await axios.get(`/api/v2/interview_app/interviews/${Number(route?.params?.id)}/histories`)
  historyRef.value = data?.histories.reverse();
  paginationHistory.value = data?.meta?.pagination;

  window.addEventListener("resize", resizeFunction);
});

onUnmounted(() => {
  window.removeEventListener("resize", resizeFunction);
});

const loadMoreHistory = async() => {
  if (paginationHistory.value.next_page > paginationHistory.value.current_page) {
    try {
      const { data } = await axios.get(`/api/v2/interview_app/interviews/${props?.interviewId}/histories?page[number]=${paginationHistory?.value?.current_page + 1}`)
      historyRef.value = [...historyRef.value, ...data?.histories];
      paginationHistory.value = data?.meta?.paginationHistory;
    } catch (error) {
      snackbar.setBgColor('onSurface').setMsg('Error getting the updates history').displaySnackBar();
    }
  }
}

const resizeFunction = () => {
  if (window.innerWidth < 1440 && drawerLarge?.value) {
    drawerLarge.value = false
    drawer.value = true
  }
  if (window.innerWidth >= 1440 && drawer?.value) {
    drawer.value = false
    drawerLarge.value = true
  }
}

const headlines = computed(() => {
  if (!interviewForm.value) return [];

  let separator_position = 1;
  return interviewForm.value.questions.map((question) => ({
    id: question.id,
    title: question.text,
    type: question.type,
    separatorpos:
      question.type === InterviewQuestionType.CHAPTER ||
      question.type === InterviewQuestionType.CREATE_ROADMAP ||
      question.type === InterviewQuestionType.UPDATE_ROADMAP
        ? separator_position++
        : null,
    icon: deduceMatIconFromInterviewQuestionType(question.type),
    visible_for: question.visible_for,
  }));
});

const clickDownload = async () => {
  downloadLoading.value = true;
  await downloadPdf(`/pdf/interview_answer/${route.params.id}`, `${employee.value?.fullname}_${campaign?.value?.title}_${campaign?.value?.campaign_type}`.toUpperCase())
  downloadLoading.value = false;
}

const isRequired = (interviewQuestion) => {
  if (interviewQuestion.required_for == 'none') return false
  if (interviewQuestion.required_for == 'all') return true
  if (interviewQuestion.required_for == 'manager') return currentUserId.value === interviewer.value.id && label.value !== 'Employee'
  if (interviewQuestion.required_for == 'employee') return currentUserId.value === employee.value.id && label.value === 'Employee'
}

const updateAnswersErrorState = () => {
  submitLoading.value = true;
  if (!interviewAnswers?.value?.length) {
    interviewTouched.value = true;
  }

  for (let question of interviewForm?.value?.questions) {
    if (isRequired(question) && !interviewAnswers?.value?.find(answer => answer?.question_id === question?.id)?.answer) {
      errorQuestions?.value?.push(question?.id)
    }
  }
  if (errorQuestions?.value?.length) {
    interviewTouched.value = true
    let element = document.getElementById(`${errorQuestions.value[0]}`)
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    snackbar.setBgColor("onSurface").setCustomClass(isMdScreen?.value ? 'mb-5' : 'mb-[145px]').setMsg("Please answer all required questions (*).").displaySnackBar();
  } else {
    openLockInterviewPopUp()
  }
  submitLoading.value = false;
  errorQuestions.value = []
}

const scrollToQuestion = (id) => {
  let element = document.getElementById(`${id}`);
  if (element) {
    drawer.value = false
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

const openLockInterviewPopUp = () => {
  const hasRoadmap = interviewForm.value.questions.some((question) => question.type === InterviewQuestionType.CREATE_ROADMAP || question.type === InterviewQuestionType.UPDATE_ROADMAP)

  if (isFinalUpdate?.value === true && campaign?.value?.campaign_type === 'OneToOne' && hasRoadmap) {
    if (snapshotsUpdated?.value?.length || snapshotsCreated?.value?.length) {
      submitWithRoadmap.value.modalSubmitWithRoadmap.dialog = true
    } else {
      submitWithoutRoadmap.value.modalSubmitWithoutRoadmap.dialog = true
    }
  }
  else {
    submitWithoutRoadmap.value.modalSubmitWithoutRoadmap.dialog = true
  }
}
</script>

<style scoped>
:deep(.v-navigation-drawer__content) {
  overflow: hidden !important;
}
</style>